import http from "../shared/services/http";

export const getUsage = async (params) => {
  try {
    const result = await http.get(`/customer/v2/balance`, params);
    return [result, null];
  } catch (err) {
    return [null, err];
  }
};

export const getPackageItemDailyCounter = async () => {
  try {
    const result = await http.get(`/customer/v1/usage/packageItemDailyCounter`);
    return [result, null];
  } catch (err) {
    return [null, err];
  }
};
