import Model from "../Model";
import { ContactType } from "./ContactType";

export class Contact extends Model {
  constructor(data) {
    super(data);
    if (data) {
      this.setValueFromData = this.setValueFromData(
        "value",
        String(data.value)
      );
      this.setValueFromData = this.setValueFromData(
        "type",
        ContactType[data.type]
      );
    }
  }
}
