import Model from "../Model";

export class Agent extends Model {
  sfid = "";
  constructor(data) {
    super(data);
    if (data) {
      this.setValueFromData("sfid", String(data.sfid).toString());
    }
  }
}
