<template>
  <div class="topbarmenu">
    <div class="content">
      <div class="topbarmenu-left">
        <a href="#/" class="router-link-active"
          ><div
            class="topbarmenu-logo"
            id="logolink"
            style="cursor: pointer; outline: none"
          >
            <img
              alt="dashboard"
              src="https://galeria.nos.pt/Style%20Library/5G/images/logo-nos.svg"
              @click="$router.push({ name: 'home' })"
            /></div
        ></a>
      </div>
      <span v-if="account.name">
        <div class="flex align-items-center">
          <Divider layout="vertical" class="ml-0" />
          {{ account.name }}
        </div>
      </span>
      <!-- <span v-if="projectName">
        <div class="flex align-items-center">
          <i class="pi pi-desktop" style="margin-right: 5px;"></i>
          <Divider layout="vertical" />
          {{projectName}}
         </div>
      </span> -->
      <div class="topbarmenu-right">
        <Menubar :model="items" />
        <DynamicDialog />
      </div>
    </div>
  </div>
</template>

<script lang="jsx">
// import Auth from "@/shared/services/auth";

// import ProjectList from "@/components/ProjectList";
import NotificationList from "@/components/NotificationList";
import { useDialog } from "primevue/usedialog";
// import Button from 'primevue/button';

import { Account } from "../model/Account/Account";
import { getAccount } from "@/services/account.service";

import { h } from "vue";

import AuthApp from "../main";

export default {
  data() {
    return {
      auth: AuthApp,
      isActive: true,
      projectName: "",
      account: {},
      dialog: useDialog(),
      isExactActive: false,
      items: [],
    };
  },
  mounted() {
    if (this.auth.account_state != "ENABLED") {
      this.items.push({
        icon: "pi pi-fw pi-bell",
        label: 1,
        command: () => this.handleNotification(),
      });
    }
    this.items.push(
      {
        label: AuthApp.email,
        icon: "pi pi-fw pi-users",
      },
      {
        icon: "pi pi-fw pi-th-large",
        items: [
          {
            label: "Gestão de conta",
            icon: "pi pi-fw pi-cog",
            command: () => {
              this.$router.push({ name: "account" });
            },
            disabled:
              this.auth.role == "super_admin" || this.auth.role == "admin"
                ? false
                : true,
          },
          {
            separator: true,
          },
          {
            label: "Sair",
            icon: "pi pi-fw pi-sign-out",
            command: () => {
              this.$router.push({ path: "/logout", meta: { auth: this.auth } });
            },
            // to: { path: "/logout", meta: { auth: this.auth } },
          },
        ],
      }
    );

    this.account = this.$store.getters.account;
    if (!Object.keys(this.account).length) {
      this.account = new Account();
      this.getAccount();
    }
  },
  methods: {
    logout() {
      // this.auth.logout();
    },
    isSelected(pathName) {
      return this.$route.name == pathName;
    },
    handleNotification() {
      this.dialog.open(NotificationList, {
        props: {
          header: "Notificações",
          style: {
            width: "50vw",
          },
          breakpoints: {
            "960px": "75vw",
            "640px": "90vw",
          },
          modal: true,
        },
        templates: {
          header: () => {
            return [
              h(
                <div>
                  <i class="pi pi-fw pi-bell"></i> Notificações
                </div>
              ),
            ];
          },
        },
      });
    },
    async getAccount() {
      const [res, err] = await getAccount();

      if (err) {
        console.log("ERROR", err);
      } else {
        this.account = res;
        this.$store.dispatch("setAccount", this.account);
      }
    },
    handleNewProject() {
      // const dialog = useDialog();
      // const dialogRef = this.$dialog;
      // const dialog = useDialog();
      //   // const dialogRef = this.$Dialog;
      //   // console.log("IN", dialogRef);
      //   // console.log("IN", dialog);
      //   this.dialog.open(ProjectList, {
      //     props: {
      //       header: "Seleccione o projecto",
      //       style: {
      //         width: "50vw",
      //       },
      //       breakpoints: {
      //         "960px": "75vw",
      //         "640px": "90vw",
      //       },
      //       modal: true,
      //     },
      //     templates: {
      //       footer: () => {
      //         // return [
      //         //     h(Button, { label: "No", icon: "pi pi-times", onClick: () => dialogRef.close({ buttonType: 'No' }), class: "p-button-text" }),
      //         //     h(Button, { label: "Yes", icon: "pi pi-check", onClick: () => dialogRef.close({ buttonType: 'Yes' }), autofocus: true })
      //         // ]
      //       },
      //     },
      //     onClose: (options) => {
      //       const data = options.data;
      //       if (data) {
      //         this.projectName = data.data.name;
      //         // console.log("options", data.data.name);
      //         // const buttonType = data.buttonType;
      //         // const summary_and_detail = buttonType ? { summary: 'No Product Selected', detail: `Pressed '${buttonType}' button` } : { summary: 'Product Selected', detail: data.name };
      //         // this.$toast.add({ severity:'info', ...summary_and_detail, life: 3000 });
      //       }
      //     },
      //   });
    },
  },
};
</script>

<style lang="scss">
.topbarmenu {
  background-color: #ffffff;
  box-shadow: 0 24px 64px -2px rgba(0, 0, 0, 0.02),
    0 6px 16px -2px rgba(0, 0, 0, 0.06), 0 2px 6px -2px rgba(0, 0, 0, 0.08);
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  -moz-transition: width 0.2s;
  -o-transition: width 0.2s;
  -webkit-transition: width 0.2s;
  transition: width 0.2s;
  height: 55px !important;
}

.topbarmenu .content {
  height: 100%;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.topbarmenu .topbarmenu-left {
  height: 100%;
  // width: 240px;
  padding: 11px 14px 13px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.topbarmenu .topbarmenu-right {
  height: 100%;
  flex-grow: 1;
  padding: 10px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-right: solid 1px transparent;
}

.topbarmenu-logo img {
  width: 55px;
  vertical-align: middle;
}

.p-menubar {
  padding: 0px;
  background: none !important;
  border: none !important;
  border-radius: none !important;
  margin-left: auto;
}

.p-menubar .p-submenu-list {
  right: 0px;
}

.p-menubar .p-menubar-start p.select-project {
  border: 1px solid #e2e2e2;
  padding: 5px 15px;
}

.p-menubar .p-menuitem-link span.pi-bell + span {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 10px;
}
</style>
