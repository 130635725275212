<template>
  <div class="paginator">
    <div class="per-page">
      <div class="per-page-text">Resultados por página</div>
      <Paginator
        class="per-page-paginator"
        :rows="size"
        :totalRecords="totalRecords"
        :first="first"
        @page="handlePageChange"
        :rowsPerPageOptions="rowsPerPageOptions"
        template="RowsPerPageDropdown"
      ></Paginator>
    </div>
    <Paginator
      class="main-paginator"
      :rows="size"
      :totalRecords="totalRecords"
      :first="first"
      @page="handlePageChange"
      template=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
    ></Paginator>
  </div>
</template>

<script>
export default {
  name: "CustomPaginator",
  props: {
    size: Number,
    totalRecords: Number,
    first: Number,
    rowsPerPageOptions: Array,
  },
  methods: {
    handlePageChange(e) {
      this.$emit("pageChange", e);
    },
  },
};
</script>

<style scoped>
.paginator {
  display: flex;
  justify-content: space-between;
}

.per-page {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.per-page-text {
  flex: 1;
  font-weight: 400;
  font-size: 12px;
}

.per-page-paginator {
  flex: 2;
  justify-content: flex-start !important;
}

.main-paginator {
  padding: 0;
}
</style>
