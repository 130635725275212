<template>
  <ErrorState
    v-if="!loading && error"
    @onErrorStateButtonClick="onErrorStateButtonClick()"
    message="Algo não correu bem"
    buttonText="Voltar a tentar"
  ></ErrorState>

  <GenericDialog
    :visible="showDialog"
    title="Eliminar lista de contactos"
    description="Confirma que quer eliminar a lista de contactos?"
    @close="showDialog = false"
    @confirm="removeList()"
  />

  <Card class="card" v-if="!loading && !error">
    <template #content>
      <div>
        <div class="text-right">
          <span :class="'mr-2 customer-badge status-' + data.status">{{
            $t("views.general.statuses." + data.status)
          }}</span>
        </div>
        <h4>{{ $t("views.catalog.formLabels.general") }}</h4>

        <div class="grid card">
          <div class="p-field col-6 md:col-6 lg:col-2">
            <label for="name">{{ $t("views.contacts.table.listName") }}</label>
            <p>{{ data.name }}</p>
          </div>
          <div class="p-field col-6 md:col-6 lg:col-2">
            <label for="recipients">{{
              $t("views.contacts.table.fileName")
            }}</label>
            <p>{{ data.originalFileName }}</p>
          </div>
          <div class="p-field col-6 md:col-6 lg:col-2">
            <label for="createdAt">{{
              $t("views.contacts.table.createdAt")
            }}</label>
            <p>{{ convertTimeStampToDate(data.createdAt) }}</p>
          </div>
          <div class="p-field col-6 md:col-6 lg:col-2">
            <label for="updatedAt">{{
              $t("views.contacts.table.updatedAt")
            }}</label>
            <p>{{ convertTimeStampToDate(data.updatedAt) }}</p>
          </div>
        </div>
        <div class="grid card">
          <div class="p-field col-6 md:col-6 lg:col-2">
            <label for="name">{{ $t("views.contacts.table.contacts") }}</label>
            <p></p>
            <div class="mb-1">
              <i
                class="pi pi-check-circle mr-1"
                style="font-size: 0.9rem; color: green"
              ></i>
              {{ data.contacts || "0" }}
              <label class="p-field">( importados )</label>
            </div>
            <div class="mb-1" v-if="data.blob.progress.task1.totalError > 0">
              <i
                class="pi pi-exclamation-circle mr-1 red"
                style="font-size: 0.9rem; color: red"
              ></i>
              {{ data.blob.progress.task1.totalError || "0" }}
              <label class="p-field">( rejeitados )</label>
            </div>
          </div>
        </div>

        <Divider />
        <Button
          @click="showDialog = true"
          icon="pi pi-trash"
          label="Apagar"
          class="p-button-outlined p-button-danger radius-normal"
          :disabled="data.status == 'accepted' || data.status == 'processing'"
        ></Button>
        <FileDownloader
          :disabled="data.status != 'processed'"
          class="ml-2"
          :url="
            downloadBaseListUrl +
            'customer/v1/list/' +
            data.listId +
            '/download'
          "
          :file="data.name + '.csv'"
          label="Download(.csv)"
          :withAuthorization="true"
        />
      </div>
    </template>
  </Card>
</template>

<script>
import ErrorState from "@/components/utils/ErrorState.vue";
import GenericDialog from "@/components/utils/GenericDialog.vue";
import AuthApp from "../main";
import { getList, removeList } from "@/services/contact.service";
import dayjs from "dayjs";
import FileDownloader from "@/components/utils/FileDownloader.vue";

export default {
  name: "messagelist",
  data: function () {
    return {
      auth: AuthApp,
      showDialog: false,
      loading: true,
      data: {},
      message: {},
      downloadBaseListUrl: process.env.VUE_APP_BASE_URL,
      deliveryReports: [
        { name: "Todos", code: "ALL" },
        { name: "Nenhum", code: "NONE" },
        { name: "Só entregues", code: "DELIVERED" },
        { name: "Não entregues", code: "NON_DELIVERED" },
      ],
    };
  },
  components: {
    ErrorState,
    GenericDialog,
    FileDownloader,
  },
  computed: {},
  mounted() {
    this.data = this.$store.getters.contactGroupListSelected;
    if (this.data && Object.keys(this.data).length) {
      this.loading = false;
      // this.downloadListUrl =
      //   process.env.VUE_APP_BASE_URL +
      //   "customer/v1/list/" +
      //   this.data.listId +
      //   "/download";
    } else {
      this.getData();
    }
  },
  methods: {
    async getData() {
      this.error = false;
      this.loading = true;
      this.loader = this.$loading.show();

      let params = this.$route.params;

      const [res, err] = await getList(params.id);

      if (err) {
        this.error = true;
        this.$toast.add({
          severity: "error",
          summary: "Erro",
          detail: "Aconteceu um erro, por favor tente mais tarde!",
          life: 3000,
        });
      } else {
        this.data = res;
        // this.downloadListUrl =
        //   process.env.VUE_APP_BASE_URL +
        //   "customer/v1/list/" +
        //   this.data.listId +
        //   "/download";
        this.onTitleChange(res.name);
      }

      this.loader.hide();
      this.loading = false;
    },
    convertTimeStampToDate: function (date) {
      return dayjs(date).format("YYYY-MM-DD HH:mm:s");
    },
    onTitleChange(title) {
      this.$emit("onTitleChange", title);
    },
    onErrorStateButtonClick: function () {
      this.getData();
    },
    async removeList() {
      this.loading = true;
      this.loader = this.$loading.show();
      const [, err] = await removeList(this.data.listId);
      if (err) {
        // this.empty = true;
        this.showDialog = false;

        this.$toast.add({
          severity: "error",
          summary: "Erro",
          detail: "Aconteceu um erro, por favor tente mais tarde!",
          life: 3000,
        });

        this.loader.hide();
      } else {
        this.showDialog = false;

        this.$router.push({ name: "contacts" });
        this.$toast.add({
          severity: "success",
          summary: "Sucesso",
          detail: "Lista apagada com sucesso",
          life: 3000,
        });
        this.loader.hide();
      }
      this.loading = false;
      // this.loader.hide();
    },
  },
};
</script>

<style lang="scss">
.p-tabview-panels {
  padding-top: 0px !important;
}

.p-dropdown .p-dropdown-label {
  padding: 0.55rem 0.6rem;
}
</style>
