<template>
  <Message severity="warn">
    <div>
      A subscrição de números para recepção de sms associadas à tipologia
      <b>MO</b>, tem um custo mensal de €75.
    </div>
  </Message>
  <EmptyState
    v-if="
      !loading && longNumbers.length === 0 && auth.account_state == 'ENABLED'
    "
    @onEmptyStateButtonClick="createLongNumber()"
    message="Ainda não tem LongNumber associado"
    buttonText="Criar LongNumber"
  ></EmptyState>

  <GenericDialog
    :visible="showDialog"
    title="Eliminar longnumber"
    description="Confirma que quer eliminar o longnumber?"
    @close="showDialog = false"
    @confirm="removeLongNumber()"
  />
  <DataTable
    :rowHover="true"
    :value="longNumbers"
    :paginator="false"
    :row-hover="true"
    responsiveLayout="scroll"
    v-if="!loading && longNumbers.length"
  >
    <Column field="actions" :header="$t('views.accounts.table.longNumber')">
      <template #header> </template>
      <template #body="slotProps">
        {{ slotProps.data.name }}
      </template>
    </Column>
    <Column bodyStyle="text-align: right">
      <template #body="slotProps">
        <Button
          icon="pi pi-trash"
          class="p-button-rounded p-button-secondary p-button-text"
          @click="(showDialog = true), (toDelete = slotProps.data.name)"
        />
      </template>
    </Column>
  </DataTable>
</template>

<script>
import {
  getLongNumber,
  createLongNumber,
  removeLongNumber,
} from "@/services/account.service";
import EmptyState from "@/components/utils/EmptyState.vue";
import AuthApp from "../../main";
import GenericDialog from "@/components/utils/GenericDialog.vue";

export default {
  name: "longnumberlist",
  data: function () {
    return {
      auth: AuthApp,
      loading: true,
      longNumbers: [],
      showDialog: false,
    };
  },
  mounted() {
    this.getData();
  },
  components: {
    EmptyState,
    GenericDialog,
  },
  props: {},
  methods: {
    async getData() {
      this.loading = true;
      this.loader = this.$loading.show();

      const [res, err] = await getLongNumber();

      if (err) {
        this.$toast.add({
          severity: "error",
          summary: "Erro",
          detail: "Aconteceu um erro, por favor tente mais tarde!",
          life: 3000,
        });
      } else {
        this.longNumbers = res.items;
      }
      this.loading = false;
      this.loader.hide();
    },
    async removeLongNumber() {
      const id = this.toDelete;
      this.loading = true;
      this.loader = this.$loading.show();
      const [, err] = await removeLongNumber(id);
      if (err) {
        this.$toast.add({
          severity: "error",
          summary: "Erro",
          detail: "Aconteceu um erro, por favor tente mais tarde!",
          life: 3000,
        });
      } else {
        this.longNumbers = this.longNumbers.filter(function (obj) {
          return obj.name !== id;
        });
      }

      this.showDialog = false;
      this.loading = false;
      this.loader.hide();
    },
    async createLongNumber() {
      this.loading = true;
      this.loader = this.$loading.show();
      const [res, err] = await createLongNumber();
      if (err) {
        if (err.statusCode == 409) {
          this.$toast.add({
            severity: "error",
            summary: "Erro",
            detail:
              "De momento não é possível atribuír longNumbers. Por favor contate o seu gestor.",
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Erro",
            detail: "Aconteceu um erro, por favor tente mais tarde!",
            life: 3000,
          });
        }
      } else {
        this.longNumbers.push(res);
      }

      this.loading = false;
      this.loader.hide();
    },
  },
};
</script>

<style scoped>
.p-field p,
.p-field label {
  margin: 0px;
}

.p-card-content h4 {
  margin: 0px 0px 40px 0px;
}
</style>
