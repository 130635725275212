<template>
  <EmptyState
    v-if="!loading && users.length === 0"
    @onEmptyStateButtonClick="$router.push({ name: 'newUserAccount' })"
    message="Ainda não tem utilizadores criados"
    buttonText="Criar utilizador"
  ></EmptyState>

  <div class="header-content">
    <div class="flex mt-2 justify-content-between align-items-center">
      <div
        class="inline-flex align-items-center results"
        v-if="users.length"
        style="font-size: 14px; color: grey"
      >
        <span>{{ users.length }} resultado(s)</span>
      </div>
      <div class="inline-flex flex-auto"></div>
      <div class="inline-flex">
        <Button
          label="Criar utilizador"
          v-if="auth.account_state == 'ENABLED'"
          class="p-button p-button-secondary"
          @click="$router.push({ name: 'newUserAccount' })"
        />
      </div>
    </div>
  </div>
  <!-- <Divider align="left" style="margin-top:30px">
  </Divider> -->

  <!-- <Divider align="left" style="margin-top:30px">
    <div>
    <div
      class="inline-flex align-items-center results"
      v-if="users.length"
      style="font-size: 14px; color: grey"
    >
      <span>{{ users.length }} resultado(s)</span>
    </div>
    <div style="display: inline-flex; flex: auto;"></div>
    <div class="inline-flex p-ai-center">
        <Button
          label="CriarUtilizador"
          class="p-button-outlined"
          iconPos="right"
          @click="$router.push({ name: 'newUserAccount' })"
        />
    </div>
  </div>
  </Divider> -->

  <GenericDialog
    :visible="showDialog"
    title="Eliminar utilizador"
    description="Confirma que quer eliminar o utilizador?"
    @close="showDialog = false"
    @confirm="removeUser()"
  />
  <DataTable
    :rowHover="true"
    :value="users"
    :paginator="false"
    :row-hover="true"
    responsiveLayout="scroll"
    @row-click="handleRowClick($event)"
    v-if="users.length"
  >
    <Column field="email" :header="$t('views.accounts.table.email')"></Column>
    <Column field="name" :header="$t('views.accounts.table.profile')">
      <template #body="slotProps">
        {{ $t("views.roles." + slotProps.data.role) }}
      </template>
    </Column>
    <Column bodyStyle="text-align: right">
      <template #body="slotProps">
        <Button
          v-if="
            (slotProps.data.email != auth.email ||
              slotProps.data.role != 'super_admin') &&
            auth.account_state == 'ENABLED'
          "
          icon="pi pi-trash"
          class="p-button-rounded p-button-secondary p-button-text"
          @click="(showDialog = true), (toDelete = slotProps.data.email)"
        />

        <!-- <a
          v-if="
            (slotProps.data.email != auth.email ||
              slotProps.data.role != 'super_admin') &&
            auth.account_state == 'ENABLED'
          "
          href="null"
          @click.prevent="
            (showDialog = true), (toDelete = slotProps.data.email)
          "
        >
          <i class="pi pi-trash" style="color: red"></i>&nbsp;Apagar
        </a> -->
      </template>
    </Column>
    <!-- <Column field="account" :header="$t('views.accounts.table.account')">
    </Column> -->
  </DataTable>
  <CustomPaginator
    :size="size"
    :totalRecords="Number(totalRecords)"
    :first="first"
    :rowsPerPageOptions="[5, 10, 20, 30]"
    @pageChange="handlePageChange"
    v-if="users.length"
  />
</template>

<script>
import { getUser, deleteUser } from "@/services/user.service";
import EmptyState from "@/components/utils/EmptyState.vue";
import CustomPaginator from "@/components/CustomPaginator.vue";
import GenericDialog from "@/components/utils/GenericDialog.vue";
import AuthApp from "../../main";

export default {
  name: "applist",
  data: function () {
    return {
      auth: AuthApp,
      loading: true,
      first: 0,
      size: 10,
      page: 0,
      users: {},
      toDelete: 0,
      showDialog: false,
    };
  },
  mounted() {
    this.getData();
  },
  components: {
    EmptyState,
    CustomPaginator,
    GenericDialog,
    // ErrorState,
  },
  props: {
    // msg: String
  },
  methods: {
    handleRowClick(e) {
      console.log(JSON.stringify(e));
    },
    async getData() {
      this.loading = true;
      this.loader = this.$loading.show();

      const [res, err] = await getUser({ page: this.page, size: this.size });

      if (err) {
        // this.empty = true;
        this.$toast.add({
          severity: "error",
          summary: "Erro",
          detail: "Aconteceu um erro, por favor tente mais tarde!",
          life: 3000,
        });

        this.loader.hide();
      } else {
        // console.log(res);
        this.users = res.items;
        this.totalRecords = res.totalItems;
        // this.users.length = 1;
        // this.appKeys = appClientRes;
        // this.$emit("updatedAppKeys", this.appKeys.credentials.length);
      }
      this.loading = false;
      this.loader.hide();
    },
    async removeUser() {
      // console.log("REMOVE");
      this.loading = true;
      this.loader = this.$loading.show();
      const [, err] = await deleteUser(this.toDelete);
      if (err) {
        // this.empty = true;
        this.$toast.add({
          severity: "error",
          summary: "Erro",
          detail: "Aconteceu um erro, por favor tente mais tarde!",
          life: 3000,
        });

        this.loader.hide();
      } else {
        // console.log(JSON.stringify(this.users));

        this.users = this.users.filter((item) => item.email !== this.toDelete);

        this.toDelete = null;
        this.showDialog = false;
        // this.users = res.items;
        // this.totalRecords = res.totalItems;
        // this.users.length = 1;
        // this.appKeys = appClientRes;
        // this.$emit("updatedAppKeys", this.appKeys.credentials.length);
      }
      this.loading = false;
      this.loader.hide();
    },
    handlePageChange(e) {
      this.page = e.page;
      this.size = e.rows;
      this.getData();
    },
  },
};
</script>

<style lang="css" scoped>
.p-datatable {
  margin-top: 10px !important;
}
</style>
