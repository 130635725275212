<template>
  <Page
    :title="$t('views.message.dashboard.headerTitle')"
    @onIconClick="this.$router.push({ name: 'home' })"
  >
    <template v-slot:content v-if="!loading">
      <MessageGenericList v-if="activeIndex === 0"></MessageGenericList>
    </template>
  </Page>
</template>

<script>
import Page from "@/components/Page.vue";
import MessageGenericList from "@/components/MessageGenericList";

import AuthApp from "../main";

export default {
  components: {
    Page,
    MessageGenericList,
  },
  setup() {},
  mounted() {},
  data() {
    return {
      auth: AuthApp,
      loading: false,
      activeIndex: 0,
      tabOptions: [{ value: "SMS Simples", selected: true, visible: true }],
    };
  },
  computed: {},
  validations() {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
