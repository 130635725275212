<template>
  <ErrorState
    v-if="!loading && error"
    @onErrorStateButtonClick="onErrorStateButtonClick()"
    message="Algo não correu bem"
    buttonText="Voltar a tentar"
  ></ErrorState>
  <GenericDialog
    :visible="showGenericDialog"
    title="Eliminar campanha"
    description="Confirma que quer eliminar a campanha?"
    @close="showGenericDialog = false"
    @confirm="removeCampaign()"
  />

  <div v-if="!error" class="flex align-items-stretch flex-wrap">
    <div class="flex align-items-start results">
      {{ totalRecords }} resultado(s)
    </div>
  </div>
  <Divider />
  <EmptyState
    v-if="!loading && !error && !items.length"
    @onEmptyStateButtonClick="$router.push({ name: 'newCampaign' })"
    message="Sem campanhas"
    buttonText="Enviar campanha"
    :showButton="auth.account_state === 'ENABLED' ? true : false"
  ></EmptyState>
  <DataTable
    :rowHover="true"
    :value="items"
    :paginator="false"
    :row-hover="true"
    responsiveLayout="scroll"
    @row-click="handleRowClick($event)"
    v-if="items.length"
  >
    <Column field="title">
      <template #header>
        <i class="pi pi-tag mr-1" style="font-size: 0.8rem"></i>
        {{ $t("views.message.dashboard.table.title") }}
      </template>
    </Column>
    <Column field="createdAt">
      <template #header>
        <i class="pi pi-calendar mr-1" style="font-size: 0.8rem"></i>
        {{ $t("views.campaign.formLabels.createdAt") }}
      </template>
      <template #body="slotProps">
        {{ convertTimeStampToDate(slotProps.data.createdAt) }}
      </template>
    </Column>
    <!-- <Column
      field="submissionDate"
      :header="$t('views.message.dashboard.table.submissionDate')"
    >
      <template #body="slotProps">
        {{ convertEpochToDate(slotProps.data.submissionDate) }}
      </template>
    </Column> -->
    <!-- <Column
      field="totalRecipients"
      :header="$t('views.message.dashboard.table.totalRecipients')"
    >
    </Column>
    <Column
      field="totalSms"
      :header="$t('views.message.dashboard.table.totalSms')"
    >
    </Column> -->
    <Column field="sender">
      <template #header>
        <i class="pi pi-send mr-1" style="font-size: 0.8rem"></i>
        {{ $t("views.message.dashboard.table.sender") }}
      </template>
    </Column>
    <Column field="status">
      <template #header>
        <i class="pi pi-sync mr-1" style="font-size: 0.8rem"></i>
        {{ $t("views.campaign.formLabels.status") }}
      </template>
      <template #body="slotProps">
        <i
          :class="
            'customer-badge status-' + slotProps.data.status.toLowerCase()
          "
          >{{
            $t("views.general.statuses." + slotProps.data.status.toLowerCase())
          }}</i
        >
      </template>
    </Column>
    <Column field="actions">
      <template #header>
        <!-- <i class="pi pi-cog"></i> -->
      </template>
      <template #body="slotProps">
        <div class="flex align-items-center justify-content-end">
          <i
            v-if="slotProps.data.moNumber"
            title="C/resposta"
            class="pi pi-arrow-right-arrow-left mr-3"
            style="font-size: 1.5rem; color: var(--green)"
          ></i>
          <i
            v-if="slotProps.data.scheduleStart"
            title="C/agendamento"
            class="pi pi-calendar-times mr-3"
            style="font-size: 1.5rem; color: var(--green)"
          ></i>
          <Button
            v-if="
              slotProps.data.status == 'PROCESSED' ||
              slotProps.data.status == 'FAILED'
            "
            icon="pi pi-trash"
            class="p-button-rounded p-button-secondary p-button-text"
            @click="
              (showGenericDialog = true),
                (selectedRow = slotProps.data.campaignId)
            "
          />
          <Button
            v-if="
              slotProps.data.status == 'CREATED' ||
              slotProps.data.status == 'WAITING' ||
              slotProps.data.status == 'VALIDATED' ||
              slotProps.data.status == 'PROCESSING'
            "
            icon="pi pi-refresh"
            class="p-button-rounded p-button-secondary p-button-text"
            @click="getData()"
          />
        </div>
      </template>
    </Column>
  </DataTable>
  <CustomPaginator
    :size="size"
    :totalRecords="Number(totalRecords)"
    :first="first"
    :rowsPerPageOptions="[10, 20, 30]"
    @pageChange="handlePageChange"
    v-if="items.length"
  />
</template>

<script>
import ErrorState from "@/components/utils/ErrorState.vue";
import CustomPaginator from "@/components/CustomPaginator.vue";
import GenericDialog from "@/components/utils/GenericDialog.vue";
import AuthApp from "../main";
import dayjs from "dayjs";
import EmptyState from "@/components/utils/EmptyState.vue";
import { getCampaigns, removeCampaign } from "@/services/campaign.service.js";
import { convertTimeStampToDate } from "@/utils/date.js";

export default {
  name: "campaignlist",
  data: function () {
    return {
      auth: AuthApp,
      showFilters: false,
      showGenericDialog: false,
      loading: false,
      catalog: {},
      searchDate: null,
      searchSender: null,
      searchTitle: null,
      senders: [{ name: "Todos os Remetentes", code: "ALL" }],
      items: [],
      filters: [],
      first: 0,
      size: 10,
      page: 0,
    };
  },
  props: {
    showFiltersArea: Boolean,
  },
  components: {
    ErrorState,
    GenericDialog,
    CustomPaginator,
    EmptyState,
  },
  mounted() {
    let params = this.$route.query;
    if (params.prevpage) {
      this.page = parseInt(params.prevpage, 10);
    }
    if (params.prevsize) {
      this.size = parseInt(params.prevsize, 10);
    }
    if (params.prevfirst) {
      this.first = parseInt(params.prevfirst, 10);
    }

    //clean up just in case
    this.$router.replace({ query: null });

    this.getData();
  },
  computed: {},
  methods: {
    async getData() {
      this.error = false;
      this.loading = true;
      this.loader = this.$loading.show();

      var filter = this.handleFilter();

      const [res, err] = await getCampaigns({
        filter: filter,
        page: this.page,
        size: this.size,
      });

      if (err) {
        this.error = true;
        this.$toast.add({
          severity: "error",
          summary: "Erro",
          detail: "Aconteceu um erro, por favor tente mais tarde!",
          life: 3000,
        });
      } else {
        this.items = res.items;
        this.totalRecords = res.totalItems;
      }

      this.loader.hide();
      this.loading = false;
    },
    async removeCampaign() {
      this.loading = true;
      this.loader = this.$loading.show();
      const [, err] = await removeCampaign(this.selectedRow);
      if (err) {
        this.showGenericDialog = false;

        this.$toast.add({
          severity: "error",
          summary: "Erro",
          detail: "Aconteceu um erro, por favor tente mais tarde!",
          life: 3000,
        });

        this.loader.hide();
      } else {
        this.showGenericDialog = false;

        this.$toast.add({
          severity: "success",
          summary: "Sucesso",
          detail: "Lista apagada com sucesso",
          life: 3000,
        });
        this.loader.hide();
        this.getData();
      }
      this.loading = false;
    },
    onErrorStateButtonClick() {
      this.getData();
    },
    handleSearchTitle: function () {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.filters = this.filters.filter(function (item) {
          return item.property !== "title";
        });

        if (this.searchTitle) {
          this.filters.push({
            property: "title",
            op: "LIKE",
            value: this.searchTitle,
          });
        }

        this.getData();
      }, 800);
    },
    handleSearchTo: function () {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.filters = this.filters.filter(function (item) {
          return item.property !== "to";
        });

        if (this.searchTo) {
          this.filters.push({
            property: "to",
            op: "LIKE",
            value: this.searchTo,
          });
        }

        this.getData();
      }, 800);
    },
    handleRowClick: function (event) {
      console.log("handleRowClick", event.data);
      let id = event.data.campaignId;

      this.$store.dispatch("setCampaignSelected", event.data);

      this.$router.push({
        name: "campaigndashboardoverview",
        params: { id },
        query: {
          prevpage: this.page,
          prevsize: this.size,
          prevfirst: this.first,
        },
      });
    },
    handlePageChange(e) {
      this.page = e.page;
      this.size = e.rows;
      this.first = e.first;
      this.getData();
    },
    handleDate: function () {
      //clean previous values
      this.filters = this.filters.filter(function (item) {
        return item.property !== "submissionDate";
      });

      if (this.searchDate) {
        this.filters.push({
          property: "submissionDate",
          op: "GTE",
          value: dayjs(this.searchDate[0]).format("YYYY-MM-DD"),
        });

        //in case we're dealing with range
        if (this.searchDate.length == 2 && this.searchDate[1] != null) {
          this.filters.push({
            property: "submissionDate",
            op: "LTE",
            value: dayjs(this.searchDate[1]).format("YYYY-MM-DD"),
          });
        }
      }

      this.getData();
    },
    handleSender: function () {
      this.filters = this.filters.filter(function (item) {
        return item.property !== "sender";
      });

      if (this.searchSender.code != "ALL") {
        this.filters.push({
          property: "sender",
          op: "EQ",
          value: this.searchSender.name,
        });
      }

      this.getData();
    },

    handleFilter: function () {
      let string = "";
      this.filters.forEach(function (item, idx, array) {
        string += item.property + ":" + item.op + ":" + item.value;
        if (idx !== array.length - 1) {
          string += ",";
        }
      });

      return string;
    },
    minDate: function () {
      var d = new Date();
      d.setMonth(d.getMonth() - 6);
      return d;
    },
    //utils
    convertTimeStampToDate: convertTimeStampToDate,
  },
};
</script>

<style lang="scss">
.p-tabview-panels {
  padding-top: 0px !important;
}

.p-dropdown .p-dropdown-label {
  padding: 0.55rem 0.6rem;
}
</style>
