<template>
  <div class="empty-state" v-if="!loading">
    <img src="/resources/images/empty_state.svg" @click="handleNewApp" />
    <div>
      <h2>{{ message }}</h2>
      <button
        v-if="showButton"
        @click="onEmptyStateButtonClick"
        class="p-button p-component large"
        type="button"
      >
        {{ buttonText }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmptyState",
  props: {
    message: String,
    buttonText: String,
    showButton: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  setup() {},
  methods: {
    onEmptyStateButtonClick(e) {
      this.$emit("onEmptyStateButtonClick", e);
    },
  },
};
</script>

<style scoped>
.empty-state {
  text-align: center;
  margin-top: 50px;
}
.empty-state button {
  padding-left: 40px !important;
  padding-right: 40px !important;
}
</style>
