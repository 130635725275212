<template>
  <Message severity="warn">
    O detalhe de entrega de mensagens, estará apenas disponível após o fim do
    período máximo de entrega definido na configuração da campanha.
  </Message>

  <EmptyState
    v-if="!loading && items.length === 0"
    message="Não existem ficheiros para download"
    :showButton="false"
  ></EmptyState>

  <DataTable
    :rowHover="true"
    :value="items"
    :paginator="false"
    :row-hover="true"
    v-if="items.length"
  >
    <Column field="period" style="width: 2rem">
      <template #header>
        <i class="pi pi-calendar mr-1"></i>
        {{ $t("views.reports.table.campaignPeriod") }}
      </template>
      <template #body="slotProps">
        {{ convertTimeStampToDate(slotProps.data.parameters.meta.startDate) }} -
        {{ convertTimeStampToDate(slotProps.data.parameters.meta.endDate) }}
      </template>
    </Column>
    <Column field="document" style="width: 4rem">
      <template #header>
        <i class="pi pi-file mr-1"></i>
        {{ $t("views.reports.table.document") }}
      </template>
      <template #body="slotProps">
        <FileDownloader
          :url="
            baseUrl +
            'communication/v1/report/' +
            slotProps.data.reportId +
            '/dlr/download'
          "
          :file="
            slotProps.data.parameters.meta.startDate +
            '-' +
            slotProps.data.parameters.meta.endDate +
            '.csv'
          "
          label="Ficheiro (.csv)"
          :withAuthorization="true"
        />
      </template>
    </Column>
    <Column field="status" style="width: 1rem">
      <template #header>
        <i class="pi pi-sync mr-1"></i>
        {{ $t("views.reports.table.status") }}
      </template>
      <template #body="slotProps">
        <i
          :class="
            'customer-badge status-' + slotProps.data.status.toLowerCase()
          "
          >{{
            $t("views.general.statuses." + slotProps.data.status.toLowerCase())
          }}</i
        >
      </template>
    </Column>
  </DataTable>

  <ErrorState
    v-if="!loading && error"
    @onErrorStateButtonClick="onErrorStateButtonClick()"
    message="Algo não correu bem"
    buttonText="Voltar a tentar"
  ></ErrorState>
</template>

<script>
import { getDlrReport } from "@/services/report.service";
import FileDownloader from "@/components/utils/FileDownloader.vue";

import EmptyState from "@/components/utils/EmptyState.vue";
import ErrorState from "@/components/utils/ErrorState.vue";
import { convertTimeStampToDate } from "@/utils/date.js";

export default {
  name: "smsdlrreport",
  data: function () {
    return {
      loading: true,
      items: [],
      error: true,
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },
  components: {
    EmptyState,
    ErrorState,
    FileDownloader,
  },
  mounted() {
    this.campaignId = this.$route.params.id;
    this.getData();
  },
  methods: {
    onErrorStateButtonClick() {
      this.getData();
    },
    async getData() {
      this.error = false;
      this.loading = true;
      this.loader = this.$loading.show();

      const [res, err] = await getDlrReport({ campaign: this.campaignId });

      if (err) {
        if (err.statusCode != 404) {
          this.error = true;
          this.$toast.add({
            severity: "error",
            summary: "Erro",
            detail: "Aconteceu um erro, por favor tente mais tarde!",
            life: 3000,
          });
        }

        // this.loader.hide();
      } else {
        this.items = res.items;
      }

      this.loader.hide();
      this.loading = false;
    },
    convertTimeStampToDate: convertTimeStampToDate,
  },
};
</script>

<style scoped></style>
