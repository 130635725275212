<template>
  <ErrorState
    v-if="!loading && error"
    @onErrorStateButtonClick="onErrorStateButtonClick()"
    message="Algo não correu bem"
    buttonText="Voltar a tentar"
  ></ErrorState>

  <GenericDialog
    :visible="showGenericDialog"
    title="Eliminar template"
    description="Confirma que quer eliminar o template?"
    @close="showGenericDialog = false"
    @confirm="removeTemplate()"
  />

  <Dialog
    position="center"
    :visible="showDialog"
    :dismissableMask="true"
    :modal="true"
    style="width: 640px"
    :draggable="false"
    :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
    :style="{ width: '70vw' }"
  >
    <template #header> Criar/actualizar template </template>
    <div>
      <Message severity="info">
        <div class="mb-2">
          Os atributos dinámicos são definidos pela forma de
          <span class="text-xs"><b>{VARn}</b></span> em que
          <b>1&lt;=n&lt;=10</b>
          <span class="text-xs">ie: {VAR1}, {VAR2}, ... , {VAR10}</span>
          <p class="text-xs w-10">
            O titulo e texto do template deverão ter pelo menos 10 caracteres
          </p>
        </div>
      </Message>
      <Card class="card">
        <template #content>
          <div class="p-fluid p-formgrid grid card mb-3">
            <!-- Name -->
            <div class="col-4">
              <label for="name"
                >{{ $t("views.contacts.table.listName") }} - (max 30
                caracteres)</label
              >
              <InputText
                class="mt-2"
                id="name"
                type="text"
                v-model="template.title"
                placeholder="--"
                maxlength="30"
              />
              <small
                v-if="v$.template?.title?.$error"
                id="name-help"
                class="p-error"
              >
                <img
                  class="sucess-image"
                  src="/resources/icons/error_label.svg"
                />{{ v$.template.title?.$errors[0]?.$message }}</small
              >
            </div>
          </div>
          <div class="p-fluid p-formgrid grid card mb-3">
            <div class="col-6">
              <label for="name"
                >{{ $t("views.message.formLabels.text") }}
              </label>
              <TextArea
                class="mt-2"
                id="name"
                type="text"
                v-model="template.text"
                placeholder="--"
                rows="10"
                :autoResize="true"
                :maxlength="msgCountMaxLength"
              />
              <label class="label-cc">{{ msgCounter }}</label>
              <small
                v-if="v$.template?.text?.$error"
                id="name-help"
                class="p-error"
              >
                <img
                  class="sucess-image"
                  src="/resources/icons/error_label.svg"
                />{{ v$.template.text?.$errors[0]?.$message }}</small
              >
            </div>
            <div class="col-6">
              <div class="flex flex-wrap p-5">
                <div
                  v-for="idx in 10"
                  :key="idx"
                  class="flex align-items-center justify-content-center m-2"
                >
                  <Button
                    class="p-button-outlined p-button-secondary radius-normal"
                    :label="'{VAR' + idx + '}'"
                    @click="
                      template.text = template.text
                        ? template.text + '{VAR' + idx + '}'
                        : '{VAR' + idx + '}'
                    "
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
      </Card>
    </div>
    <template #footer>
      <Button label="Fechar" @click="showDialog = false" />
      <Button
        label="Submeter"
        @click="template.id ? updateTemplate() : submitTemplate()"
      />
    </template>
  </Dialog>

  <div v-if="!error" class="flex align-items-stretch flex-wrap">
    <div class="flex align-items-start results">
      {{ totalRecords }} resultado(s)
    </div>
    <div class="inline-flex flex-auto"></div>
    <div class="inline-flex">
      <Button
        label="Adicionar template"
        v-if="auth.account_state == 'ENABLED'"
        class="p-button p-button-secondary"
        @click="(template = {}), (showDialog = true)"
      />
    </div>
  </div>
  <Divider />

  <EmptyState
    v-if="!loading && !error && !items.length"
    @onEmptyStateButtonClick="openNewTemplatePopup"
    message="Sem templates criados"
    buttonText="Adicionar template"
    :showButton="auth.account_state === 'ENABLED' ? true : false"
  ></EmptyState>

  <!-- <Button
    v-if="items.length"
    @click="getData()"
    icon="pi pi-refresh"
    label="Atualizar"
    class="p-button-outlined p-button-secondary radius-normal"
  ></Button> -->
  <DataTable
    :rowHover="true"
    :value="items"
    :paginator="false"
    :row-hover="true"
    responsiveLayout="scroll"
    v-if="items.length"
  >
    <Column field="title">
      <template #header>
        <i class="pi pi-tag mr-1" style="font-size: 0.8rem"></i>
        {{ $t("views.contacts.table.listName") }}
      </template>
    </Column>
    <Column field="createdAt" :style="{ 'text-align': 'center' }">
      <template #header>
        <div class="flex-1 text-center">
          <i class="pi pi-calendar" style="font-size: 0.8rem"></i>
          {{ $t("views.contacts.table.createdAt") }}
        </div>
      </template>
      <template #body="slotProps">
        {{ convertTimeStampToDate(slotProps.data.createdAt) }}
      </template>
    </Column>
    <Column field="body">
      <template #header>
        <div class="flex-1">
          <i class="pi pi-calendar" style="font-size: 0.8rem"></i>
          {{ $t("views.templates.formLabels.text") }}
        </div>
      </template>
    </Column>
    <Column field="actions">
      <template #header>
        <!-- <i class="pi pi-cog"></i> -->
      </template>
      <template #body="slotProps">
        <Button
          icon="pi pi-user-edit"
          class="p-button-rounded p-button-secondary p-button-text"
          @click="
            (showDialog = true),
              (template.title = slotProps.data.title),
              (template.text = slotProps.data.body),
              (template.id = slotProps.data.templateId)
          "
        />
        <Button
          icon="pi pi-trash"
          class="p-button-rounded p-button-secondary p-button-text"
          @click="
            (showGenericDialog = true),
              (selectedRow = slotProps.data.templateId)
          "
        />
      </template>
    </Column>
  </DataTable>
  <CustomPaginator
    :size="size"
    :totalRecords="Number(totalRecords)"
    :first="first"
    :rowsPerPageOptions="[10, 20, 30]"
    @pageChange="handlePageChange"
    v-if="items.length"
  />
</template>

<script>
import ErrorState from "@/components/utils/ErrorState.vue";
import CustomPaginator from "@/components/CustomPaginator.vue";
import GenericDialog from "@/components/utils/GenericDialog.vue";
import useVuelidate from "@vuelidate/core";
import { getnrMsgs } from "@/utils/maxCalc.js";

import AuthApp from "../main";
import dayjs from "dayjs";
import { required, macthes } from "@/shared/customValidators.js";

import EmptyState from "@/components/utils/EmptyState.vue";
import {
  getTemplates,
  removeTemplate,
  submitTemplate,
  updateTemplate,
} from "@/services/template.service.js";

const regexTemplateTitle = /^.{10,30}/gm;
const regexTemplateText = /^.{10,765}/gm;

export default {
  name: "templatelist",
  setup() {
    return { v$: useVuelidate() };
  },
  data: function () {
    return {
      auth: AuthApp,
      showFilters: false,
      showDialog: false,
      showGenericDialog: false,
      loading: false,
      template: {},
      items: [],
      filters: [],
      first: 0,
      size: 10,
      page: 0,
    };
  },
  props: {
    showFiltersArea: Boolean,
  },
  components: {
    ErrorState,
    CustomPaginator,
    EmptyState,
    GenericDialog,
  },
  mounted() {
    let params = this.$route.query;
    if (params.prevpage) {
      this.page = parseInt(params.prevpage, 10);
    }
    if (params.prevsize) {
      this.size = parseInt(params.prevsize, 10);
    }
    if (params.prevfirst) {
      this.first = parseInt(params.prevfirst, 10);
    }

    //clean up just in case
    this.$router.replace({ query: null });

    this.getData();
  },
  computed: {
    msgCounter() {
      var obj = getnrMsgs(this.template.text || "");
      return obj.chars + "/765, " + obj.nrMsgs + " SMS";
    },
    msgCountMaxLength() {
      var obj = getnrMsgs(this.template.text);
      return 765 - (obj.chars - (this.template.text?.length || 0));
    },
  },
  validations() {
    return {
      template: {
        title: {
          required: required,
          regex: macthes(regexTemplateTitle),
        },
        text: {
          required: required,
          regex: macthes(regexTemplateText),
        },
      },
    };
  },
  methods: {
    async getData() {
      this.error = false;
      this.loading = true;

      this.loader = this.$loading.show();

      var filter = this.handleFilter();

      const [res, err] = await getTemplates({
        filter: filter,
        page: this.page,
        size: this.size,
      });

      if (err) {
        this.error = true;
        this.$toast.add({
          severity: "error",
          summary: "Erro",
          detail: "Aconteceu um erro, por favor tente mais tarde!",
          life: 3000,
        });
      } else {
        this.items = res.items;
        this.totalRecords = res.totalItems;
        //keep track of pending lists
      }

      this.loader.hide();
      this.loading = false;
    },

    async submitTemplate() {
      if (!(await this.checkForm())) return;

      this.error = false;
      this.loading = true;

      this.loader = this.$loading.show();
      const [, err] = await submitTemplate(this.template);
      if (err) {
        this.error = true;
        this.$toast.add({
          severity: "error",
          summary: "Erro",
          detail: "Aconteceu um erro, por favor tente mais tarde!",
          life: 3000,
        });
      } else {
        this.$toast.add({
          severity: "success",
          summary: "Sucesso",
          detail: "Template criado com sucesso",
          life: 3000,
        });
        this.showDialog = false;
        this.loader.hide();

        this.v$.$reset();
        this.getData();
      }

      this.loader.hide();
      this.loading = false;
    },

    async updateTemplate() {
      if (!(await this.checkForm())) return;

      this.error = false;
      this.loading = true;

      this.loader = this.$loading.show();
      const [, err] = await updateTemplate(this.template);
      if (err) {
        this.error = true;
        this.$toast.add({
          severity: "error",
          summary: "Erro",
          detail: "Aconteceu um erro, por favor tente mais tarde!",
          life: 3000,
        });
      } else {
        this.$toast.add({
          severity: "success",
          summary: "Sucesso",
          detail: "Template atualizado com sucesso",
          life: 3000,
        });
        this.showDialog = false;
        this.loader.hide();

        this.v$.$reset();
        this.getData();
      }

      this.loader.hide();
      this.loading = false;
    },

    async removeTemplate() {
      this.loading = true;
      this.loader = this.$loading.show();
      const [, err] = await removeTemplate(this.selectedRow);
      if (err) {
        this.showGenericDialog = false;

        this.$toast.add({
          severity: "error",
          summary: "Erro",
          detail: "Aconteceu um erro, por favor tente mais tarde!",
          life: 3000,
        });

        this.loader.hide();
      } else {
        this.showGenericDialog = false;

        this.$toast.add({
          severity: "success",
          summary: "Sucesso",
          detail: "Template apagado com sucesso",
          life: 3000,
        });
        this.loader.hide();
        this.getData();
      }
      this.loading = false;
      this.loader.hide();
    },

    onErrorStateButtonClick() {
      this.getData();
    },
    // handleRowClick: function (event) {
    //   let id = event.data.template_id;

    //   this.$store.dispatch("setTemplateGroupListSelected", event.data);

    //   this.$router.push({
    //     name: "templatedashboardoverview",
    //     params: { id },
    //     query: {
    //       prevpage: this.page,
    //       prevsize: this.size,
    //       prevfirst: this.first,
    //     },
    //   });
    // },
    handlePageChange(e) {
      this.page = e.page;
      this.size = e.rows;
      this.first = e.first;
      this.getData();
    },

    openNewTemplatePopup: function () {
      this.showDialog = true;
    },
    closeNewTemplatePopup: function () {
      this.showDialog = false;
    },
    handleFilter: function () {
      let string = "";
      this.filters.forEach(function (item, idx, array) {
        string += item.property + ":" + item.op + ":" + item.value;
        if (idx !== array.length - 1) {
          string += ",";
        }
      });

      return string;
    },
    convertTimeStampToDate: function (date) {
      return dayjs(date).format("YYYY-MM-DD HH:mm:ss");
    },
    async checkForm() {
      await this.v$.$validate();
      if (this.v$.$errors.length) return false;

      return true;
    },
  },
};
</script>

<style lang="scss">
.p-tabview-panels {
  padding-top: 0px !important;
}

.p-dropdown .p-dropdown-label {
  padding: 0.55rem 0.6rem;
}

.label-cc {
  float: right;
  top: -25px;
  right: 5px;
  position: relative;
  font-size: 14px;
}
</style>
