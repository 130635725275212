import axios from "axios";

export const getProjects = async () => {
  return fetch("/projects.json")
    .then((res) => res.json())
    .then((d) => d.data);
};

export const getCountryCodes = async () => {
  try {
    const countryCodes = await axios.get("/CountryCodes.json", {
      maxContentLength: 100000000,
      maxBodyLength: 1000000000,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const localeCodes = await axios.get(`/codes.json`);

    const countries = countryCodes.data.map((item) => ({
      name: localeCodes.data[item.code] + " - (" + item.dial_code + ")",
      code: item.dial_code,
    }));
    return [countries, null];
  } catch (err) {
    return [null, err];
  }
};
