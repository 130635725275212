<template>
  <ErrorState
    v-if="!loading && error"
    @onErrorStateButtonClick="onErrorStateButtonClick()"
    message="Algo não correu bem"
    buttonText="Voltar a tentar"
  ></ErrorState>
  <Card class="card" v-if="!loading && !error">
    <template #content>
      <div>
        <h4>{{ $t("views.catalog.formLabels.general") }}</h4>

        <div class="grid card">
          <div class="p-field col-6 md:col-6 lg:col-3">
            <label for="sender">{{
              $t("views.message.formLabels.sender")
            }}</label>
            <p>{{ data.sender }}</p>
          </div>
          <div class="p-field col-6 md:col-6 lg:col-3">
            <label for="recipients">{{
              $t("views.message.formLabels.recipients")
            }}</label>
            <p>{{ data.totalRecipients }}</p>
          </div>
          <div class="p-field col-6 md:col-6 lg:col-3">
            <label for="deliveryDate">{{
              $t("views.message.formLabels.deliveryDate")
            }}</label>
            <p>{{ convertEpochToDate(data.submissionDate) }}</p>
          </div>
          <div class="p-field col-6 md:col-6 lg:col-3">
            <label for="deliveryDate">
              {{ $t("views.message.formLabels.encoding") }}</label
            >
            <p>
              <span class="customer-badge type-campaign">
                {{ data.smartEncode ? "UCS-2" : "GSM-7" }}
              </span>
            </p>
          </div>
        </div>
        <div class="grid cart">
          <div class="p-field col-6 md:col-6 lg:col-6">
            <label for="deliveryDate">
              {{ $t("views.message.formLabels.identifier") }}</label
            >
            <p>
              {{ data.id }}
            </p>
          </div>
        </div>

        <Divider />

        <h4>{{ $t("views.message.formLabels.message") }}</h4>
        <div class="p-field col-6 md:col-6 lg:col-6">
          <p>{{ data.text }}</p>
        </div>

        <Divider />

        <h4>{{ $t("views.message.formLabels.typology") }}</h4>

        <div class="grid card">
          <div class="p-field col-6 md:col-6 lg:col-3">
            <label for="deliveryType">{{
              $t("views.message.formLabels.messageValidity")
            }}</label>
            <p>{{ Math.floor(data.ttl / 3600) }}h</p>
          </div>
          <div class="p-field col-6 md:col-6 lg:col-3">
            <label for="deliveryReport">{{
              $t("views.message.formLabels.deliveryReport")
            }}</label>
            <p>
              {{
                deliveryReports.filter((key) => key.code == data.dlrType)[0]
                  .name
              }}
            </p>
          </div>
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import ErrorState from "@/components/utils/ErrorState.vue";
import AuthApp from "../main";
import { getMessage } from "@/services/log.service";
import dayjs from "dayjs";

export default {
  name: "messagelist",
  data: function () {
    return {
      auth: AuthApp,
      showFilters: false,
      loading: true,
      data: {},
      message: {},
      deliveryReports: [
        { name: "Todos", code: "ALL" },
        { name: "Nenhum", code: "NONE" },
        { name: "Só entregues", code: "DELIVERED" },
        { name: "Não entregues", code: "NON_DELIVERED" },
      ],
    };
  },
  components: {
    ErrorState,
  },
  computed: {},
  mounted() {
    this.data = this.$store.getters.messageSelected;
    if (Object.keys(this.data).length) {
      this.loading = false;
    } else {
      this.getData();
    }
  },
  methods: {
    async getData() {
      this.error = false;
      this.loading = true;
      this.loader = this.$loading.show();

      let params = this.$route.params;

      const [res, err] = await getMessage(params.id);

      if (err) {
        this.error = true;
        this.$toast.add({
          severity: "error",
          summary: "Erro",
          detail: "Aconteceu um erro, por favor tente mais tarde!",
          life: 3000,
        });
      } else {
        this.data = res;
        this.onUpdateResourseChange(res);
        this.$store.dispatch("setMessageSelected", this.data);
      }

      this.loader.hide();
      this.loading = false;
    },
    convertEpochToDate: function (date) {
      return dayjs.unix(date).format("YYYY-MM-DD HH:mm:s");
    },
    onUpdateResourseChange(data) {
      this.$emit("onUpdateResourseChange", data);
    },
    onErrorStateButtonClick: function () {
      this.getData();
    },
  },
};
</script>

<style lang="scss">
.p-tabview-panels {
  padding-top: 0px !important;
}

.p-dropdown .p-dropdown-label {
  padding: 0.55rem 0.6rem;
}
</style>
