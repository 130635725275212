<template>
  <Page
    leftIcon="pi pi-arrow-left"
    :title="title"
    @onIconClick="handlePreviousButton"
    @onTabClick="changeSelectedTab"
    :tabOptions="tabOptions"
  >
    <template v-slot:content v-if="!loading">
      <div class="clients-content">
        <TabView :activeIndex="activeIndex">
          <!-- SMS OVERVIEW-->
          <TabPanel header="Campanha">
            <CampaignOverview
              v-if="activeIndex === 0"
              @onTitleChange="updateTitle"
            ></CampaignOverview>
          </TabPanel>
        </TabView>
      </div>
    </template>
  </Page>
</template>

<script>
import Page from "@/components/Page.vue";
import CampaignOverview from "@/components/CampaignOverview";

export default {
  components: {
    Page,
    CampaignOverview,
  },
  setup() {},
  mounted() {
    this.title = this.$store.getters.campaignSelected.title;
  },
  data() {
    return {
      loading: false,
      activeIndex: 0,
      title: "",
      tabOptions: [{ value: "Campanha", selected: true, visible: true }],
    };
  },
  computed: {},
  validations() {},
  methods: {
    handlePreviousButton: function () {
      let params = this.$route.query;
      this.$router.push({ name: "campaigndashboard", query: { ...params } });
    },
    updateTitle: function (title) {
      this.title = title;
    },
    changeSelectedTab(e) {
      this.tabOptions.forEach((element) => {
        element.selected = false;
      });
      this.tabOptions[e].selected = true;
      this.activeIndex = e;
    },
  },
};
</script>

<style lang="scss" scoped></style>
