import Model from "../Model";

export class BillingAccount extends Model {
  billingAccountNumber = "";
  constructor(data) {
    super(data);
    if (data) {
      this.setValueFromData(
        "billingAccountNumber",
        String(data.billingAccountNumber).toString()
      );
    }
  }
}
