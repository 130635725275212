import dayjs from "dayjs";
import Model from "../Model";

export class Product extends Model {
  productId = undefined;
  startDate = dayjs(new Date()).format("YYYY-MM-DD");
  constructor(data) {
    super(data);
    if (data) {
      this.setValueFromData("productId", Number(data.productId).toFixed());
      this.setValueFromData("startDate", new String(data.startDate).toString());
    }
  }
}
