const firstSMSLimit = 160;
const secondSMSLimit = 306;
const thirdSMSLimit = 459;
const fourthSMSLimit = 612;
const fifthSMSLimit = 765;

const maxVar1Length = 0;
const maxVar2Length = 0;
const maxVar3Length = 0;
const maxVar4Length = 0;
const maxVar5Length = 0;
const maxVar6Length = 0;
const maxVar7Length = 0;
const maxVar8Length = 0;
const maxVar9Length = 0;
const maxVar10Length = 0;

let finalMaxLength = 0;
let chars = 0;
let remain = 0;
let currLimit = 0;
let nrMsgs = 0;

export const getnrMsgs = function (txtMessage) {
  var initConsumedChar = 0;
  var textAreaMaxSmsNr = 5;

  finalMaxLength = CalculateMessageMaxSize(textAreaMaxSmsNr, initConsumedChar);
  chars = countChars(txtMessage);

  var res = getLimitAndNrMsgs(chars + initConsumedChar, initConsumedChar);
  currLimit = res[0];
  nrMsgs = res[1];

  chars = countChars(txtMessage);

  var resLimit = getLimitAndNrMsgs(chars + initConsumedChar, initConsumedChar);
  currLimit = resLimit[0];
  nrMsgs = resLimit[1];

  return { currLimit, nrMsgs, chars };
};

function countChars(txtMessage) {
  var countChars;

  countChars = `${txtMessage}`
    .replace(/([{}[\]\\|^~€])/g, "\\$1")
    .replace(/\n/g, "--").length;

  if (countChars > finalMaxLength) {
    remain = parseInt(countChars - finalMaxLength);

    while (remain > 0) {
      var endVariable = txtMessage.match(/<TEXTO(10|[1-9]){0,1}>$/);
      var subtractedCharsLength = 0;
      if (endVariable) {
        switch (endVariable[0]) {
          case "<TEXTO>":
          case "<TEXTO1>":
            subtractedCharsLength = maxVar1Length;
            break;

          case "<TEXTO2>":
            subtractedCharsLength = maxVar2Length;
            break;

          case "<TEXTO3>":
            subtractedCharsLength = maxVar3Length;
            break;

          case "<TEXTO4>":
            subtractedCharsLength = maxVar4Length;
            break;

          case "<TEXTO5>":
            subtractedCharsLength = maxVar5Length;
            break;

          case "<TEXTO6>":
            subtractedCharsLength = maxVar6Length;
            break;

          case "<TEXTO7>":
            subtractedCharsLength = maxVar7Length;
            break;

          case "<TEXTO8>":
            subtractedCharsLength = maxVar8Length;
            break;

          case "<TEXTO9>":
            subtractedCharsLength = maxVar9Length;
            break;

          case "<TEXTO10>":
            subtractedCharsLength = maxVar10Length;
            break;

          default:
            subtractedCharsLength = 0;
        }
      } else {
        var endChar = txtMessage.substr(txtMessage.length - 1, 1);
        subtractedCharsLength = endChar
          .replace(/([{}[\]\\|^~€])/g, "\\$1")
          .replace(/\n/g, "--").length;
      }
      remain = remain - subtractedCharsLength;
      countChars = countChars - subtractedCharsLength;
    }
  }
  return countChars;
}

function getLimitAndNrMsgs(currCount, initConsumedChar) {
  if (currCount + initConsumedChar <= firstSMSLimit) {
    currLimit = firstSMSLimit - initConsumedChar;
    nrMsgs = 1;
  } else if (currCount <= secondSMSLimit) {
    currLimit = secondSMSLimit - initConsumedChar;
    nrMsgs = 2;
  } else if (currCount <= thirdSMSLimit) {
    currLimit = thirdSMSLimit - initConsumedChar;
    nrMsgs = 3;
  } else if (currCount <= fourthSMSLimit) {
    currLimit = fourthSMSLimit - initConsumedChar;
    nrMsgs = 4;
  } else {
    currLimit = fifthSMSLimit - initConsumedChar;
    nrMsgs = 5;
  }
  return [currLimit, nrMsgs];
}

function CalculateMessageMaxSize(smsNr, initConsumedChar) {
  switch (smsNr) {
    case 1:
      return firstSMSLimit - initConsumedChar;
    case 2:
      return secondSMSLimit - initConsumedChar;
    case 3:
      return thirdSMSLimit - initConsumedChar;
    case 4:
      return fourthSMSLimit - initConsumedChar;
    case 5:
      return fifthSMSLimit - initConsumedChar;
    default:
      return -1;
  }
}
