import keycloakInstance, { initOptions } from "../../keycloak";
import jwt_decode from "jwt-decode";

class Auth {
  constructor(initVue, loading) {
    this.initAuthApp(initVue, loading);
  }

  setToken() {
    if (keycloakInstance.token) {
      this.keycloakInstance = keycloakInstance;
      this.token = keycloakInstance.token;
      this.decoded = jwt_decode(keycloakInstance.token);
      //FIXME default to subaccount in index zero, but that's not true in the future
      this.role = this.decoded?.mpro.subaccounts[0].role;
      this.permissions = this.decoded?.permissions?.auth;
      this.email_verifie = this.decoded?.email_verified;
      this.email = this.decoded.email;
      this.mpro_scope = keycloakInstance.tokenParsed;
      this.account_state = this.decoded?.mpro["account-state"]
        ? this.decoded?.mpro["account-state"]
        : "ENABLED";
    }
  }

  guardRoutes(to, _from, next) {
    next();
    // const guard = guardRoles[to.name];
    // if ((guard && roles.includes(guard)) || guard === "*") {
    //   next();
    // }
  }

  initAuthApp(initVue, loading) {
    loading.mount("#loading");
    keycloakInstance
      .init({ onLoad: initOptions.onLoad })
      .then((auth) => {
        if (!auth) {
          window.location.reload();
        } else {
          this.setToken();
          initVue();
        }

        //Token Refresh
        setInterval(() => {
          keycloakInstance
            .updateToken(50)
            .then((refreshed) => {
              if (refreshed) {
                // console.log("Token refreshed" + refreshed);
                this.setToken();
              }
              // else {
              //   console.log(
              //     "Token not refreshed, valid for " +
              //       Math.round(
              //         keycloakInstance.tokenParsed.exp +
              //           keycloakInstance.timeSkew -
              //           new Date().getTime() / 1000
              //       ) +
              //       " seconds"
              //   );
              // }
            })
            .catch((error) => {
              console.error("Failed to refresh token:" + error);
            });
        }, 20000);
      })
      .catch((err) => {
        console.error("Authenticated Failed", err);
        document.getElementById("loading").style.display = "none";
        window.location.href = "/error.html";
      });
  }

  logout() {
    if (this.keycloakInstance) {
      let logoutOptions = {
        redirectUri: process.env.VUE_APP_MPRO_INDEX,
      };

      console.log(logoutOptions);
      this.keycloakInstance.logout(logoutOptions);
      // document.cookie =
      //   "KEYCLOAK_SESSION=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      // document.cookie =
      //   "AUTH_SESSION_ID=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      // window.location.href = "http://localhost:8080";
    }
    // else {
    //   console.log("NOTHING TO LOGOUT");
    // }
  }
}

export default Auth;
