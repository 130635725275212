<template>
  <ErrorState
    v-if="!loading && error"
    @onErrorStateButtonClick="onErrorStateButtonClick()"
    message="Algo não correu bem"
    buttonText="Voltar a tentar"
  ></ErrorState>
  <EmptyState
    v-if="!loading && !error && !items.length"
    @onEmptyStateButtonClick="$router.push({ name: 'newMessage' })"
    message="Sem mensagens"
    buttonText="Enviar mensagem"
    :showButton="auth.account_state === 'ENABLED' ? true : false"
  ></EmptyState>
  <div
    class="vl-parent"
    ref="formContainer"
    style="position: relative; min-height: 150px"
  >
    <div v-if="!loading && !error && items.length">
      <div class="flex align-items-stretch flex-wrap">
        <div class="flex align-items-start results">
          {{ totalRecords }} resultado(s)
        </div>
        <div
          class="flex-grow-1 flex align-items-center justify-content-center"
        ></div>
        <div
          class="flex align-items-end results cursor-pointer"
          @click="showFilters = !showFilters"
          v-if="showFiltersArea"
        >
          <template v-if="showFilters">
            Fechar filtros
            <img class="menu-icon" src="/resources/icons/close.svg" />
          </template>
          <template v-else>
            <img class="menu-icon" src="/resources/icons/filter.svg" />
            Filtros
          </template>
        </div>
      </div>
      <Divider />

      <Card class="card fadein animation-duration-500" v-if="showFilters">
        <template #content>
          <div class="formgrid grid">
            <div class="field col-12 md:col-2">
              <!-- <label for="city">Data</label> -->
              <Calendar
                id="searchDate"
                v-model="searchDate"
                selectionMode="range"
                dateFormat="yy-mm-dd"
                :showIcon="true"
                placeholder="--"
                :minDate="minDate()"
                :manualInput="false"
                class="w-full"
                @hide="handleDate($event)"
              />
              <small id="searchDate">Período</small>
            </div>
            <div class="field col-12 md:col-2">
              <Dropdown
                id="searchSender"
                v-model="searchSender"
                :options="senders"
                optionLabel="name"
                placeholder="--"
                class="w-full"
                @change="handleSender($event)"
              />
              <small id="searchSender">Remetente</small>

              <!-- <select
              id="state"
              class="w-full p-inputtext p-component"
              style="appearance: auto"
            > -->
              <!-- <option default>Todos os Remetentes</option> -->
              <!-- </select> -->
            </div>
            <div class="field col-12 md:col-2 p-input-icon-right">
              <span class="p-input-icon-right w-full">
                <InputText
                  id="searchTitle"
                  type="text"
                  v-model="searchTitle"
                  class="w-full"
                  @keyup="handleSearchTitle($event.target.value)"
                />
                <i class="pi pi-search"></i>
              </span>
              <small id="searchTitle">Título</small>
            </div>
            <div class="field col-12 md:col-2 p-input-icon-right">
              <span class="p-input-icon-right w-full">
                <InputText
                  id="searchTo"
                  type="text"
                  class="w-full"
                  v-model="searchTo"
                  @keyup="handleSearchTo($event.target.value)"
                />
                <i class="pi pi-search"></i>
              </span>
              <small id="searchTo">Número</small>
            </div>
          </div>
        </template>
      </Card>

      <DataTable
        :rowHover="true"
        :value="items"
        :paginator="false"
        :row-hover="true"
        responsiveLayout="scroll"
        @row-click="handleRowClick($event)"
      >
        <Column
          field="title"
          :header="$t('views.message.dashboard.table.title')"
        >
          <template #body="slotProps">
            {{ slotProps.data.title }}
          </template>
        </Column>
        <Column
          field="title"
          :header="$t('views.message.dashboard.table.type')"
        >
          <template #body="slotProps">
            <span
              :class="
                'customer-badge type-' +
                slotProps.data.messageType.toLowerCase()
              "
              >{{
                $t(
                  "views.general.type." +
                    slotProps.data.messageType.toLowerCase()
                )
              }}
            </span>
          </template>
        </Column>
        <Column
          field="submissionDate"
          :header="$t('views.message.dashboard.table.submissionDate')"
        >
          <template #body="slotProps">
            {{ convertEpochToDate(slotProps.data.submissionDate) }}
          </template>
        </Column>
        <Column
          field="totalRecipients"
          :header="$t('views.message.dashboard.table.totalRecipients')"
        >
        </Column>
        <Column
          field="totalSms"
          :header="$t('views.message.dashboard.table.totalSms')"
        >
        </Column>
        <Column
          field="sender"
          :header="$t('views.message.dashboard.table.sender')"
        >
        </Column>
      </DataTable>
      <CustomPaginator
        :size="size"
        :totalRecords="Number(totalRecords)"
        :first="first"
        :rowsPerPageOptions="[10, 20, 30]"
        @pageChange="handlePageChange"
      />
    </div>
  </div>
</template>

<script>
import ErrorState from "@/components/utils/ErrorState.vue";
import CustomPaginator from "@/components/CustomPaginator.vue";
import AuthApp from "../main";
import dayjs from "dayjs";
import EmptyState from "@/components/utils/EmptyState.vue";
import { getMessages } from "@/services/log.service";
// import { getSender } from "@/services/account.service.js";

export default {
  name: "messagelist",
  data: function () {
    return {
      auth: AuthApp,
      showFilters: false,
      loading: false,
      catalog: {},
      searchDate: null,
      searchSender: null,
      searchTitle: null,
      senders: [{ name: "Todos os Remetentes", code: "ALL" }],
      items: [],
      filters: [],
      first: 0,
      size: 10,
      page: 0,
      fullPage: false,
    };
  },
  props: {
    showFiltersArea: Boolean,
    showLoadingFullPage: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    ErrorState,
    CustomPaginator,
    EmptyState,
  },
  mounted() {
    let params = this.$route.query;
    if (params.prevpage) {
      this.page = parseInt(params.prevpage, 10);
    }
    if (params.prevsize) {
      this.size = parseInt(params.prevsize, 10);
    }
    if (params.prevfirst) {
      this.first = parseInt(params.prevfirst, 10);
    }

    //clean up just in case
    this.$router.replace({ query: null });

    this.getData();
    // this.getSender();
  },
  computed: {},
  methods: {
    async getData() {
      this.error = false;
      this.loading = true;
      console.log("this.$refs.formContainer:", this.$refs.formContainer);
      this.loader = this.$loading.show({
        // Optional parameters
        container: this.showLoadingFullPage ? null : this.$refs.formContainer,
        canCancel: true,
        onCancel: this.onCancel,
        isFullPage: false,
      });

      var filter = this.handleFilter();

      const [res, err] = await getMessages({
        filter: filter,
        page: this.page,
        size: this.size,
      });

      if (err) {
        this.error = true;
        this.$toast.add({
          severity: "error",
          summary: "Erro",
          detail: "Aconteceu um erro, por favor tente mais tarde!",
          life: 3000,
        });
      } else {
        this.items = res.items;
        this.totalRecords = res.totalItems;
      }

      this.loader.hide();
      this.loading = false;
    },

    onErrorStateButtonClick() {
      this.getData();
    },
    handleSearchTitle: function () {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.filters = this.filters.filter(function (item) {
          return item.property !== "title";
        });

        if (this.searchTitle) {
          this.filters.push({
            property: "title",
            op: "LIKE",
            value: this.searchTitle,
          });
        }

        this.getData();
      }, 800);
    },
    handleSearchTo: function () {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.filters = this.filters.filter(function (item) {
          return item.property !== "to";
        });

        if (this.searchTo) {
          this.filters.push({
            property: "to",
            op: "LIKE",
            value: this.searchTo,
          });
        }

        this.getData();
      }, 800);
    },
    handleRowClick: function (event) {
      let id = event.data.id;

      this.$store.dispatch("setMessageSelected", event.data);

      this.$router.push({
        name: "messagedashboardoverview",
        params: { id },
        query: {
          prevpage: this.page,
          prevsize: this.size,
          prevfirst: this.first,
        },
      });
    },
    handlePageChange(e) {
      this.page = e.page;
      this.size = e.rows;
      this.first = e.first;
      this.getData();
    },
    handleDate: function () {
      //clean previous values
      this.filters = this.filters.filter(function (item) {
        return item.property !== "submissionDate";
      });

      if (this.searchDate) {
        this.filters.push({
          property: "submissionDate",
          op: "GTE",
          value: dayjs(this.searchDate[0]).format("YYYY-MM-DD"),
        });

        //in case we're dealing with range
        if (this.searchDate.length == 2 && this.searchDate[1] != null) {
          this.filters.push({
            property: "submissionDate",
            op: "LTE",
            value: dayjs(this.searchDate[1]).format("YYYY-MM-DD"),
          });
        }
      }

      this.getData();
    },
    handleSender: function () {
      this.filters = this.filters.filter(function (item) {
        return item.property !== "sender";
      });

      if (this.searchSender.code != "ALL") {
        this.filters.push({
          property: "sender",
          op: "EQ",
          value: this.searchSender.name,
        });
      }

      this.getData();
    },

    handleFilter: function () {
      let string = "";
      this.filters.forEach(function (item, idx, array) {
        string += item.property + ":" + item.op + ":" + item.value;
        if (idx !== array.length - 1) {
          string += ",";
        }
      });

      return string;
    },
    minDate: function () {
      var d = new Date();
      d.setMonth(d.getMonth() - 6);
      return d;
    },

    convertEpochToDate: function (date) {
      return dayjs.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },

    //FIXME - cache this in a storage like VUEX..
    // async getSender() {
    //   var self = this;

    //   const [res, err] = await getSender(this.auth.decoded.mpro.account);

    //   if (!err) {
    //     res.items.forEach(function (elem) {
    //       self.senders.push({
    //         code: elem.id,
    //         name: elem.name,
    //       });
    //     });
    //   }
    // },
  },
};
</script>

<style lang="scss">
.p-tabview-panels {
  padding-top: 0px !important;
}

.p-dropdown .p-dropdown-label {
  padding: 0.55rem 0.6rem;
}
</style>
