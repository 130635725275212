import { createStore } from "vuex";

const store = createStore({
  state: {
    messageSelected: {},
    account: {},
  },
  getters: {
    campaignSelected(state) {
      return state.campaignSelected;
    },
    messageSelected(state) {
      return state.messageSelected;
    },
    account(state) {
      return state.account;
    },
    contactGroupListSelected(state) {
      return state.contactGroupListSelected;
    },
    // templateGroupListSelected(state) {
    //   return state.templateGroupListSelected;
    // },˙
  },
  mutations: {
    setCampaignSelected: function (state, campaign) {
      state.campaignSelected = campaign;
    },
    setMessageSelected: function (state, message) {
      state.messageSelected = message;
    },
    setAccount: function (state, account) {
      state.account = account;
    },
    setContactGroupListSelected: function (state, message) {
      state.contactGroupListSelected = message;
    },
    // setTemplateGroupListSelected: function (state, message) {
    //   state.templateGroupListSelected = message;
    // },
  },
  actions: {
    setCampaignSelected: ({ commit, state }, campaign) => {
      commit("setCampaignSelected", campaign);
      return state.campaign;
    },
    setMessageSelected: ({ commit, state }, message) => {
      commit("setMessageSelected", message);
      return state.message;
    },
    setAccount: ({ commit, state }, account) => {
      commit("setAccount", account);
      return state.account;
    },
    setContactGroupListSelected: ({ commit, state }, message) => {
      commit("setContactGroupListSelected", message);
      return state.message;
    },
    // setTemplateGroupListSelected: ({ commit, state }, message) => {
    //   commit("setTemplateGroupListSelected", message);
    //   return state.message;
    // },
  },
});

export default store;
