<template>
  <div class="error-state">
    <img src="/resources/images/error_state.svg" />
    <div>
      <h2>{{ message }}</h2>
      <button
        class="p-button p-component large"
        type="button"
        @click="onErrorStateButtonClick"
      >
        {{ buttonText }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorState",
  props: {
    message: String,
    buttonText: String,
  },
  setup() {
    // console.log(props.items);
  },
  methods: {
    onErrorStateButtonClick(e) {
      this.$emit("onErrorStateButtonClick", e);
    },
  },
};
</script>

<style scoped>
.error-state {
  text-align: center;
}
.error-state button {
  padding-left: 40px !important;
  padding-right: 40px !important;
}
</style>
