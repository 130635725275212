import http from "../shared/services/http";

export const getAccount = async () => {
  try {
    const result = await http.get(`/customer/v1/account`);
    return [result, null];
  } catch (err) {
    return [null, err];
  }
};

export const getAccountCatalog = async () => {
  try {
    const result = await http.get(`/customer/v2/product`);
    return [result, null];
  } catch (err) {
    return [null, err];
  }
};

export const getSender = async () => {
  try {
    const result = await http.get(`/customer/v2/sender`);
    return [result, null];
  } catch (err) {
    return [null, err];
  }
};

export const getLongNumber = async () => {
  try {
    const result = await http.get(`/customer/v2/longnumber`);
    return [result, null];
  } catch (err) {
    return [null, err];
  }
};

export const createLongNumber = async () => {
  try {
    const result = await http.put(`/customer/v2/longnumber`);
    return [result, null];
  } catch (err) {
    return [null, err];
  }
};

export const removeLongNumber = async (id) => {
  try {
    const result = await http.delete(`/customer/v2/longnumber/${id}`);
    return [result, null];
  } catch (err) {
    return [null, err];
  }
};
