<template>
  <ErrorState
    v-if="!loading && error"
    @onErrorStateButtonClick="onErrorStateButtonClick()"
    message="Algo não correu bem"
    buttonText="Voltar a tentar"
  ></ErrorState>

  <div v-if="!error" class="flex align-items-stretch flex-wrap">
    <div class="flex align-items-start results">
      {{ totalRecords }} resultado(s)
    </div>
    <div
      class="flex-grow-1 flex align-items-center justify-content-center"
    ></div>
    <div
      class="flex align-items-end results cursor-pointer"
      @click="showFilters = !showFilters"
      v-if="showFiltersArea"
    >
      <template v-if="showFilters">
        Fechar filtros
        <img class="menu-icon" src="/resources/icons/close.svg" />
      </template>
      <template v-else>
        <img class="menu-icon" src="/resources/icons/filter.svg" />
        Filtros
      </template>
    </div>
  </div>
  <Divider />
  <EmptyState
    v-if="!loading && !error && !items.length && !handledFilters?.length"
    message="Sem mensagens"
    :showButton="false"
  ></EmptyState>
  <DataTable
    v-if="items.length || handledFilters?.length"
    :rowHover="true"
    :value="items"
    :paginator="false"
    :row-hover="true"
    responsiveLayout="scroll"
    @sort="onSort($event)"
    @filter="onFilter($event)"
    filterDisplay="row"
    v-model:filters="filters"
    lazy
  >
    <template #empty> Sem mensagens disponíveis </template>
    <Column field="to" :header="$t('views.message.formLabels.phoneNumber')">
      <template #filter="{ filterModel, filterCallback }">
        <InputText
          v-model="filterModel.value"
          type="text"
          @input="filterCallback()"
          class="p-column-filter"
          placeholder="filtrar por número"
          :disabled="loading"
        />
      </template>
    </Column>
    <Column field="from" :header="$t('views.message.dashboard.table.sender')">
      <template #filter="{ filterModel, filterCallback }">
        <InputText
          v-model="filterModel.value"
          type="text"
          @input="filterCallback()"
          class="p-column-filter"
          placeholder="filtrar por remetente"
          :disabled="loading"
        />
      </template>
    </Column>
    <Column
      field="text"
      :header="$t('views.message.formLabels.text')"
      style="max-width: 600px !important"
      class="overflow-auto"
    >
      <template #filter="{ filterModel, filterCallback }">
        <InputText
          v-model="filterModel.value"
          type="text"
          @input="filterCallback()"
          class="p-column-filter"
          placeholder="filtrar por texto"
          :disabled="loading"
        />
      </template>
      <template #body="slotProps">
        {{ slotProps.data.text }}
      </template>
    </Column>
    <Column field="type" :header="$t('views.message.formLabels.smsType')">
      <template #body="slotProps">
        {{
          $t(
            "views.message.formLabels.smsTypeCategories." + slotProps.data.type
          )
        }}
      </template>
      <template #filter="{ filterModel, filterCallback }">
        <Dropdown
          id="searchStatus"
          v-model="filterModel.value"
          :options="availableType"
          optionLabel="name"
          optionValue="code"
          :placeholder="availableType[0].name"
          class="w-full"
          @change="filterCallback($event)"
          :disabled="loading"
        />
      </template>
    </Column>
    <Column
      field="messageType"
      :header="$t('views.message.formLabels.messageType')"
    >
      <template #body="slotProps">
        <span
          :class="
            'customer-badge type-' + slotProps.data.messageType.toLowerCase()
          "
        >
          {{
            $t(
              "views.message.formLabels.messageTypeCategories." +
                slotProps.data.messageType
            )
          }}
        </span>
      </template>
      <template #filter="{ filterModel, filterCallback }">
        <Dropdown
          id="searchStatus"
          v-model="filterModel.value"
          :options="availableMessageType"
          optionLabel="name"
          optionValue="code"
          :placeholder="availableMessageType[0].name"
          class="w-full"
          @change="filterCallback($event)"
          :disabled="loading"
        />
      </template>
    </Column>
    <Column field="status" :header="$t('views.message.formLabels.status')">
      <template #body="slotProps">
        {{
          slotProps.data.type == "MO"
            ? "--"
            : slotProps.data.dlrStatus
            ? $t("views.message.formLabels.dlr." + slotProps.data.dlrStatus)
            : "--"
        }}
      </template>
      <template #filter="{ filterModel, filterCallback }">
        <Dropdown
          id="searchStatus"
          v-model="filterModel.value"
          :options="availableStatus"
          optionLabel="name"
          optionValue="code"
          :placeholder="availableStatus[0].name"
          class="w-full"
          @change="filterCallback($event)"
          :disabled="loading"
        />
      </template>
    </Column>
    <Column
      field="date"
      :header="$t('views.message.dashboard.table.submissionDate')"
      sortField="date"
      sortable
    >
      <template #body="slotProps">
        {{ convertEpochToDate(slotProps.data.date) }}
      </template>
    </Column>
  </DataTable>
  <CustomPaginator
    :size="size"
    :totalRecords="Number(totalRecords)"
    :first="first"
    :rowsPerPageOptions="[10, 20, 30]"
    @pageChange="handlePageChange"
    v-if="items.length"
  />
</template>

<script>
import ErrorState from "@/components/utils/ErrorState.vue";
import CustomPaginator from "@/components/CustomPaginator.vue";
import AuthApp from "../main";
import dayjs from "dayjs";
import EmptyState from "@/components/utils/EmptyState.vue";
import { getMessageGenericSms } from "@/services/log.service";
// import { getSender } from "@/services/account.service.js";

export default {
  name: "messagegenericlist",
  data: function () {
    return {
      auth: AuthApp,
      showFilters: false,
      loading: false,
      catalog: {},
      searchDate: null,
      searchSender: null,
      searchTitle: null,
      senders: [{ name: "Todos os Remetentes", code: "ALL" }],
      items: [],
      availableStatus: [
        { name: "ALL", code: "" },
        { name: "SENT", code: "SENT" },
        { name: "DELIVERED", code: "DELIVERED" },
        { name: "NON_DELIVERED", code: "NON_DELIVERED" },
      ],
      availableType: [
        { name: "ALL", code: "" },
        { name: "MT", code: "MT" },
        { name: "MO", code: "MO" },
      ],
      availableMessageType: [
        { name: "ALL", code: "" },
        { name: "SMS", code: "SMS" },
        { name: "CAMPAIGN", code: "CAMPAIGN" },
      ],
      filters: {
        to: { value: null, matchMode: "LIKE" },
        text: { value: null, matchMode: "LIKE" },
        status: { value: null, matchMode: "LIKE" },
        type: { value: null, matchMode: "LIKE" },
        messageType: { value: null, matchMode: "LIKE" },
        from: { value: null, matchMode: "LIKE" },
      },
      first: 0,
      size: 10,
      page: 0,
    };
  },
  props: {
    showFiltersArea: Boolean,
  },
  components: {
    ErrorState,
    CustomPaginator,
    EmptyState,
  },
  mounted() {
    this.loadAvailableStatus();
    this.loadAvailableType();
    this.loadAvailableMessageType();
    this.getData();
  },
  computed: {},
  methods: {
    async getData() {
      this.error = false;
      this.loading = true;
      this.loader = this.$loading.show();

      let filters = this.handledFilters?.length
        ? JSON.stringify(this.handledFilters)
        : "";

      let sort = this.handledSort?.length
        ? JSON.stringify(this.handledSort)
        : "";

      const [res, err] = await getMessageGenericSms({
        filter: filters,
        sort: sort,
        page: this.page,
        size: this.size,
      });

      if (err) {
        this.error = true;
        this.$toast.add({
          severity: "error",
          summary: "Erro",
          detail: "Aconteceu um erro, por favor tente mais tarde!",
          life: 3000,
        });
      } else {
        this.items = res.items;
        this.totalRecords = res.totalItems;
      }
      this.loading = false;
      this.loader.hide();
    },
    loadAvailableStatus: function () {
      var self = this;
      this.availableStatus.forEach((item) => {
        item.name = self.$t("views.message.formLabels.dlr." + item.name);
        // item.code = item.code;
      });
    },
    loadAvailableType: function () {
      var self = this;
      this.availableType.forEach((item) => {
        item.name = self.$t(
          "views.message.formLabels.smsTypeCategories." + item.name
        );
        // item.code = item.code;
      });
    },
    loadAvailableMessageType: function () {
      var self = this;
      this.availableMessageType.forEach((item) => {
        item.name = self.$t(
          "views.message.formLabels.messageTypeCategories." + item.name
        );
        // item.code = item.code;
      });
    },

    onErrorStateButtonClick() {
      this.getData();
    },
    // handleRowClick: function (event) {
    //   let id = event.data.id;

    //   this.$store.dispatch("setMessageSelected", event.data);

    //   this.$router.push({
    //     name: "messagedashboardoverview",
    //     params: { id },
    //     query: {
    //       prevpage: this.page,
    //       prevsize: this.size,
    //       prevfirst: this.first,
    //     },
    //   });
    // },
    handlePageChange(e) {
      this.page = e.page;
      this.size = e.rows;
      this.first = e.first;
      this.getData();
    },

    minDate: function () {
      var d = new Date();
      d.setMonth(d.getMonth() - 6);
      return d;
    },

    convertEpochToDate: function (date) {
      return dayjs.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },

    onSort(e) {
      this.handledSort = [];

      if (e.sortField) {
        this.handledSort = [
          {
            property: e.sortField,
            operator: e.sortOrder == 1 ? "ASC" : "DESC",
          },
        ];
      }

      this.getData();
    },
    onFilter() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.handledFilters = Object.keys(this.filters)
          .map((key) => {
            return {
              property: key,
              operator: this.filters[key].matchMode,
              value:
                typeof this.filters[key].value === "object"
                  ? this.filters[key].value?.code
                  : this.filters[key].value,
            };
          })
          .filter((obj) => obj.value);

        //reset paging
        this.page = 0;

        this.getData();
      }, 800);
    },
  },
};
</script>

<style lang="scss" scoped>
.p-tabview-panels {
  padding-top: 0px !important;
}

.p-dropdown .p-dropdown-label {
  padding: 0.55rem 0.6rem;
}

:deep() .p-column-filter-row .p-column-filter-menu-button {
  display: none !important;
}

:deep .p-dropdown .p-inputtext {
  border: none;
}
</style>
