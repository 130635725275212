export default class Model {
  constructor(data) {
    this.data = { ...data };
  }
  setValueFromData(param, value) {
    if (this.data[param]) {
      this[param] = value;
    }
  }

  setValue(param, value) {
    if (value) this[param] = value;
  }
}
