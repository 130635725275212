<template>
  <Page
    :title="$t('views.campaign.dashboard.headerTitle')"
    @onIconClick="this.$router.push({ name: 'home' })"
  >
    <template v-slot:content v-if="!loading">
      <CampaignList v-if="activeIndex === 0"></CampaignList>
    </template>
  </Page>
</template>

<script>
import Page from "@/components/Page.vue";
import CampaignList from "@/components/CampaignList";

export default {
  components: {
    Page,
    CampaignList,
  },
  setup() {},
  mounted() {},
  data() {
    return {
      loading: false,
      activeIndex: 0,
    };
  },
  computed: {},
  validations() {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
