import http from "../shared/services/http";

export const getBillingReport = async () => {
  try {
    const result = await http.get(`/communication/v1/report/billing`);
    return [result, null];
  } catch (err) {
    return [null, err];
  }
};

export const getDlrReport = async (params) => {
  try {
    const result = await http.get(`/communication/v1/report/dlr`, params);
    return [result, null];
  } catch (err) {
    return [null, err];
  }
};
