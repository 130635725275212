<template>
  <Page :title="$t('views.dashboard.headerTitle')">
    <!-- <template v-slot:header>
      <div class="p-d-flex p-justify-end button-wrapper">
        <Button
          label="Enviar Mensagem"
          iconPos="right"
          @click="this.$router.push({ name: 'newMessage' })"
        />
      </div>
    </template> -->
    <template v-slot:content>
      <!-- message data -->
      <div class="layout">
        <template v-if="!loading">
          <div class="dashboard">
            <div class="grid">
              <!-- <div class="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12">
                <div
                  class="card p-fluid grid-nogutter widget-overview-box widget-overview-box-1"
                >
                  <div class="header">
                    <span class="overview-icon"
                      ><i class="pi pi-comment"></i></span
                    ><span class="overview-title">Mensagens</span>
                    <span class="overview-button">
                      <Button
                        label="Enviar mensagem"
                        icon="pi pi-external-link"
                        iconPos="left"
                        class="p-button-text p-button-secondary"
                        @click="this.$router.push({ name: 'newMessage' })"
                      />
                    </span>
                  </div>
                  <div class="overview-detail">
                    Envie mensagens de forma simples e rápida
                    <div class="col-6">
                      <div class="overview-number">130290</div>
                      <div class="overview-subtext">Enviadas</div>
                    </div>
                    <div class="col-6">
                      <div class="overview-number">1420</div>
                      <div class="overview-subtext">Pendentes</div>
                    </div>
                  </div>
                </div>
              </div> -->
              <!--<div class="col-12 sm:col-12 md:col-6 lg:col-4 xl:col-3">
                <div
                  class="card p-fluid grid-nogutter widget-overview-box widget-overview-box-2"
                 
                >
                <div class="header">
                  <span class="overview-icon"
                    ><i class="pi pi-comments"></i></span
                  ><span class="overview-title"
                    >Campanhas</span
                  >
                  <span class="overview-button">
                    <Button
                      label="Nova campanha"
                      icon="pi pi-external-link"
                      iconPos="left"
                      class="p-button-text p-button-secondary"
                      @click="this.$router.push({ name: 'newCampaign' })"
                    />
                  </span>
                </div>
                  <div class="grid overview-detail">
                    <div class="col-6">
                      <div class="overview-number">1500</div>
                      <div class="overview-subtext">
                        Submetidas
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="overview-number">189</div>
                      <div class="overview-subtext">
                        Pendentes
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 sm:col-12 md:col-6 lg:col-4 xl:col-3">
                <div
                  class="card p-fluid grid-nogutter widget-overview-box widget-overview-box-3"
                 
                >
                
                  <span class="overview-icon"
                    ><i class="pi pi-users"></i></span
                  ><span class="overview-title"
                    >Utilizadores</span
                  >
                  <div class="grid overview-detail">
                    <div class="col-6">
                      <div class="overview-number">12</div>
                      <div class="overview-subtext">
                        Activos
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="overview-number">1</div>
                      <div class="overview-subtext">
                        Bloqueado
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 sm:col-12 md:col-6 lg:col-4 xl:col-3">
                <div
                  class="card p-fluid grid-nogutter widget-overview-box widget-overview-box-4"
                 
                >
                <div class="header">
                  <span class="overview-icon"
                    ><i class="pi pi-euro"></i></span
                  ><span class="overview-title"
                    >Consumos</span
                  >
                  </div>
                  <div class="grid overview-detail">
                    <div class="col-6">
                      <div class="overview-number">€270</div>
                      <div class="overview-subtext">Mês</div>
                    </div>
                    <div class="col-6">
                      <div class="overview-number">€25</div>
                      <div class="overview-subtext">
                        Extras
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="col-12 xl:col-6">
                <SmsStatistics :showLoadingFullPage="false" />
              </div>
              <div class="col-12 xl:col-6">
                <MessageList
                  :showFiltersArea="false"
                  :showLoadingFullPage="false"
                />
              </div>
            </div>
            <!-- <div
              class="grid jlex justify-center align-items-center justify-content-around"
              style="height: 100%; min-height: 60vh"
            >
              <div>¬
                <div>
                  <h1>Envie de forma simples e rápida sms's!</h1>
                </div>
                <div class="text-center">
                  <Button
                    label="Enviar Mensagem"
                    class="p-button-secondary p-button-text"
                    iconPos="right"
                    @click="this.$router.push({ name: 'newMessage' })"
                  />
                </div>
              </div>
            </div> -->
          </div>
        </template>
      </div>
    </template>
  </Page>
  <!-- <div class="wrapper-flex-container">
    <main-header />
    <dashboard />
    <main-footer />
  </div> -->
</template>

<script>
// import MainHeader from "@/components/Header.vue";
// import MainFooter from "@/components/Footer.vue";
// import Dashboard from "@/components/Dashboard.vue";

import SmsStatistics from "@/components/SmsStatistics.vue";
import MessageList from "@/components/MessageList.vue";

import Page from "@/components/Page.vue";

export default {
  name: "Home",
  data: function () {
    return {};
  },
  components: {
    Page,
    SmsStatistics,
    MessageList,
    // MainHeader,
    // Dashboard,
    // MainFooter,
  },
  props: {
    // msg: String
  },
  mounted() {
    // this.$router.push("/clientes");
  },
};
</script>

<style scoped>
a {
  color: #2a2a2e;
  text-decoration: none;
  background-color: transparent;
}

.flex {
  display: flex;
}
.inside {
  border: 1px solid #000;
}
.inside-right {
  flex: 1;
  background: #c0ffee;
}

.wrapper-flex-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* aside   { flex: 1; background: rgb(223, 223, 223); } */
/* header {
  flex: 1;
  background: rgb(236 236 236);
} */
section {
  flex: 20;
  background: rgb(255, 255, 255);
}
footer {
  flex: 1;
}

.p-grid .dashboard > [class*="p-col"] {
  background-color: #ffffff;
}

.dashboard .overview-box {
  padding: 15px;
  background-color: #ffffff;
  color: #000000;
  min-height: 100px;
  border-radius: 4px;
  margin: 0 !important;
  border: 1px solid #555555;
}

/* .dashboard .overview-box.c-white {
  color: #ffffff;
  background-color: #000000;
} */

.dashboard .overview-box:hover {
  background-color: #f2f2f2;
  cursor: pointer;
}

.dashboard .overview-box .overview-box-title {
  width: 100%;
}

.dashboard .overview-box .overview-box-title > i {
  float: right;
  font-size: 25px;
}

.dashboard .overview-box .overview-box-stats {
  font-weight: bold;
  width: 100%;
}

/* GRID */
.card {
  background: #ffffff;
  padding: 10px;
  border: 0 none;
  margin-bottom: 2rem;
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 1px 3px 0 rgb(0 0 0 / 12%);
  border-radius: 4px;
}

.widget-overview-box {
  min-height: 6rem;
  position: relative;
  border-left: 4px solid;
  max-width: 400px;
}

.widget-overview-box .header {
  line-height: 35px;
}

.widget-overview-box .overview-icon {
  display: inline-block;
  border-radius: 4px;
  width: 2em;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  vertical-align: middle;
}

.widget-overview-box .overview-icon i {
  font-size: 1rem;
  line-height: inherit;
}

.widget-overview-box .overview-title {
  font-weight: 600;
  font-size: 16px;
  vertical-align: middle;
  margin-left: 0.5rem;
}
.widget-overview-box .overview-button {
  text-align: right;
  float: right;
}

.widget-overview-box .overview-detail {
  text-align: center;
  margin-top: 1rem;
}
.widget-overview-box .col-6:first-child {
  border-right: 1px solid #dee2e6;
}

/* .widget-overview-box .overview-detail .col-6 {
  padding: 0.5rem;
} */

.widget-overview-box .overview-number {
  font-size: 15px;
  font-weight: 600;
  color: var(--grey-400);
}

.widget-overview-box .overview-subtext {
  color: #6c757d;
  font-weight: 600;
  margin-top: 0.25rem;
  color: var(--grey-300);
}

/* related to box 1 colors */
.widget-overview-box.widget-overview-box-1 {
  border-left-color: #64b5f6;
}

.widget-overview-box.widget-overview-box-1 .overview-icon {
  background: #64b5f6;
  color: #1976d2;
}

.widget-overview-box.widget-overview-box-1 .overview-title {
  color: #64b5f6;
}

/* related to box 2 colors */
.widget-overview-box.widget-overview-box-2 {
  border-left-color: #7986cb;
}
.widget-overview-box.widget-overview-box-2 .overview-icon {
  background: #7986cb;
  color: #303f9f;
}

.widget-overview-box.widget-overview-box-2 .overview-title {
  color: #64b5f6;
}

.widget-overview-box.widget-overview-box-2 .overview-title {
  color: #7986cb;
}

/* related to box 3 colors */
.widget-overview-box.widget-overview-box-3 {
  border-left-color: #4db6ac;
}

.widget-overview-box.widget-overview-box-3 .overview-icon {
  background: #4db6ac;
  color: #00796b;
}

.widget-overview-box.widget-overview-box-3 .overview-title {
  color: #4db6ac;
}

/* related to box 4 colors */
.widget-overview-box.widget-overview-box-4 {
  border-left-color: #4dd0e1;
}

.widget-overview-box.widget-overview-box-4 .overview-icon {
  background: #4dd0e1;
  color: #0097a7;
}

.widget-overview-box.widget-overview-box-4 .overview-title {
  color: #4dd0e1;
}

/* Extra small devices (phones, 600px and down) */
/* @media only screen and (max-width: 600px) {} */
/* Small devices (portrait tablets and large phones, 600px and up) */
/* @media only screen and (min-width: 600px) {} */
/* Medium devices (landscape tablets, 768px and up) */
/* @media only screen and (min-width: 768px) {} */
/* Large devices (laptops/desktops, 992px and up) */
/* @media only screen and (min-width: 992px) {} */
/* Extra large devices (large laptops and desktops, 1200px and up) */
/* @media only screen and (max-width: 1200px) {} */

@media only screen and (max-width: 1850px) {
  /* .widget-overview-box .overview-button button.p-button-text >>> span.p-button-label{
    display:none;
  } */
}

/* menu */

.card .card-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
</style>
