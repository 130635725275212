<template>
  <Page
    leftIcon="pi pi-arrow-left"
    :title="title"
    @onIconClick="handlePreviousButton"
    @onTabClick="changeSelectedTab"
    :tabOptions="tabOptions"
  >
    <template v-slot:content v-if="!loading">
      <div class="clients-content">
        <TabView :activeIndex="activeIndex">
          <!-- list overview -->
          <TabPanel header="Mensagem">
            <ContactGroupListOverview
              v-if="activeIndex === 0"
              @onTitleChange="updateTitle"
            ></ContactGroupListOverview>
          </TabPanel>
          <!-- list contacts -->
          <TabPanel header="SMS enviados">
            <ContactGroupListContacts
              v-if="activeIndex === 1"
            ></ContactGroupListContacts>
          </TabPanel>
        </TabView>
      </div>
    </template>
  </Page>
</template>

<script>
import Page from "@/components/Page.vue";
import ContactGroupListOverview from "@/components/ContactGroupListOverview";
import ContactGroupListContacts from "@/components/ContactGroupListContacts";

// import ErrorState from "@/components/utils/ErrorState.vue";

// import { submitSMS } from "../services/messages.service.js";

import AuthApp from "../main";

export default {
  components: {
    Page,
    ContactGroupListOverview,
    ContactGroupListContacts,
  },
  setup() {},
  mounted() {
    if (
      this.$store.getters.contactGroupListSelected &&
      this.$store.getters.contactGroupListSelected.name
    ) {
      this.title = this.$store.getters.contactGroupListSelected.name;
    }
  },
  data() {
    return {
      auth: AuthApp,
      loading: false,
      activeIndex: 0,
      title: "",
      tabOptions: [
        { value: "Geral", selected: true, visible: true },
        { value: "Contactos", selected: false, visible: true },
      ],
    };
  },
  computed: {},
  validations() {},
  methods: {
    handlePreviousButton: function () {
      let params = this.$route.query;
      console.log("params", params);
      this.$router.push({ name: "contacts", query: { ...params } });
    },
    updateTitle: function (title) {
      this.title = title;
    },
    changeSelectedTab(e) {
      this.tabOptions.forEach((element) => {
        element.selected = false;
      });
      this.tabOptions[e].selected = true;
      this.activeIndex = e;
    },
  },
};
</script>

<style lang="scss" scoped></style>
