<template>
  <ErrorState
    v-if="!loading && error"
    @onErrorStateButtonClick="onErrorStateButtonClick()"
    message="Algo não correu bem"
    buttonText="Voltar a tentar"
  ></ErrorState>

  <div v-if="!error" class="flex align-items-stretch flex-wrap">
    <div class="flex align-items-start results">
      {{ totalRecords }} resultado(s)
    </div>
    <!-- <div
      class="flex-grow-1 flex align-items-center justify-content-center"
    ></div> -->
    <div class="inline-flex flex-auto"></div>
    <div class="inline-flex">
      <Button
        label="Adicionar contacto"
        v-if="auth.account_state == 'ENABLED'"
        class="p-button p-button-secondary"
        @click="(showDialog = true), (newContact = true), (object = contact)"
      />
    </div>
  </div>
  <Divider />

  <Dialog
    position="center"
    :visible="showDialog"
    :dismissableMask="true"
    :modal="true"
    style="width: 640px"
    :draggable="false"
    :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
    :style="{ width: '70vw' }"
    :key="componentKey"
  >
    <template #header>
      {{ newContact ? "Criar contacto" : "Actualizar contacto" }}
    </template>
    <div>
      <Message severity="warn">
        <div>
          Os atributos são referentes ao contacto, pelo que todas as listas onde
          o contacto existir, irão sofrer alterações.
        </div>
      </Message>
      <Card class="card">
        <template #content>
          <!-- MSISDN -->
          <div class="p-fluid p-formgrid grid card mb-3">
            <div class="col-2">
              <label for="name"
                >{{ $t("views.message.formLabels.phoneNumber") }}
              </label>
              <InputText
                class="mt-2"
                id="name"
                type="text"
                :placeholder="newContact ? 'ie: +351930000000' : object.msisdn"
                maxlength="30"
                :disabled="newContact ? false : true"
                v-model="object.msisdn"
                @input="searchContact()"
              />
            </div>
          </div>
          <div class="p-fluid p-formgrid grid card mb-3">
            <div
              style="
                display: flex;
                flex-flow: row wrap;
                justify-content: flex-start;
              "
            >
              <div v-for="(value, name, idx) in object.parameters" :key="idx">
                <div class="flex ml-2 mb-4">
                  <div>
                    <label class="customer-badge status-accepted" for="name">{{
                      "{VAR" + (idx + 1) + "}"
                    }}</label>
                    <InputText
                      class="mt-2"
                      id="name"
                      maxlength="30"
                      type="text"
                      placeholder="--"
                      v-model="object.parameters['v' + (idx + 1)]"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </Card>
    </div>
    <template #footer>
      <Button label="Fechar" @click="showDialog = false" />
      <Button
        label="Submeter"
        :disabled="
          newContact
            ? isValidPhoneNumber(object.msisdn)
              ? false
              : true
            : false
        "
        @click="newContact ? addListContact() : updateContact()"
      />
    </template>
  </Dialog>

  <GenericDialog
    :visible="showGenericDialog"
    title="Eliminar contacto da lista"
    description="Confirma que quer eliminar o contacto da lista?"
    @close="showGenericDialog = false"
    @confirm="removeListContact()"
  />

  <EmptyState
    v-if="!loading && !error && !items.length && !handledFilters?.length"
    @onEmptyStateButtonClick="$router.push({ name: 'contacts' })"
    message="Sem contactos"
    buttonText="Retroceder"
    :showButton="auth.account_state === 'ENABLED' ? true : false"
  ></EmptyState>

  <DataTable
    v-if="items.length || handledFilters?.length"
    :value="items"
    :paginator="false"
    :row-hover="true"
    @filter="onFilter($event)"
    filterDisplay="row"
    v-model:filters="filters"
    lazy
  >
    <template #empty>Não foram encontrados resultados</template>
    <Column field="msisdn" style="width: 17rem">
      <template #header>
        <i class="pi pi-tag mr-1"></i>
        {{ $t("views.message.formLabels.phoneNumber") }}
      </template>
      <template #filter="{ filterModel, filterCallback }">
        <InputText
          v-model="filterModel.value"
          type="text"
          @input="filterCallback()"
          class="p-column-filter"
          placeholder="filtrar por número"
          :disabled="loading"
        />
      </template>
    </Column>

    <Column class="singleLine">
      <template #header>
        <i class="pi pi-tags mr-1"></i>
        Variáveis
      </template>
      <template #body="slotProps">
        <InlineMessage
          v-if="
            !Object.values(slotProps.data.parameters).some((el) => el !== '')
          "
          :style="{
            border: 'solid #d9a300',
            borderWidth: '0 0 0 0px',
            color: '#6d5100',
          }"
          severity="warn"
          class="border-primary"
          :class="{ 'mt-1': idx }"
        >
          <div class="flex align-items-center">Sem atributos definidos</div>
        </InlineMessage>
        <div
          style="
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;
          "
        >
          <div
            v-for="(value, name, idx) in slotProps.data.parameters"
            :key="idx"
          >
            <InlineMessage
              v-if="value"
              :style="{
                border: 'solid #696cff',
                borderWidth: '0 0 0 0px',
                margin: '2px',
              }"
              severity="success"
              class="border-primary"
            >
              <div class="flex align-items-center">
                <div class="customer-badge status-accepted">
                  {VAR{{ idx + 1 }}}
                </div>
                <div class="ml-2">{{ value }}</div>
              </div>
            </InlineMessage>
          </div>
        </div>
      </template>
    </Column>
    <Column field="actions" class="text-right">
      <template #header>
        <!-- <i class="pi pi-cog"></i> -->
      </template>
      <template #body="slotProps">
        <Button
          icon="pi pi-user-edit"
          class="p-button-rounded p-button-secondary p-button-text"
          @click="
            (showDialog = true),
              (newContact = false),
              (object = JSON.parse(JSON.stringify(slotProps.data)))
          "
        />
        <Button
          icon="pi pi-trash"
          class="p-button-rounded p-button-secondary p-button-text"
          @click="(showGenericDialog = true), (selectedRow = slotProps.data)"
        />
      </template>
    </Column>
  </DataTable>
  <CustomPaginator
    :size="size"
    :totalRecords="Number(totalRecords)"
    :first="first"
    :rowsPerPageOptions="[10, 20, 30]"
    @pageChange="handlePageChange"
    v-if="items.length"
  />
</template>

<script>
import ErrorState from "@/components/utils/ErrorState.vue";
import CustomPaginator from "@/components/CustomPaginator.vue";
import GenericDialog from "@/components/utils/GenericDialog.vue";
import AuthApp from "../main";
import EmptyState from "@/components/utils/EmptyState.vue";
import { isValidPhoneNumber } from "libphonenumber-js";

import {
  addListContact,
  getListContacts,
  removeListContact,
  updateContact,
  getContact,
} from "@/services/contact.service";

export default {
  name: "messagelist",
  data: function () {
    return {
      auth: AuthApp,
      showFilters: false,
      loading: false,
      catalog: {},
      searchDate: null,
      searchSender: null,
      searchTitle: null,
      items: [],
      showDialog: false,
      showGenericDialog: false,
      first: 0,
      size: 10,
      page: 0,
      filters: {
        msisdn: { value: null, matchMode: "like" },
      },
      contact: {
        msisdn: "",
        parameters: {
          v1: "",
          v2: "",
          v3: "",
          v4: "",
          v5: "",
          v6: "",
          v7: "",
          v8: "",
          v9: "",
          v10: "",
        },
      },
    };
  },
  props: {
    showFiltersArea: Boolean,
  },
  components: {
    ErrorState,
    CustomPaginator,
    EmptyState,
    GenericDialog,
  },
  mounted() {
    let params = this.$route.query;
    if (params.prevpage) {
      this.page = parseInt(params.prevpage, 10);
    }
    if (params.prevsize) {
      this.size = parseInt(params.prevsize, 10);
    }
    if (params.prevfirst) {
      this.first = parseInt(params.prevfirst, 10);
    }

    //clean up just in case
    this.$router.replace({ query: null });

    this.getData();
  },
  computed: {},
  methods: {
    async getData() {
      this.error = false;
      this.loading = true;
      this.loader = this.$loading.show();

      let params = this.$route.params;
      let filters = this.handledFilters?.length
        ? JSON.stringify(this.handledFilters)
        : "";

      const [res, err] = await getListContacts(params.id, {
        filter: filters,
        page: this.page,
        size: this.size,
      });

      if (err) {
        this.error = true;
        this.$toast.add({
          severity: "error",
          summary: "Erro",
          detail: "Aconteceu um erro, por favor tente mais tarde!",
          life: 3000,
        });
      } else {
        this.items = res.items;
        this.totalRecords = res.totalItems;
      }

      this.loading = false;
      this.loader.hide();
    },
    async getContact() {
      this.error = false;
      this.loading = true;
      this.loader = this.$loading.show();

      const [res, err] = await getContact(this.object.msisdn);

      if (!err) {
        this.contact.parameters = { ...res.parameters };
      }

      this.loading = false;
      this.loader.hide();
    },

    onErrorStateButtonClick() {
      this.getData();
    },

    async removeListContact() {
      this.loading = true;
      this.loader = this.$loading.show();

      const [, err] = await removeListContact(
        this.$route.params.id,
        this.selectedRow.msisdn
      );
      if (err) {
        // this.empty = true;
        this.showGenericDialog = false;

        this.$toast.add({
          severity: "error",
          summary: "Erro",
          detail: "Aconteceu um erro, por favor tente mais tarde!",
          life: 3000,
        });

        this.loader.hide();
      } else {
        this.showGenericDialog = false;

        this.$toast.add({
          severity: "success",
          summary: "Sucesso",
          detail: "Contacto apagado com sucesso",
          life: 3000,
        });
        this.loader.hide();
        this.getData();
      }
      this.loading = false;
    },

    async updateContact() {
      // if (!(await this.checkForm())) return;

      this.error = false;
      this.loading = true;
      this.loader = this.$loading.show();
      const [, err] = await updateContact(
        this.object.msisdn,
        this.object.parameters
      );
      if (err) {
        this.error = true;
        this.$toast.add({
          severity: "error",
          summary: "Erro",
          detail: "Aconteceu um erro, por favor tente mais tarde!",
          life: 3000,
        });
        this.loader.hide();
      } else {
        this.$toast.add({
          severity: "success",
          summary: "Sucesso",
          detail: "Contacto atualizado com sucesso",
          life: 3000,
        });
        this.showDialog = false;
        this.loader.hide();

        // this.v$.$reset();
        this.getData();
      }

      // this.loader.hide();
      this.loading = false;
    },

    async addListContact() {
      this.error = false;
      this.loading = true;

      this.loader = this.$loading.show();
      const [, err] = await addListContact(this.$route.params.id, this.contact);
      if (err) {
        this.error = true;
        this.$toast.add({
          severity: "error",
          summary: "Erro",
          detail: "Aconteceu um erro, por favor tente mais tarde!",
          life: 3000,
        });
        this.loader.hide();
      } else {
        this.$toast.add({
          severity: "success",
          summary: "Sucesso",
          detail: "Contacto criado com sucesso",
          life: 3000,
        });
        this.showDialog = false;
        this.loader.hide();

        //reset object
        this.contact.msisdn = "";
        this.contact.parameters = Object.keys(this.contact.parameters).reduce(
          (acc, curr) => ({ ...acc, [curr]: "" }),
          {}
        );

        this.getData();
      }

      // this.loader.hide();
      this.loading = false;
    },

    onFilter() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.handledFilters = Object.keys(this.filters)
          .map((key) => {
            return {
              property: key,
              operator: this.filters[key].matchMode,
              value:
                typeof this.filters[key].value === "object"
                  ? this.filters[key].value?.code
                  : this.filters[key].value,
            };
          })
          .filter((obj) => obj.value);

        //reset paging
        this.page = 0;
        this.first = 0;
        this.getData();
      }, 800);
    },
    searchContact() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        if (isValidPhoneNumber(this.object.msisdn)) this.getContact();
      }, 500);
    },
    handlePageChange(e) {
      this.page = e.page;
      this.size = e.rows;
      this.first = e.first;

      this.getData();
    },
    isValidPhoneNumber(e) {
      return isValidPhoneNumber(e);
    },
  },
};
</script>

<style lang="scss">
.p-tabview-panels {
  padding-top: 0px !important;
}

.p-dropdown .p-dropdown-label {
  padding: 0.55rem 0.6rem;
}

.p-inline-message-icon {
  display: none !important;
}

.p-column-filter-row .p-column-filter-menu-button {
  display: none !important;
}
</style>
