import http from "../shared/services/http";

export const submitCampaign = async (data) => {
  try {
    const payload = {
      recipients: {
        lists: data.body.recipients.map(function (item) {
          return item.listId;
        }),
      },
      sender: data.body.sender,
      dlr: data.body.deliveryReport.code,
      ttl: data.body.messageValidity.code,
      title: data.body.title,
      scheduleStart: data.body.scheduleStart,
      ...(data.body.template && { templateId: data.body.template.templateId }),
      ...(!data.body.template && { body: data.body.body }),
      ...(data.body.smartText && { smartText: true }),
      ...(data.body.smartEncode && { smartEncode: true }),
      ...(data.body.type.code == "mo" && {
        moNumber: data.body.longNumber,
        moValidity: data.body.moValidity,
      }),
    };

    const result = await http.post(`/communication/v1/campaign`, payload);
    return [result, null];
  } catch (err) {
    return [null, err];
  }
};

export const getCampaigns = async (data) => {
  try {
    const result = await http.get(`/communication/v1/campaign`, data);
    return [result, null];
  } catch (err) {
    return [null, err];
  }
};

export const getCampaign = async (id) => {
  try {
    const result = await http.get(`/communication/v1/campaign/${id}`);
    return [result, null];
  } catch (err) {
    return [null, err];
  }
};

export const removeCampaign = async (id) => {
  try {
    const result = await http.delete(`/communication/v1/campaign/${id}`);
    return [result, null];
  } catch (err) {
    return [null, err];
  }
};
