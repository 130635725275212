<template>
  <div class="right-side">
    <Header
      :title="title"
      :leftIcon="leftIcon"
      @onIconClick="onIconClick"
      @onTabClick="onTabClick"
      :tabItems="tabOptions"
      :roundBorderIcon="roundBorderIcon"
    >
      <slot name="header"></slot>
    </Header>
    <div class="page-content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";

export default {
  name: "Page",
  props: {
    isTabPage: Boolean,
    title: String,
    leftIcon: String,
    tabOptions: Object,
    roundBorderIcon: Boolean,
  },
  components: {
    Header,
  },
  methods: {
    onIconClick(e) {
      this.$emit("onIconClick", e);
    },
    onTabClick(e) {
      this.$emit("onTabClick", e);
    },
  },
};
</script>

<style scoped>
.right-side {
  width: 100%;
  margin: 20px;
  /* backgroud-color: hsl(240, 7%, 97%); */
}

.page-content {
  text-align: left;
  /* color: hsl(210, 16%, 53%); */
  position: relative;
  /* padding: 30px; */
  max-height: calc(100vh - 144px);
  overflow: scroll;
  padding-top: 30px;
  /* background-color:red; */
}

.page-content .p-tabmenu {
  /* background-color: hsl(240, 7%, 97%); */
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
