<template>
  <ContactGroupListSelection
    :visible="showDialog"
    :key="NaN"
    :lists="campaign.recipients"
    :showDialog="showDialog"
    @onClose="showDialog = false"
    @onSave="(lists) => saveLists(lists)"
  >
  </ContactGroupListSelection>

  <TemplateGroupListSelection
    :visible="showTemplateDialog"
    :key="NaN"
    :template="campaign.template"
    :showDialog="showTemplateDialog"
    @onClose="showTemplateDialog = false"
    @onSave="(template) => saveTemplate(template)"
  >
  </TemplateGroupListSelection>

  <Page
    leftIcon="pi-times"
    :title="$t('views.campaign.headerTitle')"
    @onIconClick="this.$router.push({ name: 'home' })"
  >
    <template v-slot:header v-if="!loading && !error">
      <div class="flex p-justify-end button-wrapper">
        <Button
          class="icon p-button-secondary"
          icon="pi pi-arrow-left"
          iconPos="left"
          v-if="currentStep !== 0"
          @click="currentStep = 0"
          label="Anterior"
        >
        </Button>

        <Button
          label="Enviar"
          iconPos="right"
          @click="sendCampaign()"
          v-if="currentStep !== 0 && edited"
        />
        <Button
          label="Seguinte"
          icon="pi pi-arrow-right"
          iconPos="right"
          class="icon"
          v-if="currentStep === 0"
          @click="checkForm()"
        />
      </div>
    </template>
    <template v-slot:content v-if="!loading">
      <ErrorState
        v-if="error"
        @onErrorStateButtonClick="getSender()"
        message="Algo não correu bem"
        buttonText="Voltar a tentar"
      ></ErrorState>
      <div v-else class="clients-content end-nav">
        <div class="step-wrapper">
          <Steps :currentStep="currentStep" :items="items" />
        </div>
        <!-- campaign creation -->
        <form @submit="checkForm" v-if="currentStep === 0">
          <div class="card-title-label">
            {{ $t("views.campaign.formLabels.general") }}
          </div>
          <!-- campaign data -->
          <Card class="card">
            <template #content v-if="!loading">
              <div class="p-fluid p-formgrid grid">
                <div class="col-5 md:col-5 lg:col-4"></div>
              </div>
              <div class="p-fluid p-formgrid grid">
                <!-- name -->
                <div class="col-4">
                  <FloatLabel>
                    <label for="name"
                      >{{ $t("views.campaign.formLabels.campaignName") }}
                    </label>
                    <InputText
                      class="mt-2"
                      id="name"
                      type="text"
                      v-model="campaign.title"
                      maxlength="30"
                    />
                  </FloatLabel>
                  <small
                    v-if="v$.campaign?.title?.$error"
                    id="name-help"
                    class="p-error"
                  >
                    <img
                      class="sucess-image"
                      src="/resources/icons/error_label.svg"
                    />{{ v$.campaign.title?.$errors[0]?.$message }}</small
                  >
                </div>
              </div>
              <div class="p-fluid p-formgrid grid mt-4">
                <!-- sender -->
                <div class="col-8 md:col-6 lg:col-4">
                  <FloatLabel>
                    <label for="name"
                      >{{ $t("views.campaign.formLabels.sender") }}
                    </label>
                    <Dropdown
                      class="mt-2 flex"
                      v-model="campaign.sender"
                      :options="campaignSenders"
                      optionValue="name"
                      optionLabel="name"
                    />
                  </FloatLabel>
                  <!-- {{ msgGetSenderName }} -->
                  <small
                    v-if="v$.campaign?.sender?.$error"
                    id="name-help"
                    class="p-error"
                  >
                    <img
                      class="sucess-image"
                      src="/resources/icons/error_label.svg"
                    />{{ v$.campaign.sender?.$errors[0]?.$message }}</small
                  >
                </div>
                <!-- recipients -->
                <div class="col-4 md:col-4 lg:col-3">
                  <FloatLabel>
                    <label for="name"
                      >{{ $t("views.campaign.formLabels.recipients") }}
                    </label>
                    <Button
                      @click="showDialog = true"
                      class="add-button flex"
                      icon="pi pi-plus"
                      iconPos="left"
                      label="Adicionar lista de contatos"
                      id="account-add-sender"
                    ></Button>
                  </FloatLabel>
                  <small
                    v-if="v$.campaign?.recipients?.$error"
                    id="name-help"
                    class="p-error"
                  >
                    <img
                      class="sucess-image"
                      src="/resources/icons/error_label.svg"
                    />{{ v$.campaign.recipients?.$errors[0]?.$message }}</small
                  >
                </div>
                <!-- recipients counters -->
                <div
                  class="field col-12 md:col-3 senders-link"
                  @click="showDialog = true"
                >
                  <a v-if="campaign.recipients && campaign.recipients.length"
                    >{{ campaign.recipients.length }} lista(s) adicionada(s)</a
                  >
                </div>
              </div>
              <!-- templates -->
              <div class="p-fluid p-formgrid grid mt-4">
                <div class="col-3">
                  <FloatLabel>
                    <label for="name"
                      >{{ $t("views.campaign.formLabels.template") }}
                    </label>
                    <Button
                      @click="showTemplateDialog = true"
                      class="add-button mt-2 flex"
                      icon="pi pi-plus"
                      iconPos="left"
                      label="Adicionar template"
                      id="account-add-template"
                    ></Button>
                  </FloatLabel>
                  <small
                    v-if="v$.campaign?.template?.$error"
                    id="name-help"
                    class="p-error"
                  >
                    <img
                      class="sucess-image"
                      src="/resources/icons/error_label.svg"
                    />{{ v$.campaign.template?.$errors[0]?.$message }}</small
                  >
                </div>
                <!-- recipients counters -->
                <div
                  class="field col-12 md:col-3 senders-link"
                  @click="showTemplateDialog = true"
                >
                  <a v-if="campaign.template"
                    >Template&nbsp;<b>{{ campaign.template.title }}</b
                    >&nbsp;adicionado</a
                  >
                </div>
              </div>
              <!-- text -->
              <div class="p-fluid p-formgrid grid mt-4">
                <div class="col-8">
                  <FloatLabel>
                    <label for="name"
                      >{{ $t("views.campaign.formLabels.text") }}
                    </label>
                    <TextArea
                      class="mt-2"
                      id="name"
                      type="text"
                      v-model="campaign.body"
                      :disabled="campaign.template ? true : false"
                      placeholder="--"
                      rows="10"
                      :autoResize="true"
                      :maxlength="msgCountMaxLength"
                    />
                    <label class="label-cc">{{ msgCounter }}</label>
                  </FloatLabel>
                  <small
                    v-if="v$.campaign?.body?.$error"
                    id="name-help"
                    class="p-error"
                  >
                    <img
                      class="sucess-image"
                      src="/resources/icons/error_label.svg"
                    />{{ v$.campaign.body?.$errors[0]?.$message }}</small
                  >
                </div>
              </div>
              <div
                class="p-fluid p-formgrid grid card mb-5"
                v-if="msgSpecialChars || campaign.template"
              >
                <div class="col-6 md:col-10 lg:col-10" style="">
                  <div style="padding: 0.5rem">
                    <!-- border: 1px solid var(--grey-100);
                      border-radius: 10px; -->
                    <div class="ml-2 mb-1">Codificação</div>
                    <div class="ml-2 mb-3 text-500">
                      <span v-if="msgSpecialChars"
                        >Foram detectados caracteres especiais na sua
                        mensagem.</span
                      >
                      Deverá escolher a opção que deseja, sabendo que poderá ter
                      afetação nos consumos e no resultado final da sms.
                    </div>
                    <div class="flex align-items-center mb-2">
                      <RadioButton
                        v-model="campaign.encodingOption"
                        inputId="smartText"
                        name="smartText"
                        value="smartText"
                      />
                      <label class="ml-3 text-500"
                        >Substituir caracteres especiais por caracteres
                        equivalentes usando o formato GSM-7.<br />
                        <b>ie: á será substítuido por a</b>.</label
                      >
                    </div>
                    <Divider />
                    <div class="flex align-items-center">
                      <RadioButton
                        v-model="campaign.encodingOption"
                        inputId="smartEncode"
                        name="smartEncode"
                        value="smartEncode"
                      />
                      <label class="ml-3 text-500"
                        >Permitir o envio de caracteres especiais fora do
                        formato GSM-7 usando o formato UNICODE. Terá impacto
                        directo no consumo de sms onde por exemplo uma sms,
                        passará a 70 caracteres.</label
                      >
                    </div>
                  </div>
                </div>
              </div>
              <!-- dlr | ttl | schedule -->
              <div class="p-fluid p-formgrid grid mt-4">
                <div class="col-2">
                  <FloatLabel>
                    <label for="name"
                      >{{ $t("views.campaign.formLabels.deliveryReport") }}
                    </label>
                    <Dropdown
                      v-model="campaign.deliveryReport"
                      :options="deliveryReports"
                      optionLabel="name"
                      placeholder="--"
                      @change="changeMessage"
                    />
                  </FloatLabel>
                </div>
                <div class="col-2">
                  <FloatLabel>
                    <label for="name"
                      >{{ $t("views.campaign.formLabels.messageValidity") }}
                    </label>
                    <Dropdown
                      v-model="campaign.messageValidity"
                      :options="messageValidities"
                      optionLabel="name"
                      :option="0"
                      @change="setMessage"
                    />
                  </FloatLabel>
                </div>
                <div class="col-4">
                  <FloatLabel>
                    <label for="name"
                      >{{ $t("views.campaign.formLabels.schedule") }}
                    </label>
                    <Calendar
                      id="calendar-12h"
                      v-model="campaign.scheduleStart"
                      :minDate="minDate"
                      :maxDate="maxDate"
                      placeholder="--"
                      :manualInput="false"
                      showTime
                      hourFormat="24"
                      @date-select="
                        campaign.moValidity =
                          campaign.scheduleStart > campaign.moValidity
                            ? undefined
                            : campaign.moValidity
                      "
                    />
                  </FloatLabel>
                </div>
              </div>
              <!-- campaigns with longnumber related -->
              <div
                class="p-fluid p-formgrid grid mt-4"
                v-if="longNumbers.length"
              >
                <div class="col-2">
                  <FloatLabel>
                    <label for="name"
                      >{{ $t("views.campaign.formLabels.campaignType") }}
                    </label>
                    <Dropdown
                      v-model="campaign.type"
                      :options="campaignTypes"
                      optionLabel="name"
                      placeholder="--"
                      @change="
                        campaign.longNumber =
                          campaign.type.code == 'mo' ? longNumbers[0].name : ''
                      "
                    />
                  </FloatLabel>
                </div>
                <div class="col-4" v-if="campaign.type.code == 'mo'">
                  <FloatLabel>
                    <label for="name"
                      >{{ $t("views.campaign.formLabels.moValidity") }}
                    </label>
                    <Calendar
                      id="calendar-12h"
                      v-model="campaign.moValidity"
                      :minDate="campaign.scheduleStart || minDate"
                      :maxDate="
                        campaign.scheduleStart
                          ? getMaxDateFrom(campaign.scheduleStart)
                          : maxDate
                      "
                      placeholder="--"
                      :manualInput="false"
                      showTime
                      hourFormat="24"
                    />
                  </FloatLabel>
                  <small
                    v-if="v$.campaign?.moValidity?.$error"
                    id="name-help"
                    class="p-error"
                  >
                    <!-- {{ v$.campaign.moValidity }} -->
                    <img
                      class="sucess-image"
                      src="/resources/icons/error_label.svg"
                    />{{ v$.campaign.moValidity?.$errors[0]?.$message }}</small
                  >
                </div>
              </div>
              <div
                class="p-fluid p-formgrid grid"
                v-if="
                  longNumbers.length &&
                  campaign.type.code == 'mo' &&
                  campaign.moValidity != undefined
                "
              >
                <Message severity="warn">
                  <div>
                    Serão aceites respostas para o número
                    {{ longNumbers[0].name }} até à data
                    {{ campaign.moValidity }}
                  </div>
                </Message>
              </div>
              <div class="flex p-justify-end button-wrapper">
                <Button
                  class="icon p-button-secondary"
                  icon="pi pi-arrow-left"
                  iconPos="left"
                  v-if="currentStep !== 0"
                  @click="currentStep = 0"
                  label="Anterior"
                >
                </Button>

                <Button
                  label="Enviar"
                  iconPos="right"
                  @click="sendCampaign()"
                  v-if="currentStep !== 0 && edited"
                />
                <Button
                  label="Seguinte"
                  icon="pi pi-arrow-right"
                  iconPos="right"
                  class="icon"
                  v-if="currentStep === 0"
                  @click="checkForm()"
                />
              </div>
            </template>
          </Card>
        </form>
        <!-- resume of the campaign -->
        <form @submit="submitMessage" v-if="currentStep === 1">
          <div class="card-title-label">&nbsp;</div>
          <!-- message data -->
          <Card class="card">
            <template #content v-if="!loading">
              <h4>{{ $t("views.campaign.formLabels.general") }}</h4>

              <div class="p-fluid p-formgrid grid card">
                <!-- Name -->
                <div class="p-field col-12 sm-12 md-3">
                  <label for="name"
                    >{{ $t("views.campaign.formLabels.campaignName") }}
                  </label>
                  <p>{{ campaign.title }}</p>
                </div>
              </div>
              <div class="p-fluid p-formgrid grid card">
                <!-- Sender -->
                <div class="p-field col-3 sm-12 md-3">
                  <label for="name"
                    >{{ $t("views.campaign.formLabels.sender") }}
                  </label>
                  <p>
                    {{ campaign.sender }}
                  </p>
                </div>
                <!-- recipients -->
                <div class="p-field col-4 sm-12 md-3">
                  <label for="name"
                    >{{ $t("views.campaign.formLabels.recipients") }}
                  </label>
                  <p
                    v-for="item in campaign.recipients"
                    :key="item.listId"
                    style="cursor: pointer"
                  >
                    {{ item.name }}
                  </p>
                </div>
              </div>
              <div
                class="p-fluid p-formgrid grid card"
                v-if="campaign.template"
              >
                <!-- template -->
                <div class="p-field col-4 sm-12 md-3">
                  <label for="name"
                    >{{ $t("views.campaign.formLabels.template") }}
                  </label>
                  <p>
                    {{ campaign.template.title }}
                  </p>
                </div>
              </div>
              <Divider />
              <!-- Message -->
              <h4>{{ $t("views.message.formLabels.message") }}</h4>

              <div class="p-fluid p-formgrid grid card">
                <!-- Name -->
                <div class="p-field col-10 sm-8 md-6 overflow-scroll">
                  <p>{{ campaign.body }}</p>
                </div>
              </div>
              <div
                class="p-fluid p-formgrid grid card mb-5"
                v-if="msgSpecialChars || campaign.template"
              >
                <div class="col-8" style="">
                  <div style="padding: 0.5rem">
                    <div class="ml-2 mb-1">Codificação</div>
                    <div class="ml-2 mb-3 text-500">
                      Foram detectados caracteres especiais na sua mensagem.
                      Deverá escolher a opção que deseja, sabendo que poderá ter
                      afetação nos consumos e no resultado final da sms.
                    </div>
                    <div class="flex align-items-center mb-2">
                      <RadioButton
                        disabled
                        v-model="campaign.encodingOption"
                        inputId="smartText"
                        name="smartText"
                        value="smartText"
                      />
                      <label class="ml-3 text-500"
                        >Substituir caracteres especiais por caracteres
                        equivalentes utilizando igualmente a codificação
                        GSM-7.<br />
                        <b>ie: á será substítuido por a</b>.</label
                      >
                    </div>
                    <Divider />
                    <div class="flex align-items-center">
                      <RadioButton
                        disabled
                        v-model="campaign.encodingOption"
                        inputId="smartEncode"
                        name="smartEncode"
                        value="smartEncode"
                      />
                      <label class="ml-3 text-500"
                        >Permitir o envio de caracteres especiais fora do
                        formato GSM-7 suportando UNICODE. Terá impacto directo
                        no consumo de sms's onde uma sms, passará a 70
                        caracteres.</label
                      >
                    </div>
                  </div>
                </div>
              </div>

              <!-- Message Options -->
              <Divider />
              <h4>{{ $t("views.message.formLabels.typology") }}</h4>
              <div class="p-fluid p-formgrid grid card">
                <!-- Sender -->
                <div class="p-field col-2 sm-4 md-3">
                  <label for="name"
                    >{{ $t("views.message.formLabels.deliveryReport") }}
                  </label>
                  <p>
                    {{
                      campaign.deliveryReport
                        ? campaign.deliveryReport.name
                        : "não definido"
                    }}
                  </p>
                </div>
                <!-- recipients -->
                <div class="p-field col-2 sm-4 md-3">
                  <label for="name"
                    >{{ $t("views.message.formLabels.messageValidity") }}
                  </label>
                  <p>
                    {{
                      campaign.messageValidity
                        ? campaign.messageValidity.name
                        : "não definido"
                    }}
                  </p>
                </div>
                <div class="p-field col-2 sm-4 md-3">
                  <label for="name"
                    >{{ $t("views.campaign.formLabels.schedule") }}
                  </label>
                  <p>
                    {{ campaign.scheduleStart ? campaign.scheduleStart : "--" }}
                  </p>
                </div>
              </div>
              <div class="p-fluid p-formgrid grid card">
                <!-- Campaign Type -->
                <div class="p-field col-2 sm-4 md-3">
                  <label for="name"
                    >{{ $t("views.message.formLabels.campaignType") }}
                  </label>
                  <p>
                    {{ campaign.type.name }}
                  </p>
                </div>
                <div
                  class="p-field col-2 sm-4 md-3"
                  v-if="campaign.type.code == 'mo'"
                >
                  <label for="name"
                    >{{ $t("views.campaign.formLabels.moValidity") }}
                  </label>
                  <p>
                    {{ campaign.moValidity }}
                  </p>
                </div>
              </div>

              <div class="flex p-justify-end button-wrapper">
                <Button
                  class="icon p-button-secondary"
                  icon="pi pi-arrow-left"
                  iconPos="left"
                  v-if="currentStep !== 0"
                  @click="currentStep = 0"
                  label="Anterior"
                >
                </Button>

                <Button
                  label="Enviar"
                  iconPos="right"
                  @click="sendCampaign()"
                  v-if="currentStep !== 0 && edited"
                />
                <Button
                  label="Seguinte"
                  icon="pi pi-arrow-right"
                  iconPos="right"
                  class="icon"
                  v-if="currentStep === 0"
                  @click="checkForm()"
                />
              </div>
            </template>
          </Card>
        </form>
      </div>
    </template>
  </Page>
</template>

<script>
import Page from "@/components/Page.vue";
import useVuelidate from "@vuelidate/core";
import {
  required,
  requiredIf,
  macthes,
  validateEncodingLenght,
} from "@/shared/customValidators.js";
import ErrorState from "@/components/utils/ErrorState.vue";
import { getnrMsgs } from "@/utils/maxCalc.js";
import ContactGroupListSelection from "@/components/ContactGroupListSelection.vue";
import TemplateGroupListSelection from "@/components/TemplateGroupListSelection.vue";
import { getSender, getLongNumber } from "../services/account.service.js";
import { submitCampaign } from "../services/campaign.service.js";
import { SegmentedMessage } from "sms-segments-calculator";

import AuthApp from "../main";

const regexCampaignTitle = /^.{3,30}/gm;

export default {
  components: {
    Page,
    // AddGenericOptionDialog,
    ErrorState,
    ContactGroupListSelection,
    TemplateGroupListSelection,
  },
  created() {
    let today = new Date();
    let month = today.getMonth();
    let year = today.getFullYear();
    // let prevMonth = month === 0 ? 11 : month - 1;
    // let prevYear = prevMonth === 11 ? year - 1 : year;
    let nextMonth = month === 11 ? 0 : month + 1;
    let nextYear = nextMonth === 0 ? year + 1 : year;

    this.minDate = new Date();
    this.minDate.setMonth(month);
    this.minDate.setFullYear(year);
    this.maxDate = new Date();
    this.maxDate.setMonth(nextMonth);
    this.maxDate.setFullYear(nextYear);
  },
  setup() {
    return { v$: useVuelidate() };
  },
  mounted() {
    this.getSender();
    this.getLongNumber();
  },
  data() {
    return {
      auth: AuthApp,
      showDialog: false,
      showTemplateDialog: false,
      loading: false,
      error: false,
      optionMasks: [],
      campaign: {
        title: "",
        body: "",
        recipients: [],
        deliveryReport: { name: "Nenhum", code: "NONE" },
        messageValidity: { name: "4h", code: "FOUR_HOURS" },
        subaccount: 1,
        encodingOption: "smartText",
        type: { name: "Simples", code: "NONE" },
      },
      currentStep: 0,
      deliveryReports: [
        { name: "Nenhum", code: "NONE" },
        { name: "Todos", code: "ALL" },
        { name: "Só entregues", code: "DELIVERED" },
        { name: "Não entregues", code: "NON_DELIVERED" },
      ],
      messageValidities: [
        { name: "4h", code: "FOUR_HOURS" },
        { name: "8h", code: "EIGHT_HOURS" },
        { name: "24h", code: "DAY" },
        { name: "48h", code: "TWO_DAYS" },
      ],
      campaignTypes: [
        { name: "Simples", code: "NONE" },
        { name: "C/resposta", code: "mo" },
      ],
      items: [
        {
          label: "Dados",
        },
        {
          label: "Resumo",
        },
      ],
      campaignSenders: [],
      longNumbers: [],
      showRecipients: false,
      canEditRecipients: false,
      maxRecipients: 10,
      filters: [],
      first: 0,
      size: 10,
      page: 0,
    };
  },
  computed: {
    msgCounter() {
      if (this.campaign.encodingOption === "smartEncode") {
        var sms = new SegmentedMessage(this.campaign.body);

        var maxSms =
          sms.getNonGsmCharacters().length &&
          this.campaign.encodingOption === "smartEncode"
            ? 335
            : 765;

        return (
          sms.numberOfCharacters +
          "/" +
          maxSms +
          ", " +
          sms.segmentsCount +
          " SMS"
        );
      } else {
        var obj = getnrMsgs(this.campaign.body);
        return obj.chars + "/765, " + obj.nrMsgs + " SMS";
      }
    },
    msgSpecialChars() {
      var sms = new SegmentedMessage(this.campaign.body || "");

      var vars = (this.campaign.body || "").match(/{VAR([1-9]|10)}/);
      var len = sms.getNonGsmCharacters().length + (vars ? 1 : 0);
      if (!len) this.resetEncodingOptions();

      return len ? true : false;
    },

    msgCountMaxLength() {
      var sms = new SegmentedMessage(this.campaign.body || "");
      var maxSms =
        sms.getNonGsmCharacters().length &&
        this.campaign.encodingOption === "smartEncode"
          ? 335
          : 765;

      return (
        maxSms -
        (sms.numberOfCharacters -
          (this.campaign.body ? this.campaign.body.length : 0))
      );
    },
  },
  validations() {
    return {
      campaign: {
        title: {
          required: required,
          regex: macthes(regexCampaignTitle),
        },
        sender: {
          required: required,
        },
        body: {
          required: required,
          length: validateEncodingLenght(),
        },
        recipients: {
          required: required,
        },
        encodingOption: {},
        moValidity: {
          required: requiredIf(
            this.campaign.type.code == "mo" &&
              this.campaign.moValidity == undefined
          ),

          // function () {
          //   helpers.withMessage(
          //     "Tamanho inválido para o formato escolhido",
          //     (value, vm) => {
          //       return false;
          //     }
          //   );
          // },
        },
      },
    };
  },
  methods: {
    resetEncodingOptions: function () {
      this.campaign.encodingOption = "smartText";
    },
    async sendCampaign() {
      this.loader = this.$loading.show();

      const [, err] = await submitCampaign({
        body: {
          ...this.campaign,
          ...{ [this.campaign.encodingOption]: true },
        },
      });

      if (err) {
        this.loader.hide();
        this.$toast.add({
          severity: "error",
          summary: "Erro",
          detail: this.$te("views.campaign.errors." + [err.response.detail])
            ? this.$t("views.campaign.errors." + [err.response.detail])
            : this.$t("views.campaign.errors." + err.response["error-code"]),
          life: 3000,
        });
      } else {
        this.$router.push({ name: "home" });
        this.$toast.add({
          severity: "success",
          summary: "Sucesso",
          detail: "Campanha enviada com sucesso",
          life: 3000,
        });
        this.loader.hide();
      }
    },
    async getSender() {
      this.loader = this.$loading.show();

      const [res, err] = await getSender();

      if (err) {
        this.loader.hide();
        this.error = true;
      } else {
        this.campaignSenders = res.items;
        this.error = false;
        this.loader.hide();
      }
      this.loading = false;
    },
    async getLongNumber() {
      this.loading = true;
      // this.loader = this.$loading.show();

      const [res, err] = await getLongNumber();

      if (err) {
        this.$toast.add({
          severity: "error",
          summary: "Erro",
          detail: "Aconteceu um erro, por favor tente mais tarde!",
          life: 3000,
        });
      } else {
        this.longNumbers = res.items;
      }
      // this.loading = false;
      // this.loader.hide();
    },
    getMaxDateFrom(date) {
      var d = new Date(date);
      d.setDate(d.getDate() + 30);
      return d;
    },
    helperCalendarCheck() {
      alert("em");
      console.log("cenas");
    },
    saveLists: function (ev) {
      this.campaign.recipients = ev;
      this.showDialog = false;
    },
    saveTemplate: function (ev) {
      if (!ev) {
        this.campaign.template = null;
        this.campaign.body = null;
      } else {
        this.campaign.template = ev;
        this.campaign.body = ev.body;
      }
      this.showTemplateDialog = false;
    },
    async checkForm() {
      await this.v$.$validate();
      if (!this.v$.$errors.length) {
        this.currentStep = 1;
        this.edited = true;
      }
    },
  },
};
</script>

<style scoped>
h1 {
  font-weight: 400;
}

.title-wrapper {
  display: flex;
  align-items: center;
}

.title-wrapper i {
  font-size: 40px;
}

.divider:before {
  border-left: 1px solid black !important;
}
.card {
  min-height: 100px !important;
}
.clients-content {
  text-align: left;
  color: hsl(210, 16%, 53%);
  position: relative;
}

.info-text {
  font-weight: bold;
  color: var(--grey-900);
  font-size: 14px;
}
.info-wrapper {
  display: flex;
  align-items: center;
  font-weight: 300;
  gap: 10px;
}

.info-wrapper i {
  color: var(--blue);
}
.info-href {
  font-size: 14px;
  color: var(--grey-900);
  border-bottom: 2px solid var(--lime);
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.p-tabview-nav {
  background-color: black;
}

.step-wrapper {
  width: 250px;
  float: right;
  position: relative;
  bottom: 30px;
}

.button-wrapper {
  gap: 30px;
}

.user-buttons {
  margin-top: 20px;
  padding: 10px;
  gap: 20px;
}

.user-buttons button {
  padding: 5px;
}

.senders-link {
  display: flex;
  margin-top: 25px;
  align-items: center;
}

.senders-link a {
  display: flex;
  align-items: center;
  text-decoration: underline;
  text-decoration-color: var(--lime);
  text-underline-offset: 5px;
  cursor: pointer;
}

.edited .senders-link {
  margin-top: 0;
}

.add-button {
  font-family: Azo Sans !important;
  font-weight: 400;
  font-size: 16px;
  color: #5b5c64;
  background: white;
  border-radius: 0;
  height: auto;
  width: 250px;
  text-align: left;
  padding: 2px !important;
  box-shadow: none;
  border: 1px solid var(--grey-100);
}

.edited {
  border-left: 3px solid blue;
  height: fit-content;
}

.label-cc {
  float: right;
  top: -25px;
  right: 5px;
  position: relative;
  font-size: 14px;
}

:deep .p-dropdown .p-inputtext {
  border: none;
}
</style>
