<template>
  <Page
    leftIcon="pi-times"
    :title="$t('views.message.headerTitle')"
    @onIconClick="this.$router.push({ name: 'home' })"
  >
    <template v-slot:header v-if="!loading && !error">
      <div class="flex p-justify-end button-wrapper">
        <Button
          class="icon p-button-secondary"
          icon="pi pi-arrow-left"
          iconPos="left"
          v-if="currentStep !== 0"
          @click="currentStep = 0"
          label="Anterior"
        >
        </Button>

        <Button
          label="Enviar"
          iconPos="right"
          @click="sendSMS()"
          v-if="currentStep !== 0 && edited"
        />
        <Button
          label="Seguinte"
          icon="pi pi-arrow-right"
          iconPos="right"
          class="icon"
          v-if="currentStep === 0"
          @click="checkForm()"
        />
      </div>
    </template>
    <template v-slot:content v-if="!loading">
      <AddGenericOptionDialog
        :optionValues="message.recipients"
        :optionMasks="optionMasks"
        :maxOptions="maxRecipients"
        :visible="showRecipients"
        :readOnly="!canEditRecipients"
        :description="`Indique os números para recepção (max: ${maxRecipients})`"
        title="Destinatários"
        inputType="number"
        regexValidation="^\d{9,12}$"
        @close="showRecipientsModal(false, true)"
        @save="(recipients) => saveRecipients(recipients)"
      />
      <ErrorState
        v-if="error"
        @onErrorStateButtonClick="getSender()"
        message="Algo não correu bem"
        buttonText="Voltar a tentar"
      ></ErrorState>
      <div v-else class="clients-content end-nav">
        <div class="step-wrapper">
          <Steps :currentStep="currentStep" :items="items" />
        </div>
        <!-- message creation -->
        <form @submit="checkForm" v-if="currentStep === 0">
          <div class="card-title-label">
            {{ $t("views.message.formLabels.general") }}
          </div>
          <!-- message data -->
          <Card class="card">
            <template #content v-if="!loading">
              <div class="p-fluid p-formgrid grid">
                <div class="col-5 md:col-5 lg:col-4"></div>
              </div>
              <div class="p-fluid p-formgrid grid">
                <!-- Name -->
                <div class="col-4 md:col-4 lg:col-3">
                  <FloatLabel>
                    <label for="name"
                      >{{ $t("views.message.formLabels.messageName") }}
                    </label>
                    <InputText
                      class=""
                      id="name"
                      type="text"
                      v-model="message.title"
                    />
                  </FloatLabel>
                  <small
                    v-if="v$.message?.title?.$error"
                    id="name-help"
                    class="p-error"
                  >
                    <img
                      class="sucess-image"
                      src="/resources/icons/error_label.svg"
                    />{{ v$.message.title?.$errors[0]?.$message }}</small
                  >
                </div>
              </div>
              <div class="p-fluid p-formgrid grid mt-4">
                <!-- Sender -->
                <div class="col-4 md:col-4 lg:col-3">
                  <FloatLabel>
                    <label for="name"
                      >{{ $t("views.message.formLabels.sender") }}
                    </label>
                    <Dropdown
                      class="mt-2 flex"
                      v-model="message.sender"
                      :options="messageSenders"
                      optionValue="name"
                      optionLabel="name"
                    />
                  </FloatLabel>
                  <!-- {{ msgGetSenderName }} -->
                  <small
                    v-if="v$.message?.sender?.$error"
                    id="name-help"
                    class="p-error"
                  >
                    <img
                      class="sucess-image"
                      src="/resources/icons/error_label.svg"
                    />{{ v$.message.sender?.$errors[0]?.$message }}</small
                  >
                </div>
                <!-- recipients -->
                <div class="col-4 md:col-4 lg:col-3">
                  <FloatLabel>
                    <label for="name" class="p-float-label"
                      >{{
                        $t("views.message.formLabels.recipients") +
                        " - " +
                        $t("views.message.formLabels.maxRecipients")
                      }}
                    </label>
                    <Button
                      @click="showRecipientsModal(true, true)"
                      class="add-button mt-2 flex"
                      icon="pi pi-plus"
                      iconPos="left"
                      label="Adicionar número"
                      id="account-add-sender"
                    ></Button>
                  </FloatLabel>
                  <small
                    v-if="v$.message?.recipients?.$error"
                    id="name-help"
                    class="p-error"
                  >
                    <img
                      class="sucess-image"
                      src="/resources/icons/error_label.svg"
                    />{{ v$.message.recipients?.$errors[0]?.$message }}</small
                  >
                </div>
                <!-- recipients counters -->
                <div
                  class="mt-0 col-4 md:col-3 lg:col-3 senders-link"
                  @click="showRecipientsModal(true, true)"
                >
                  <a v-if="message.recipients && message.recipients.length"
                    >{{ message.recipients.length }} destinatário(s)
                    adicionado(s)</a
                  >
                </div>
              </div>
              <div class="p-fluid p-formgrid grid mt-4">
                <div class="col-6 md:col-8 lg:col-8">
                  <FloatLabel>
                    <label for="name"
                      >{{ $t("views.message.formLabels.text") }}
                    </label>
                    <TextArea
                      class="mt-2"
                      id="name"
                      type="text"
                      v-model="message.text"
                      rows="8"
                      :autoResize="true"
                      :maxlength="msgCountMaxLength"
                    />
                    <label class="label-cc">{{ msgCounter }}</label>
                  </FloatLabel>
                  <small
                    v-if="v$.message?.text?.$error"
                    id="name-help"
                    class="p-error"
                  >
                    <img
                      class="sucess-image"
                      src="/resources/icons/error_label.svg"
                    />{{ v$.message.text?.$errors[0]?.$message }}</small
                  >
                </div>
              </div>
              <div
                class="p-fluid p-formgrid grid card mb-5"
                v-if="msgSpecialChars"
              >
                <div class="col-6 md:col-10 lg:col-10" style="">
                  <div style="padding: 0.5rem">
                    <!-- border: 1px solid var(--grey-100);
                      border-radius: 10px; -->
                    <div class="ml-2 mb-1">Codificação</div>
                    <div class="ml-2 mb-3 text-500">
                      Foram detectados caracteres especiais na sua mensagem.
                      Deverá escolher a opção que deseja, sabendo que poderá ter
                      afetação nos consumos e no resultado final da sms.
                    </div>
                    <div class="flex align-items-center mb-2">
                      <RadioButton
                        v-model="message.encodingOption"
                        inputId="smartText"
                        name="smartText"
                        value="smartText"
                      />
                      <label class="ml-3 text-500"
                        >Substituir caracteres especiais por caracteres
                        equivalentes usando o formato GSM-7.<br />
                        <b>ie: á será substítuido por a</b>.</label
                      >
                    </div>
                    <Divider />
                    <div class="flex align-items-center">
                      <RadioButton
                        v-model="message.encodingOption"
                        inputId="smartEncode"
                        name="smartEncode"
                        value="smartEncode"
                      />
                      <label class="ml-3 text-500"
                        >Permitir o envio de caracteres especiais fora do
                        formato GSM-7 usando o formato UNICODE. Terá impacto
                        directo no consumo de sms onde por exemplo uma sms,
                        passará a 70 caracteres.</label
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="p-fluid p-formgrid grid mt-4">
                <div class="col-4">
                  <FloatLabel>
                    <label for="name"
                      >{{ $t("views.message.formLabels.deliveryReport") }}
                    </label>
                    <Dropdown
                      v-model="message.deliveryReport"
                      :options="deliveryReports"
                      optionLabel="name"
                      placeholder="--"
                      @change="changeMessage"
                      class="mt-2 flex"
                    />
                  </FloatLabel>
                </div>
                <div class="col-4">
                  <FloatLabel>
                    <label for="name"
                      >{{ $t("views.message.formLabels.messageValidity") }}
                    </label>
                    <Dropdown
                      v-model="message.messageValidity"
                      :options="messageValidities"
                      optionLabel="name"
                      :option="0"
                      @change="setMessage"
                      class="mt-2 flex"
                    />
                  </FloatLabel>
                </div>
              </div>
              <div class="flex p-justify-end button-wrapper">
                <Button
                  class="icon p-button-secondary"
                  icon="pi pi-arrow-left"
                  iconPos="left"
                  v-if="currentStep !== 0"
                  @click="currentStep = 0"
                  label="Anterior"
                >
                </Button>

                <Button
                  label="Enviar"
                  iconPos="right"
                  @click="sendSMS()"
                  v-if="currentStep !== 0 && edited"
                />
                <Button
                  label="Seguinte"
                  icon="pi pi-arrow-right"
                  iconPos="right"
                  class="icon"
                  v-if="currentStep === 0"
                  @click="checkForm()"
                />
              </div>
            </template>
          </Card>
        </form>
        <!-- resume of the message -->
        <form @submit="submitMessage" v-if="currentStep === 1">
          <div class="card-title-label">&nbsp;</div>
          <!-- message data -->
          <Card class="card">
            <template #content v-if="!loading">
              <h4>{{ $t("views.message.formLabels.general") }}</h4>

              <div class="p-fluid p-formgrid grid card">
                <!-- Name -->
                <div class="p-field col-12 sm-12 md-3">
                  <label for="name"
                    >{{ $t("views.message.formLabels.messageName") }}
                  </label>
                  <p>{{ message.title }}</p>
                </div>
              </div>
              <div class="p-fluid p-formgrid grid card">
                <!-- Sender -->
                <div class="p-field col-3 sm-12 md-3">
                  <label for="name"
                    >{{ $t("views.message.formLabels.sender") }}
                  </label>
                  <p>
                    <!-- {{ msgGetSender }} -->
                    {{ message.sender }}
                    <!-- {{ message.sender.name ? message.sender.name : "não definido" }} -->
                  </p>
                </div>
                <!-- recipients -->
                <div class="p-field col-4 sm-12 md-3">
                  <label for="name"
                    >{{ $t("views.message.formLabels.recipients") }}
                  </label>
                  <div
                    class="p-field col-12 md-12 senders-link"
                    @click="showRecipientsModal(true, false)"
                  >
                    <a v-if="message.recipients && message.recipients.length"
                      >{{ message.recipients.length }}
                      {{ $t("views.message.formLabels.recipientsSentence") }}
                    </a>
                  </div>
                </div>
              </div>
              <Divider />
              <!-- Message -->
              <h4>{{ $t("views.message.formLabels.message") }}</h4>

              <div class="p-fluid p-formgrid grid card">
                <!-- Name -->
                <div class="p-field col-12 sm-12 md-6">
                  <!-- <label for="name"
                    >{{ $t("views.message.formLabels.message") }}
                  </label> -->

                  <p>{{ message.text }}</p>
                </div>
              </div>
              <div
                class="p-fluid p-formgrid grid card mb-5"
                v-if="msgSpecialChars"
              >
                <div class="col-8" style="">
                  <div style="padding: 0.5rem">
                    <div class="ml-2 mb-1">Codificação</div>
                    <div class="ml-2 mb-3 text-500">
                      Foram detectados caracteres especiais na sua mensagem.
                      Deverá escolher a opção que deseja, sabendo que poderá ter
                      afetação nos consumos e no resultado final da sms.
                    </div>
                    <div class="flex align-items-center mb-2">
                      <RadioButton
                        disabled
                        v-model="message.encodingOption"
                        inputId="smartText"
                        name="smartText"
                        value="smartText"
                      />
                      <label class="ml-3 text-500"
                        >Substituir caracteres especiais por caracteres
                        equivalentes utilizando igualmente a codificação
                        GSM-7.<br />
                        <b>ie: á será substítuido por a</b>.</label
                      >
                    </div>
                    <Divider />
                    <div class="flex align-items-center">
                      <RadioButton
                        disabled
                        v-model="message.encodingOption"
                        inputId="smartEncode"
                        name="smartEncode"
                        value="smartEncode"
                      />
                      <label class="ml-3 text-500"
                        >Permitir o envio de caracteres especiais fora do
                        formato GSM-7 suportando UNICODE. Terá impacto directo
                        no consumo de sms's onde uma sms, passará a 70
                        caracteres.</label
                      >
                    </div>
                  </div>
                </div>
              </div>

              <!-- Message Options -->
              <Divider />
              <h4>{{ $t("views.message.formLabels.typology") }}</h4>
              <div class="p-fluid p-formgrid grid card">
                <!-- Sender -->
                <div class="p-field col-3 sm-4 md-3">
                  <label for="name"
                    >{{ $t("views.message.formLabels.deliveryReport") }}
                  </label>
                  <p>
                    {{
                      message.deliveryReport
                        ? message.deliveryReport.name
                        : "não definido"
                    }}
                  </p>
                </div>
                <!-- recipients -->
                <div class="p-field col-3 sm-4 md-3">
                  <label for="name"
                    >{{ $t("views.message.formLabels.messageValidity") }}
                  </label>
                  <p>
                    {{
                      message.messageValidity
                        ? message.messageValidity.name
                        : "não definido"
                    }}
                  </p>
                </div>
              </div>
              <div class="flex p-justify-end button-wrapper">
                <Button
                  class="icon p-button-secondary"
                  icon="pi pi-arrow-left"
                  iconPos="left"
                  v-if="currentStep !== 0"
                  @click="currentStep = 0"
                  label="Anterior"
                >
                </Button>

                <Button
                  label="Enviar"
                  iconPos="right"
                  @click="sendSMS()"
                  v-if="currentStep !== 0 && edited"
                />
                <Button
                  label="Seguinte"
                  icon="pi pi-arrow-right"
                  iconPos="right"
                  class="icon"
                  v-if="currentStep === 0"
                  @click="checkForm()"
                />
              </div>
            </template>
          </Card>
        </form>
      </div>
    </template>
  </Page>
</template>

<script>
import Page from "@/components/Page.vue";
import useVuelidate from "@vuelidate/core";
import { required, validateEncodingLenght } from "@/shared/customValidators.js";
import AddGenericOptionDialog from "@/components/AddGenericOptionDialog.vue";
import ErrorState from "@/components/utils/ErrorState.vue";
import { getnrMsgs } from "@/utils/maxCalc.js";

import { submitSMS } from "../services/messages.service.js";
import { getSender } from "../services/account.service.js";
import { getCountryCodes } from "../services/product.service.js";
import { SegmentedMessage } from "sms-segments-calculator";

import AuthApp from "../main";

export default {
  components: {
    Page,
    AddGenericOptionDialog,
    ErrorState,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  mounted() {
    this.getSender();
    this.getCountryCodes();
  },
  data() {
    return {
      auth: AuthApp,
      loading: true,
      error: false,
      optionMasks: [],
      message: {
        title: "",
        text: "",
        deliveryReport: { name: "Nenhum", code: "NONE" },
        messageValidity: { name: "4h", code: "FOUR_HOURS" },
        subaccount: 1, //FIXME
        encodingOption: "smartText",
      },
      currentStep: 0,
      deliveryReports: [
        { name: "Nenhum", code: "NONE" },
        { name: "Todos", code: "ALL" },
        { name: "Só entregues", code: "DELIVERED" },
        { name: "Não entregues", code: "NON_DELIVERED" },
      ],
      messageValidities: [
        { name: "4h", code: "FOUR_HOURS" },
        { name: "8h", code: "EIGHT_HOURS" },
        { name: "24h", code: "DAY" },
        { name: "48h", code: "TWO_DAYS" },
      ],
      items: [
        {
          label: "Dados",
        },
        {
          label: "Resumo",
        },
      ],
      showRecipients: false,
      canEditRecipients: false,
      maxRecipients: 10,
    };
  },
  computed: {
    msgCounter() {
      if (this.message.encodingOption === "smartEncode") {
        var sms = new SegmentedMessage(this.message.text);

        var maxSms =
          sms.getNonGsmCharacters().length &&
          this.message.encodingOption === "smartEncode"
            ? 335
            : 765;

        return (
          sms.numberOfCharacters +
          "/" +
          maxSms +
          ", " +
          sms.segmentsCount +
          " SMS"
        );
      } else {
        var obj = getnrMsgs(this.message.text);
        return obj.chars + "/765, " + obj.nrMsgs + " SMS";
      }
    },
    msgSpecialChars() {
      var sms = new SegmentedMessage(this.message.text);

      var len = sms.getNonGsmCharacters().length;
      if (!len) this.resetEncodingOptions();

      return len ? true : false;
    },

    msgCountMaxLength() {
      var sms = new SegmentedMessage(this.message.text);
      var maxSms =
        sms.getNonGsmCharacters().length &&
        this.message.encodingOption === "smartEncode"
          ? 335
          : 765;

      return maxSms - (sms.numberOfCharacters - this.message.text.length);
    },
  },
  validations() {
    return {
      message: {
        title: {
          required: required,
        },
        sender: {
          required: required,
        },
        text: {
          required: required,
          length: validateEncodingLenght(),
        },
        recipients: {
          required: required,
        },
        encodingOption: {},
      },
    };
  },
  methods: {
    resetEncodingOptions: function () {
      this.message.encodingOption = "smartText";
    },
    onErrorStateButtonClick: function () {},
    async checkForm() {
      await this.v$.$validate();
      if (!this.v$.$errors.length) {
        this.currentStep = 1;
        this.edited = true;
      }
    },
    async submitMessage() {
      console.log("DUMMY");
    },

    async getCountryCodes() {
      const [countryCodes] = await getCountryCodes();
      this.optionMasks = countryCodes;

      // if (err) {
      //   // this.empty = true;
      //   this.$toast.add({
      //     severity: "error",
      //     summary: "Erro",
      //     detail: "Aconteceu um erro, por favor tente mais tarde!",
      //     life: 3000,
      //   });

      // } else {
      // console.log(appClientRes);
      // this.appKeys = appClientRes;
      // this.$emit("updatedAppKeys", this.appKeys.credentials.length);
      // }
    },
    async sendSMS() {
      this.loader = this.$loading.show();

      const [, err] = await submitSMS({
        body: {
          ...this.message,
          ...{ [this.message.encodingOption]: true },
        },
      });

      if (err) {
        this.$toast.add({
          severity: "error",
          summary: "Erro",
          detail: this.$te("views.message.errors." + err["error-code"])
            ? this.$t("views.message.errors." + err["error-code"])
            : this.$t("views.message.errors.generic"),
          life: 3000,
        });
        this.loader.hide();
      } else {
        this.$router.push({ name: "home" });
        this.$toast.add({
          severity: "success",
          summary: "Sucesso",
          detail:
            this.message.recipients.length > 1
              ? "Sms enviada com sucesso"
              : "Sms's enviadas com sucesso",
          life: 3000,
        });
        this.loader.hide();
      }
    },

    async getSender() {
      this.loader = this.$loading.show();

      const [res, err] = await getSender();

      if (err) {
        this.loader.hide();
        this.error = true;
      } else {
        this.messageSenders = res.items;
        this.error = false;
        this.loader.hide();
      }
      this.loading = false;
    },
    showRecipientsModal(value, canEdit) {
      this.canEditRecipients = canEdit;
      this.showRecipients = value;
    },
    saveRecipients(recipients) {
      this.message.recipients = recipients;
      // this.client.sendersNames = senders.map((s) => s.name);
    },
  },
};
</script>

<style scoped>
h1 {
  font-weight: 400;
}

.title-wrapper {
  display: flex;
  align-items: center;
}

.title-wrapper i {
  font-size: 40px;
}

.divider:before {
  border-left: 1px solid black !important;
}
.card {
  min-height: 100px !important;
}
.clients-content {
  text-align: left;
  color: hsl(210, 16%, 53%);
  position: relative;
}

.info-text {
  font-weight: bold;
  color: var(--grey-900);
  font-size: 14px;
}
.info-wrapper {
  display: flex;
  align-items: center;
  font-weight: 300;
  gap: 10px;
}

.info-wrapper i {
  color: var(--blue);
}
.info-href {
  font-size: 14px;
  color: var(--grey-900);
  border-bottom: 2px solid var(--lime);
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.p-tabview-nav {
  background-color: black;
}

.step-wrapper {
  width: 250px;
  float: right;
  position: relative;
  bottom: 30px;
}

.button-wrapper {
  gap: 30px;
}

.user-buttons {
  margin-top: 20px;
  padding: 10px;
  gap: 20px;
}

.user-buttons button {
  padding: 5px;
}

.senders-link {
  display: flex;
  margin-top: 25px;
  align-items: center;
}

.senders-link a {
  display: flex;
  align-items: center;
  text-decoration: underline;
  text-decoration-color: var(--lime);
  text-underline-offset: 5px;
  cursor: pointer;
}

.edited .senders-link {
  margin-top: 0;
}

.add-button {
  font-family: Azo Sans !important;
  font-weight: 400;
  font-size: 16px;
  color: #5b5c64;
  background: white;
  border-radius: 0;
  height: auto;
  width: 250px;
  text-align: left;
  padding: 2px !important;
  box-shadow: none;
  border: 1px solid var(--grey-100);
}

.edited {
  border-left: 3px solid blue;
  height: fit-content;
}

.label-cc {
  float: right;
  top: -25px;
  right: 5px;
  position: relative;
  font-size: 14px;
}

:deep(.p-dropdown .p-inputtext) {
  border: none;
}

:deep(.p-highlight) {
  border-bottom: 0px;
}
</style>
