import Model from "../Model";

export class CorporateUser extends Model {
  username = "";
  constructor(data) {
    super(data);

    if (data) {
      this.setValueFromData("username", String(data.username).toString());
    }
  }
}
