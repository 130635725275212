<template>
  <header>
    <div class="header-content">
      <div class="title-wrapper">
        <i v-if="leftIcon" :class="`pi ${leftIcon}`" @click="onIconClick" />
        <Divider v-if="leftIcon" layout="vertical" class="divider" />
        <h1>{{ title }}</h1>
      </div>
      <slot></slot>
    </div>
    <tab-navigator :options="tabItems" @onTabClick="onTabClick" />
  </header>
</template>

<script>
import TabNavigator from "./TabNavigator.vue";
export default {
  name: "Header",
  components: { TabNavigator },
  emits: ["onIconClick", "onTabClick"],
  props: {
    title: String,
    leftIcon: String,
    roundBorderIcon: Boolean,
    tabItems: Object,
  },
  methods: {
    onIconClick(e) {
      this.$emit("onIconClick", e);
    },
    onTabClick(e) {
      this.$emit("onTabClick", e);
    },
  },
};
</script>

<style scoped>
header {
  /* left: -34px; */
  /* min-height: 134px; */
  /* background-color: var(--main-bg-color); */
  /* color: rgb(255, 255, 255); */
  /* padding-left: 30px; */
  /* padding-right: 40px; */
  color: black;
  height: 30px;
  width: 100%;
  display: flex;
}

h1 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
}

.g-nav > li {
  position: relative;
  display: inline-block;
  list-style: none;
}

.g-nav > li > a {
  padding: 10px;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  flex: 1;
}

.title-wrapper {
  display: flex;
  align-items: center;
}

.title-wrapper i {
  font-size: 20px;
  cursor: pointer;
}

.divider:before {
  border-left: 1px solid black !important;
}

.roundIcon {
  border: 1px solid rgba(30, 31, 39, 0.3);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 100%;
  font-size: 50px !important;
  margin-right: 20px;
}
</style>
