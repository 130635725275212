import http from "../shared/services/http";

export const submitSMS = async (data) => {
  try {
    const payload = {
      body: data.body.text,
      recipients: data.body.recipients.map(function (item) {
        // var obj = { to: item["name"] };
        return { to: item["name"] };
      }),
      sender: data.body.sender,
      dlr: data.body.deliveryReport.code,
      ttl: data.body.messageValidity.code,
      title: data.body.title,
      ...(data.body.smartText && { smartText: true }),
      ...(data.body.smartEncode && { smartEncode: true }),
    };

    const result = await http.post(
      `/communication/v1/sms`,
      payload
      // { headers: data.headers }
      // data.headers
    );
    return [result, null];
  } catch (err) {
    return [null, err];
  }
};
