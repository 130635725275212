<template>
  <div>
    <DataTable
      :value="notifications"
      :rowHover="true"
      responsiveLayout="scroll"
    >
      <Column field="type" header="Tipo"></Column>
      <Column field="description" header="Descrição"></Column>
    </DataTable>
    <div class="flex justify-content-end mt-3 mb-1">
      <Button icon="pi pi-times" label="Fechar" @click="closeDialog" />
    </div>
  </div>
</template>

<script>
import { getNotifications } from "@/services/notification.service.js";

export default {
  inject: ["dialogRef"],
  data() {
    return {
      notifications: null,
    };
  },
  notificationService: null,
  mounted() {
    this.notifications = getNotifications();
  },
  methods: {
    closeDialog() {
      this.dialogRef.close();
    },
    selectProject(data) {
      this.dialogRef.close(data);
    },
  },
};
</script>
