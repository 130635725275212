<template>
  <ErrorState
    v-if="!loading && error"
    @onErrorStateButtonClick="onErrorStateButtonClick()"
    message="Algo não correu bem"
    buttonText="Voltar a tentar"
  ></ErrorState>

  <div v-if="!error" class="flex align-items-stretch flex-wrap">
    <div class="flex align-items-start results">
      {{ totalRecords }} resultado(s)
    </div>
    <!-- <div
      class="flex-grow-1 flex align-items-center justify-content-center"
    ></div> -->
    <!-- <div
      class="flex align-items-end results cursor-pointer"
      @click="showFilters = !showFilters"
    >
      <template v-if="showFilters">
        Fechar filtros
        <img class="menu-icon" src="/resources/icons/close.svg" />
      </template>
      <template v-else>
        <img class="menu-icon" src="/resources/icons/filter.svg" />
        Filtros
      </template>
    </div>-->
  </div>
  <Divider />
  <!-- <Card class="card fadein animation-duration-500" v-if="showFilters">
    <template #content>
      <div class="formgrid grid">
        <div class="field col-12 md:col-2">
          <Calendar
            id="searchDate"
            v-model="searchDate"
            selectionMode="range"
            dateFormat="yy-mm-dd"
            :showIcon="true"
            placeholder="--"
            :minDate="minDate()"
            :manualInput="false"
            class="w-full"
            @hide="handleDate($event)"
          />
          <small id="searchDate">Período</small>
        </div>
        <div class="field col-12 md:col-2">
          <Dropdown
            id="searchStatus"
            v-model="searchStatus"
            :options="availableStatus"
            optionLabel="code"
            placeholder="--"
            class="w-full"
            @change="handleStatus($event)"
          />
          <small id="searchStatus">Estado</small>

          <select
            id="state"
            class="w-full p-inputtext p-component"
            style="appearance: auto"
          >
          <option default>Todos os Remetentes</option>
          </select>
        </div>
        <div class="field col-12 md:col-2 p-input-icon-right">
          <span class="p-input-icon-right w-full">
            <InputText
              id="searchText"
              type="text"
              v-model="searchText"
              class="w-full"
              @keyup="handleSearchText($event.target.value)"
            />
            <i class="pi pi-search"></i>
          </span>
          <small id="searchText">Texto</small>
        </div>
        <div class="field col-12 md:col-2 p-input-icon-right">
          <span class="p-input-icon-right w-full">
            <InputText
              id="searchTo"
              type="text"
              class="w-full"
              v-model="searchTo"
              @keyup="handleSearchTo($event.target.value)"
            />
            <i class="pi pi-search"></i>
          </span>
          <small id="searchTo">Número</small>
        </div>
      </div>
    </template>
  </Card> -->
  <EmptyState
    v-if="!items.length && !loading && !error && !handledFilters?.length"
    @onEmptyStateButtonClick="$router.push({ name: 'newMessage' })"
    message="Sem mensagens"
    :showButton="false"
  ></EmptyState>
  <DataTable
    :rowHover="true"
    :value="items"
    :paginator="false"
    :row-hover="true"
    responsiveLayout="scroll"
    v-if="items.length || handledFilters?.length"
    @sort="onSort($event)"
    @filter="onFilter($event)"
    filterDisplay="row"
    v-model:filters="filters"
    lazy
  >
    <template #empty> Sem sms encontradas para os filtros aplicados. </template>
    <Column field="from" :header="$t('views.message.formLabels.phoneNumber')">
      <template #filter="{ filterModel, filterCallback }">
        <InputText
          v-model="filterModel.value"
          type="text"
          @input="filterCallback()"
          class="p-column-filter"
          placeholder="msisdn"
          :disabled="loading"
        />
      </template>
    </Column>
    <Column
      field="text"
      :header="$t('views.message.formLabels.text')"
      style="max-width: 600px !important"
      class="overflow-auto"
    >
      <template #filter="{ filterModel, filterCallback }">
        <InputText
          v-model="filterModel.value"
          type="text"
          @input="filterCallback()"
          class="p-column-filter"
          placeholder="texto"
          :disabled="loading"
        />
      </template>
    </Column>
    <Column
      field="status"
      :header="$t('views.message.formLabels.status')"
      v-if="type == 'MT'"
    >
      <template #body="slotProps">
        {{
          slotProps.data.dlrStatus
            ? $t("views.message.formLabels.dlr." + slotProps.data.dlrStatus)
            : "n/a"
        }}
      </template>
      <template #filter="{ filterModel, filterCallback }">
        <Dropdown
          id="searchStatus"
          v-model="filterModel.value"
          :options="availableStatus"
          optionLabel="name"
          optionValue="code"
          :placeholder="availableStatus[0].name"
          class="w-full"
          @change="filterCallback($event)"
          :disabled="loading"
        />
      </template>
    </Column>
    <Column
      field="date"
      :header="$t('views.message.dashboard.table.submissionDate')"
      sortField="date"
      sortable
    >
      <template #body="slotProps">
        {{ convertEpochToDate(slotProps.data.date) }}
      </template>
    </Column>
  </DataTable>
  <CustomPaginator
    :size="size"
    :totalRecords="Number(totalRecords)"
    :first="first"
    :rowsPerPageOptions="[10, 20, 30]"
    @pageChange="handlePageChange"
    v-if="items.length"
  />
</template>

<script>
import ErrorState from "@/components/utils/ErrorState.vue";
import CustomPaginator from "@/components/CustomPaginator.vue";
import AuthApp from "../main";
import dayjs from "dayjs";
import EmptyState from "@/components/utils/EmptyState.vue";
import { getMessageSms } from "@/services/log.service";

export default {
  name: "messagelist",
  data: function () {
    return {
      auth: AuthApp,
      showFilters: false,
      loading: false,
      catalog: {},
      // searchDate: null,
      searchText: null,
      searchStatus: null,
      availableStatus: [
        { name: "ALL", code: "" },
        { name: "SENT", code: "SENT" },
        { name: "DELIVERED", code: "DELIVERED" },
        { name: "NON_DELIVERED", code: "NON_DELIVERED" },
      ],
      items: [],
      // filters: [],
      first: 0,
      size: 10,
      page: 0,
      filters: {
        to: {
          value: null,
          matchMode: "LIKE",
        },
        from: {
          value: null,
          matchMode: "LIKE",
        },
        text: {
          value: null,
          matchMode: "LIKE",
        },
        status: { value: null, matchMode: "LIKE" },
        type: { value: this.type ? this.type : null, matchMode: "LIKE" },
      },
    };
  },
  components: {
    ErrorState,
    CustomPaginator,
    EmptyState,
  },
  mounted() {
    let params = this.$route.query;
    if (params.prevpage) {
      this.page = parseInt(params.prevpage, 10);
    }
    if (params.prevsize) {
      this.size = parseInt(params.prevsize, 10);
    }
    if (params.prevfirst) {
      this.first = parseInt(params.prevfirst, 10);
    }

    //load labels
    this.loadAvailableStatus();

    //clean up just in case
    this.$router.replace({ query: null });

    this.getData();
  },
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  computed: {},
  methods: {
    async getData() {
      this.error = false;
      this.loading = true;
      this.loader = this.$loading.show();

      let filters = this.handledFilters?.length
        ? JSON.stringify(this.handledFilters)
        : "";

      //hardcode filters with type as default
      if (!filters) {
        filters = JSON.stringify([
          {
            property: "type",
            operator: "LIKE",
            value: this.type ? this.type : null,
          },
        ]);
      }

      let sort = this.handledSort?.length
        ? JSON.stringify(this.handledSort)
        : "";

      let params = this.$route.params;

      const [res, err] = await getMessageSms(params.id, {
        filter: filters,
        sort: sort,
        page: this.page,
        size: this.size,
      });

      if (err) {
        this.error = true;
        this.$toast.add({
          severity: "error",
          summary: "Erro",
          detail: "Aconteceu um erro, por favor tente mais tarde!",
          life: 3000,
        });
      } else {
        this.items = res.items;
        this.totalRecords = res.totalItems;
      }

      this.loader.hide();
      this.loading = false;
    },

    onErrorStateButtonClick() {
      this.getData();
    },
    handlePageChange(e) {
      this.page = e.page;
      this.size = e.rows;
      this.first = e.first;
      this.getData();
    },

    minDate: function () {
      var d = new Date();
      d.setMonth(d.getMonth() - 6);
      return d;
    },

    convertEpochToDate: function (date) {
      return dayjs.unix(date).format("YYYY-MM-DD HH:mm:s");
    },

    loadAvailableStatus: function () {
      var self = this;
      this.availableStatus.forEach((item) => {
        item.name = self.$t("views.message.formLabels.dlr." + item.name);
        // item.code = item.code;
      });
    },

    //FIXME - cache this in a storage like VUEX..
    /* filters and sorts methods */
    onSort(e) {
      this.handledSort = [];

      if (e.sortField) {
        this.handledSort = [
          {
            property: e.sortField,
            operator: e.sortOrder == 1 ? "ASC" : "DESC",
          },
        ];
      }

      this.getData();
    },
    onFilter() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.handledFilters = Object.keys(this.filters)
          .map((key) => {
            return {
              property: key,
              operator: this.filters[key].matchMode,
              value:
                typeof this.filters[key].value === "object"
                  ? this.filters[key].value?.code
                  : this.filters[key].value,
            };
          })
          .filter((obj) => obj.value);

        //reset paging
        this.page = 0;

        this.getData();
      }, 800);
    },
  },
};
</script>

<style scoped>
.p-tabview-panels {
  padding-top: 0px !important;
}

.p-dropdown .p-dropdown-label {
  padding: 0.55rem 0.6rem;
}

:deep() .p-column-filter-row .p-column-filter-menu-button {
  display: none !important;
}
</style>
