<template>
  <div class="stepper-wrapper">
    <div
      v-for="(item, index) in items"
      :key="item.label"
      :class="`stepper-item ${
        (currentStep >= index + 1 || currentStep === items.length - 1) &&
        'completed'
      }`"
    >
      <div class="step-counter-wrapper">
        <div
          :class="`step-counter ${
            (index === 0 || currentStep === index) && 'first'
          }`"
        ></div>
      </div>
      <div class="step-name">{{ item.label }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Steps",
  props: {
    currentStep: Number,
    items: Array,
  },
  setup(props) {
    console.log(props.items);
  },
};
</script>

<style scoped>
.stepper-wrapper {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  font-size: 14px;
}
.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: -50%;
  z-index: 2;
}

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 2;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 6px;
  border-radius: 16px;
  background: #ccc;
  margin-bottom: 6px;
}

.step-counter-wrapper {
  position: relative;
  top: 18px;
  width: 24px;
  background: var(--backgroud-color);
  justify-content: center;
  display: flex;
  z-index: 100;
}

.step-name {
  position: relative;
  top: 20px;
}

.stepper-item.active {
  font-weight: bold;
}

.stepper-item.completed .step-counter {
  background-color: var(--lime);
}

.stepper-item.completed::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid var(--lime) !important;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 3;
}

.stepper-item:first-child::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid var(--lime);
  width: 30px;
  top: 20px;
  left: calc(50% - 40px);
  z-index: 2;
}
.stepper-item:last-child::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 50px;
  top: 20px;
  left: 50%;
  z-index: 2;
}

.step-counter.first {
  background-color: var(--lime);
}
</style>
