<template>
  <Page
    leftIcon="pi-times"
    :title="$t('views.userAccount.headerTitle')"
    @onIconClick="$router.push({ name: 'account', query: { tab: 'users' } })"
  >
    <template v-slot:header>
      <div class="p-d-flex p-justify-end button-wrapper">
        <Button
          class="icon p-button-secondary mr-2"
          icon="pi pi-arrow-left"
          iconPos="left"
          v-if="currentStep !== 0"
          @click="currentStep = 0"
          label="Anterior"
        >
        </Button>

        <Button
          label="CriarUtilizador"
          iconPos="right"
          @click="addUser()"
          v-if="currentStep !== 0 && edited"
        />
        <Button
          label="Seguinte"
          icon="pi pi-arrow-right"
          iconPos="right"
          class="icon"
          v-if="currentStep === 0"
          @click="checkForm()"
        />
      </div>
    </template>
    <template v-slot:content>
      <div class="clients-content end-nav">
        <div class="step-wrapper">
          <Steps :currentStep="currentStep" :items="items" />
        </div>
        <form @submit="checkForm" v-if="currentStep === 0">
          <div class="card-title-label">
            {{ $t("views.userAccount.formLabels.general") }}
          </div>
          <!-- message data -->
          <Card class="card">
            <template #content v-if="!loading">
              <!-- EMAIL -->
              <div class="p-fluid p-formgrid p-grid card">
                <div class="p-field p-col-12 p-sm-12 p-md-4">
                  <label for="name"
                    >{{ $t("views.userAccount.formLabels.email") }}
                  </label>
                  <InputText
                    id="name"
                    type="email"
                    v-model="user.email"
                    placeholder="--"
                  />
                  <small
                    v-if="v$.user?.email?.$error"
                    id="name-help"
                    class="p-error"
                  >
                    <img
                      class="sucess-image"
                      src="/resources/icons/error_label.svg"
                    />{{ v$.user.email?.$errors[0]?.$message }}</small
                  >
                </div>
              </div>
              <!-- PROFILE -->
              <div class="p-fluid p-formgrid p-grid card">
                <div class="p-field p-col-12 p-sm-12 p-md-4">
                  <label for="name"
                    >{{ $t("views.userAccount.formLabels.profile") }}
                  </label>
                  <Dropdown
                    v-model="user.profile"
                    :options="userProfiles"
                    optionLabel="name"
                    placeholder="--"
                    @change="changeMessage"
                  />
                  <small
                    v-if="v$.user?.profile?.$error"
                    id="name-help"
                    class="p-error"
                  >
                    <img
                      class="sucess-image"
                      src="/resources/icons/error_label.svg"
                    />{{ v$.user.profile?.$errors[0]?.$message }}</small
                  >
                </div>
              </div>
              <!-- SUB-ACCOUNT -->
              <!-- <div class="p-fluid p-formgrid p-grid card">
                <div class="p-field p-col-12 p-sm-12 p-md-4">
                  <label for="name"
                    >{{ $t("views.userAccount.formLabels.account") }}
                  </label>
                  <Dropdown
                    v-model="user.subAccount"
                    :options="accountSubAccounts"
                    optionLabel="name"
                    placeholder="--"
                    @change="changeMessage"
                  />
                  <small
                    v-if="v$.user?.subAccount?.$error"
                    id="name-help"
                    class="p-error"
                  >
                    <img
                      class="sucess-image"
                      src="/resources/icons/error_label.svg"
                    />{{ v$.user.subAccount?.$errors[0]?.$message }}</small
                  >
                </div>
              </div> -->
            </template>
          </Card>
        </form>
        <!-- resume of the message -->
        <form @submit="submitMessage" v-if="currentStep === 1">
          <div class="card-title-label">
            {{ $t("views.userAccount.formLabels.confirmData") }}
          </div>
          <!-- message data -->
          <Card class="card">
            <template #content v-if="!loading">
              <h4>{{ $t("views.userAccount.formLabels.general") }}</h4>

              <div class="p-fluid p-formgrid p-grid card">
                <!-- Name -->
                <div class="p-field p-col-12 p-sm-12 p-md-3">
                  <label for="name"
                    >{{ $t("views.userAccount.formLabels.email") }}
                  </label>
                  <p>{{ user.email }}</p>
                </div>
                <!-- Profile -->
                <div class="p-field p-col-12 p-sm-12 p-md-3">
                  <label for="name"
                    >{{ $t("views.userAccount.formLabels.profile") }}
                  </label>
                  <p>
                    {{ user.profile.name }}
                  </p>
                </div>
                <!-- <div class="p-field p-col-12 p-sm-12 p-md-3">
                  <label for="name"
                    >{{ $t("views.userAccount.formLabels.account") }}
                  </label>
                  <p>{{ user.subAccount.name }}</p>
                </div> -->
              </div>
            </template>
          </Card>
        </form>
      </div>
    </template>
  </Page>
</template>

<script>
import Page from "@/components/Page.vue";
import useVuelidate from "@vuelidate/core";
import { required, macthes } from "@/shared/customValidators.js";
import { createUser } from "@/services/user.service";
// import keycloak from "../keycloak";

const regexEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;

export default {
  components: {
    Page,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      user: {},
      loading: false,
      currentStep: 0,
      items: [{ label: "Dados" }, { label: "Resumo" }],
      userProfiles: [
        { name: "Administrador", code: "admin" },
        { name: "Utilizador", code: "user" },
      ],
    };
  },
  validations() {
    return {
      user: {
        email: {
          required: required,
          regex: macthes(regexEmail),
        },
        profile: {
          required: required,
        },
      },
    };
  },
  methods: {
    async addUser() {
      this.loading = true;
      this.loader = this.$loading.show();

      const [, err] = await createUser({
        name: this.user.email,
        email: this.user.email,
        role: this.user.profile.code,
        mobileNumber: "+351930000000",
        status: "active",
      });

      this.loading = false;

      if (err) {
        this.loader.hide();
        this.$toast.add({
          severity: "error",
          summary: "Erro",
          detail: this.$te("views.users.errors." + [err.response.detail])
            ? this.$t("views.users.errors." + [err.response.detail])
            : this.$t("views.users.errors." + err.response["error-code"]),
          life: 3000,
        });
      } else {
        this.loader.hide();
        this.$toast.add({
          severity: "success",
          summary: "Sucesso",
          detail: "Utilizador criado com sucesso",
          life: 3000,
        });
        this.$router.push({ name: "account", query: { tab: "users" } });
      }
    },
    async checkForm() {
      await this.v$.$validate();
      if (!this.v$.$errors.length) {
        this.currentStep = 1;
        this.edited = true;
      }
    },
  },
};
</script>

<style scoped>
h1 {
  font-weight: 400;
}

.title-wrapper {
  display: flex;
  align-items: center;
}

.title-wrapper i {
  font-size: 40px;
}

.divider:before {
  border-left: 1px solid black !important;
}
.card {
  min-height: 100px !important;
}
.clients-content {
  text-align: left;
  /* color: hsl(210, 16%, 53%); */
  position: relative;
}

.info-text {
  font-weight: bold;
  color: var(--grey-900);
  font-size: 14px;
}
.info-wrapper {
  display: flex;
  align-items: center;
  font-weight: 300;
  gap: 10px;
}

.info-wrapper i {
  color: var(--blue);
}
.info-href {
  font-size: 14px;
  color: var(--grey-900);
  border-bottom: 2px solid var(--lime);
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.p-tabview-nav {
  background-color: black;
}

.step-wrapper {
  width: 250px;
  float: right;
  position: relative;
  bottom: 30px;
}

.button-wrapper {
  gap: 30px;
}

.user-buttons {
  margin-top: 20px;
  padding: 10px;
  gap: 20px;
}

.user-buttons button {
  padding: 5px;
}

.senders-link {
  display: flex;
  margin-top: 25px;
  align-items: center;
}

.senders-link a {
  display: flex;
  align-items: center;
  text-decoration: underline;
  text-decoration-color: var(--lime);
  text-underline-offset: 5px;
  cursor: pointer;
}

.edited .senders-link {
  margin-top: 0;
}

.add-button {
  font-family: Azo Sans !important;
  font-weight: 400;
  font-size: 16px;
  color: #5b5c64;
  background: white;
  border-radius: 0;
  height: auto;
  width: 250px;
  text-align: left;
  padding: 2px !important;
  box-shadow: none;
  border: 1px solid var(--grey-200);
}

.edited {
  border-left: 3px solid blue;
  height: fit-content;
}
</style>
