<template>
  <Dialog
    position="center"
    :visible="showDialog"
    :dismissableMask="true"
    :modal="true"
    style="width: 640px"
    :draggable="false"
    :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
    :style="{ width: '70vw' }"
  >
    <template #header>
      <p>
        Adicionar uma ou várias listas de contactos
        <small>( máximo 5 listas por campanha )</small>
      </p>
    </template>
    <div class="page-content">
      <DataTable
        v-model:selection="selectedItems"
        v-model:filters="filterItems"
        :globalFilterFields="['name']"
        :value="items"
        :paginator="false"
        :row-hover="true"
        responsiveLayout="scroll"
        @rowSelect="checkStatus"
        @rowUnselect="checkStatus"
        :rows="5"
        disabledSelection="#{status != true}"
        lazy
      >
        <template #header>
          <div class="flex justify-content-between">
            <!-- <Button
              type="button"
              icon="pi pi-filter-slash"
              label="Clear"
              outlined
              @click="clearFilter()"
            /> -->
            <span class="p-input-icon-left">
              <InputText
                v-model="filterItems['global'].value"
                @keyup="filterCallback()"
                :disabled="loading"
                placeholder="Procurar por nome"
              />
            </span>
            <Button
              type="button"
              class="square"
              icon="pi pi-filter-slash"
              label="Limpar"
              @click="clearFilter()"
              :disabled="this.filters.length == 1"
            />
          </div>
        </template>
        <template #empty> Nenhuma lista encontrada </template>
        <template #loading> Carregar listas.. </template>

        <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
        <Column field="name" disabledSelection="true">
          <template #header>
            <i class="pi pi-tag mr-1" style="font-size: 0.8rem"></i>
            {{ $t("views.contacts.table.listName") }}
          </template>
        </Column>
        <Column field="contacts">
          <template #header>
            <i class="pi pi-phone mr-1" style="font-size: 0.8rem"></i>
            {{ $t("views.contacts.table.contacts") }}
          </template>
          <template #body="slotProps">
            {{
              slotProps.data.status != "accepted" &&
              slotProps.data.status != "processing"
                ? slotProps.data.contacts
                : "n/a"
            }}
          </template>
        </Column>
        <Column field="status">
          <template #header>
            <i class="pi pi-sync mr-1" style="font-size: 0.8rem"></i>
            {{ $t("views.contacts.table.status") }}
          </template>
          <template #body="slotProps">
            <i :class="'customer-badge status-' + slotProps.data.status">{{
              $t("views.general.statuses." + slotProps.data.status)
            }}</i>
          </template>
        </Column>
      </DataTable>
      <CustomPaginator
        :size="size"
        :totalRecords="Number(totalRecords)"
        :first="first"
        :rowsPerPageOptions="[5, 10]"
        @pageChange="handlePageChange"
        v-if="items.length"
      />
    </div>
    <template #footer>
      <div class="modal-footer">
        <Button class="square" label="Cancelar" @click="onClose()" />
        <Button
          class="square"
          label="Confirmar"
          @click="onSave()"
          :disabled="!showConfirm || selectedItems.length > 5"
        />
      </div>
    </template>
  </Dialog>
</template>

<script>
// import ErrorState from "@/components/utils/ErrorState.vue";
import CustomPaginator from "@/components/CustomPaginator.vue";
import AuthApp from "../main";
// import EmptyState from "@/components/utils/EmptyState.vue";
import { getLists } from "@/services/contact.service.js";
import { FilterMatchMode, FilterOperator } from "primevue/api";

export default {
  emits: ["onClose", "onSave"],
  props: {
    lists: {
      type: Array,
    },
    showDialog: {
      type: Boolean,
    },
  },
  name: "messagelist",
  components: {
    CustomPaginator,
    // ErrorState
  },
  setup() {
    return {};
  },
  data: function () {
    return {
      auth: AuthApp,
      showConfirm: false,
      selectedItems: [],
      filterItems: null,
      items: [],
      filters: [
        {
          property: "status",
          operator: "like",
          value: "processed",
        },
      ],
      first: 0,
      size: 5,
      page: 0,
      loading: false,
    };
  },
  mounted() {
    if (this.showDialog) {
      this.initFilters();
      this.selectedItems = this.lists;
      this.getData();
    }
  },
  methods: {
    clearFilter() {
      if (this.filters.length) {
        this.initFilters();
        this.clearDynamicFilter();
        this.getData();
      }
    },
    /* left this to aplly more logic in the future */
    initFilters() {
      this.filterItems = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      };
    },
    filterCallback() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.clearDynamicFilter();
        this.filters.push({
          property: "name",
          operator: "like",
          value: this.filterItems.global.value,
        });

        //reset paging
        this.first = 0;
        this.getData();
      }, 700);
    },
    clearDynamicFilter() {
      this.filters = this.filters.filter(function (obj) {
        return obj.property !== "name";
      });
    },
    async getData() {
      this.error = false;
      this.loading = true;
      this.loader = this.$loading.show();

      const [res, err] = await getLists({
        filter: JSON.stringify(this.filters),
        page: this.page,
        size: this.size,
      });

      if (err) {
        this.error = true;
        this.$toast.add({
          severity: "error",
          summary: "Erro",
          detail: "Aconteceu um erro, por favor tente mais tarde!",
          life: 3000,
        });
      } else {
        this.items = res.items;
        this.totalRecords = res.totalItems;
      }

      this.loader.hide();
      this.loading = false;
    },
    handlePageChange(e) {
      this.page = e.page;
      this.size = e.rows;
      this.first = e.first;
      this.getData();
    },

    /*datatable helpers*/
    checkStatus() {
      if (this.lists.length != this.selectedItems.length) {
        this.showConfirm = true;
      } else {
        const results = this.lists.filter(
          ({ listId: id1 }) =>
            !this.selectedItems.some(({ listId: id2 }) => id1 === id2)
        );

        this.showConfirm = results.length > 0 ? true : false;
      }
    },
    onClose: function () {
      this.$emit("onClose");
    },
    onSave: function () {
      console.log("ITEMS::::", this.selectedItems);
      this.$emit("onSave", this.selectedItems);
    },
  },
};
</script>

<style scoped>
:deep() .p-paginator {
  background-color: inherit !important;
}

/* hidden select all */
:deep()
  .p-datatable
  .p-datatable-thead
  > tr
  > th:first-child
  > .p-column-header-content {
  display: none !important;
}

:deep() .p-highlight {
  border-bottom: none !important;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
