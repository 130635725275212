<template>
  <Page
    :title="$t('views.templates.headerTitle')"
    @onIconClick="this.$router.push({ name: 'home' })"
  >
    <template v-slot:content v-if="!loading">
      <TemplateGroupList></TemplateGroupList>
    </template>
  </Page>
</template>

<script>
import Page from "@/components/Page.vue";
import TemplateGroupList from "@/components/TemplateGroupList";

// import AuthApp from "../main";

export default {
  components: {
    Page,
    TemplateGroupList,
  },
  setup() {},
  mounted() {},
  data() {
    return {
      //   auth: AuthApp,
    };
  },
  validations() {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
