<template>
  <Page
    leftIcon="pi pi-arrow-left"
    :title="title"
    @onIconClick="handlePreviousButton"
    @onTabClick="changeSelectedTab"
    :tabOptions="tabOptions"
  >
    <template v-slot:content v-if="!loading">
      <div class="clients-content">
        <TabView :activeIndex="activeIndex">
          <!-- SMS OVERVIEW-->
          <TabPanel header="Mensagem">
            <MessageOverview
              v-if="activeIndex === 0"
              @onUpdateResourseChange="updateAttributes"
            ></MessageOverview>
          </TabPanel>
          <!-- SMS MT DETAILS -->
          <TabPanel header="SMS enviados">
            <MessageDetail v-if="activeIndex === 1" type="MT"></MessageDetail>
          </TabPanel>
          <!-- SMS MO DETAILS -->
          <TabPanel header="SMS recebidos">
            <MessageDetail v-if="activeIndex === 2" type="MO"></MessageDetail>
          </TabPanel>
          <!-- SMS DLR REPORT -->
          <TabPanel header="Relatórios">
            <SmsDlrReport v-if="activeIndex === 3"></SmsDlrReport>
          </TabPanel>
        </TabView>
      </div>
    </template>
  </Page>
</template>

<script>
import Page from "@/components/Page.vue";
import MessageOverview from "@/components/MessageOverview";
import MessageDetail from "@/components/MessageDetail";
import SmsDlrReport from "@/components/SmsDlrReport";

// import ErrorState from "@/components/utils/ErrorState.vue";

import AuthApp from "../main";

export default {
  components: {
    Page,
    MessageOverview,
    MessageDetail,
    SmsDlrReport,
    // ErrorState,
  },
  setup() {},
  mounted() {
    // this.title = this.$store.getters.messageSelected.title;
    //hack
    // this.tabOptions[2].visible = this.messageType == "CAMPAIGN" ? true : false;
  },
  beforeMount() {
    this.title = this.$store.getters.messageSelected.title;
    this.messageType = this.$store.getters.messageSelected.messageType;
    this.tabOptions = [
      { value: "Mensagem", selected: true, visible: true },
      { value: "SMS enviados", selected: false, visible: true },
      { value: "SMS recebidos", selected: false, visible: true },
      {
        value: "Relatórios",
        selected: false,
        visible: this.messageType == "CAMPAIGN" ? true : false,
      },
    ];
  },
  data() {
    return {
      auth: AuthApp,
      loading: false,
      activeIndex: 0,
      title: "",
      messageType: "",
      tabOptions: "",
    };
  },
  computed: {},
  validations() {},
  methods: {
    handlePreviousButton: function () {
      let params = this.$route.query;
      this.$router.push({ name: "messagedashboard", query: { ...params } });
    },
    updateAttributes: function (data) {
      this.title = data.title;
      this.messageType = data.messageType;

      //hack
      this.tabOptions[2].visible =
        this.messageType == "CAMPAIGN" ? true : false;
    },
    changeSelectedTab(e) {
      this.tabOptions.forEach((element) => {
        element.selected = false;
      });
      this.tabOptions[e].selected = true;
      this.activeIndex = e;
    },
  },
};
</script>

<style lang="scss" scoped></style>
