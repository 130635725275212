<template>
  <ErrorState
    v-if="!loading && error"
    @onErrorStateButtonClick="onErrorStateButtonClick()"
    message="Algo não correu bem"
    buttonText="Voltar a tentar"
  ></ErrorState>

  <GenericDialog
    :visible="showGenericDialog"
    title="Eliminar lista de contatos"
    description="Confirma que quer eliminar a lista de contatos?"
    @close="showGenericDialog = false"
    @confirm="removeList()"
  />

  <Dialog
    position="center"
    :visible="showDialog"
    :dismissableMask="true"
    :modal="true"
    style="width: 640px"
    :draggable="false"
    :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
    :style="{ width: '70vw' }"
  >
    <template #header> Criar nova lista de contactos </template>
    <div>
      <Dialog
        position="center"
        :visible="showTutorial"
        :dismissableMask="true"
        modal="true"
        :draggable="false"
      >
        <template #header>Tutorial</template>
        <video-player
          class="video-player vjs-big-play-centered"
          :src="tutorialUrl"
          playsinline
          controls
          :volume="0.6"
          :height="320"
          :playback-rates="[0.7, 1.0, 1.5, 2.0]"
          @ended="showTutorial = false"
        />
        <template #footer>
          <Button label="Fechar" @click="showTutorial = false" text />
        </template>
      </Dialog>
      <Message severity="warn">
        <div class="mb-2">
          Os números terão de ser precedidos do respectivo código do País.
          Exemplo: ["+351930000000"]
        </div>
        <Button
          icon="pi pi-video"
          class="p-button p-button-secondary p-button-outlined mr-2"
          @click="showTutorial = true"
          label="Ver tutorial"
        ></Button>
        <FileDownloader
          :url="storageUrl"
          file="nos_mpro_template.xlsx"
          label="Download template (.xlsx)"
          :withAuthorization="false"
        />
      </Message>
      <Card class="card">
        <template #content v-if="!loading">
          <div class="p-fluid p-formgrid grid card mb-3">
            <!-- Name -->
            <div class="col-4">
              <label for="name"
                >{{ $t("views.contacts.table.listName") }} - (max 30
                caracteres)</label
              >
              <InputText
                class="mt-2"
                id="name"
                type="text"
                v-model="list.name"
                placeholder="--"
                maxlength="30"
              />
              <small
                v-if="v$.list?.name?.$error"
                id="name-help"
                class="p-error"
              >
                <img
                  class="sucess-image"
                  src="/resources/icons/error_label.svg"
                />{{ v$.list.name?.$errors[0]?.$message }}</small
              >
            </div>
          </div>
          <FileUpload
            name="file"
            :url="uploadUrl"
            @upload="onTemplatedUpload($event)"
            :multiple="true"
            accept=".xlsx,.csv"
            :maxFileSize="20000000"
            :fileLimit="1"
            @select="onSelectedFiles"
            @before-send="uploadBeforeSend"
            :invalidFileSizeMessage="$t('views.upload.maxSizeExceeded')"
            :invalidFileTypeMessage="$t('views.upload.invalidFileType')"
            :invalidFileLimitMessage="$t('views.upload.invalidFileLimit')"
          >
            <template #empty>
              <div
                class="flex align-items-center justify-content-center flex-column"
              >
                <i
                  class="pi pi-cloud-upload border-2 border-circle p-5 text-8xl text-400 border-400"
                />
                <p class="mt-4 mb-0">{{ $t("views.upload.dragMessage") }}</p>
              </div>
            </template>

            <template #content="{ files, removeFileCallback }">
              <div v-if="files.length > 0">
                <!-- <h5>Pending</h5> -->
                <div class="flex flex-wrap p-5 gap-5">
                  <div
                    v-for="(file, index) of files"
                    :key="file.name + file.type + file.size"
                    class="card m-0 px-6 flex flex-column border-1 surface-border align-items-center gap-3 pt-2 pb-2"
                  >
                    <span class="font-semibold">{{ file.name }}</span>
                    <div>{{ formatSize(file.size) }}</div>
                    <Badge value="Pendente" severity="warning" />
                    <Button
                      icon="pi pi-times"
                      @click="
                        onRemoveTemplatingFile(file, removeFileCallback, index)
                      "
                      class="p-button-outlined p-button-danger p-button-rounded"
                    />
                  </div>
                </div>
              </div>

              <!-- {{uploadedFiles.length > 0 ? removeUploadedFileCallback(0) : '' }} -->

              <!-- <div v-if="uploadedFiles.length > 0 ">
                <div class="flex flex-wrap p-0 sm:p-5 sm:p-5 gap-5">
                  <div
                    v-for="(file, index) of uploadedFiles"
                    :key="file.name + file.type + file.size"
                    class="card m-0 px-6 flex flex-column border-1 surface-border align-items-center gap-3"
                  >
                    <span class="font-semibold">{{ file.name }}</span>
                    <div>{{ formatSize(file.size) }}</div>
                    <Badge value="Completed" class="mt-3" severity="success" />
                    <Button
                      icon="pi pi-times"
                      @click="removeUploadedFileCallback(index)"
                      class="p-button-outlined p-button-danger p-button-rounded"
                    />
                  </div>
                </div>
              </div> -->
            </template>

            <template #header="{ chooseCallback, uploadCallback, files }">
              <div
                class="flex flex-wrap justify-content-between align-items-center flex-1 gap-2"
              >
                <div class="flex gap-2">
                  <Button
                    @click="chooseCallback()"
                    icon="pi pi-images"
                    label="Escolher"
                    class="mr-2"
                    :disabled="files.length >= 1"
                  ></Button>
                  <Button
                    @click="uploadEvent(uploadCallback)"
                    icon="pi pi-cloud-upload"
                    class="p-button-success mr-2"
                    label="Importar"
                    :disabled="!files || files.length === 0"
                  ></Button>

                  <!-- <Button
                  @click="clearCallback()"
                  icon="pi pi-times"
                  class="p-button-rounded p-button-danger"
                  label="Cancelar"
                  :disabled="!files || files.length === 0"
                ></Button> -->
                </div>
                <!-- <ProgressBar
                  :value="totalSizePercent"
                  :showValue="false"
                  :class="[
                    'md:w-20rem h-1rem w-full md:ml-auto',
                    { 'exceeded-progress-bar': totalSizePercent > 100 },
                  ]"
                  ><span class="white-space-nowrap"
                    >{{ totalSize }}B / 5Mb</span
                  ></ProgressBar
                > -->
              </div>
            </template>
          </FileUpload>
        </template>
      </Card>
    </div>
    <template #footer>
      <Button label="Fechar" @click="closeUploadPopup" />
    </template>
  </Dialog>

  <div v-if="!error" class="flex align-items-stretch flex-wrap">
    <div class="flex align-items-start results">
      {{ totalRecords }} resultado(s)
    </div>
    <div class="inline-flex flex-auto"></div>
    <div class="inline-flex">
      <Button
        label="Adicionar lista"
        v-if="auth.account_state == 'ENABLED'"
        class="p-button p-button-secondary"
        @click="openUploadPopup"
        :disabled="totalRecords >= maxAllowedLists"
      />
    </div>
    <!-- </div> -->
    <!--<div
      class="flex align-items-end results cursor-pointer"
      @click="showFilters = !showFilters"
      v-if="showFiltersArea"
    >
      <template v-if="showFilters">
        Fechar filtros
        <img class="menu-icon" src="/resources/icons/close.svg" />
      </template>
      <template v-else>
        <img class="menu-icon" src="/resources/icons/filter.svg" />
        Filtros
      </template>
    </div> -->
  </div>
  <Message severity="warn" v-if="totalRecords >= maxAllowedLists">
    <div>Atingiu o máximo de 100 listas permitidas por conta.</div>
  </Message>
  <Divider />

  <EmptyState
    v-if="!loading && !error && !items.length"
    @onEmptyStateButtonClick="openUploadPopup"
    message="Sem listas carregadas"
    buttonText="Adicionar lista"
    :showButton="auth.account_state === 'ENABLED' ? true : false"
  ></EmptyState>

  <!-- <Button
    v-if="items.length"
    @click="getData()"
    icon="pi pi-refresh"
    label="Atualizar"
    class="p-button-outlined p-button-secondary radius-normal"
  ></Button> -->
  <DataTable
    :rowHover="true"
    :value="items"
    :paginator="false"
    :row-hover="true"
    responsiveLayout="scroll"
    @row-click="handleRowClick($event)"
    v-if="items.length"
  >
    <Column field="name">
      <template #header>
        <i class="pi pi-tag mr-1" style="font-size: 0.8rem"></i>
        {{ $t("views.contacts.table.listName") }}
      </template>
    </Column>
    <Column field="originalFileName">
      <template #header>
        <i class="pi pi-file mr-1" style="font-size: 0.8rem"></i>
        {{ $t("views.contacts.table.fileName") }}
      </template>
    </Column>
    <Column field="createdAt" :style="{ 'text-align': 'center' }">
      <template #header>
        <div class="flex-1 text-center">
          <i class="pi pi-calendar" style="font-size: 0.8rem"></i>
          {{ $t("views.contacts.table.createdAt") }}
        </div>
      </template>
      <template #body="slotProps">
        {{ convertTimeStampToDate(slotProps.data.createdAt) }}
      </template>
    </Column>
    <Column field="status" :style="{ 'text-align': 'center' }">
      <template #header>
        <div class="flex-1 text-center">
          <i class="pi pi-sync" style="font-size: 0.8rem"></i>
          {{ $t("views.contacts.table.status") }}
        </div>
      </template>
      <template #body="slotProps">
        <i :class="'customer-badge status-' + slotProps.data.status">{{
          $t("views.general.statuses." + slotProps.data.status)
        }}</i>
      </template>
    </Column>
    <Column field="progress" :style="{ 'text-align': 'center' }">
      <template #header>
        <div class="flex-1 text-center">
          <i class="pi pi-history" style="font-size: 0.8rem"></i>
          {{ $t("views.contacts.table.progress") }}
        </div>
      </template>
      <template #body="slotProps">
        <ProgressBar
          :value="
            Math.round(
              0.3 * slotProps.data.blob.progress.task1.progress +
                0.25 * slotProps.data.blob.progress.task2.progress +
                0.45 * slotProps.data.blob.progress.task3.progress
            )
          "
        ></ProgressBar>
        <!-- <i :class="'customer-badge status-' + slotProps.data.status">{{
          $t("views.general.statuses." + slotProps.data.status)
        }}</i> -->
      </template>
    </Column>
    <Column field="contacts">
      <template #header>
        <i class="pi pi-chart-pie mr-1" style="font-size: 0.8rem"></i>
        {{ $t("views.contacts.table.contacts") }}
      </template>
      <template #body="slotProps">
        <div class="mb-1" title="contatos importados">
          <i
            title="contatos importados com successo"
            class="pi pi-check-circle mr-1"
            style="font-size: 0.9rem; color: green"
          ></i>
          {{ slotProps.data.contacts || "0" }}
        </div>
        <div
          title="contatos rejeitados"
          v-if="slotProps.data.blob.progress.task1.totalError > 0"
        >
          <i
            class="pi pi-exclamation-circle mr-1 red"
            style="font-size: 0.9rem; color: red"
          ></i>
          {{ slotProps.data.blob.progress.task1.totalError || "0" }}
        </div>

        <!-- {{
          slotProps.data.status != "accepted" &&
          slotProps.data.status != "processing"
            ? slotProps.data.contacts
            : "n/a"
        }} -->
      </template>
    </Column>
    <Column field="actions">
      <template #header>
        <!-- <i class="pi pi-cog"></i> -->
      </template>
      <template #body="slotProps">
        <Button
          v-if="
            slotProps.data.status == 'processed' ||
            slotProps.data.status == 'failed'
          "
          icon="pi pi-trash"
          class="p-button-rounded p-button-secondary p-button-text"
          @click="
            (showGenericDialog = true), (selectedRow = slotProps.data.listId)
          "
        />
        <i
          v-if="
            slotProps.data.status == 'accepted' ||
            slotProps.data.status == 'processing'
          "
          class="pi pi-spin pi-spinner ml-2"
        />
      </template>
    </Column>
  </DataTable>
  <CustomPaginator
    :size="size"
    :totalRecords="Number(totalRecords)"
    :first="first"
    :rowsPerPageOptions="[10, 20, 30]"
    @pageChange="handlePageChange"
    v-if="items.length"
  />
</template>

<script>
import ErrorState from "@/components/utils/ErrorState.vue";
import CustomPaginator from "@/components/CustomPaginator.vue";
import GenericDialog from "@/components/utils/GenericDialog.vue";
import FileDownloader from "@/components/utils/FileDownloader.vue";

import AuthApp from "../main";
import dayjs from "dayjs";
import useVuelidate from "@vuelidate/core";
import { required } from "@/shared/customValidators.js";

import EmptyState from "@/components/utils/EmptyState.vue";
import { getLists, getList, removeList } from "@/services/contact.service.js";

import { VideoPlayer } from "@videojs-player/vue";
import "video.js/dist/video-js.css";

export default {
  name: "messagelist",
  setup() {
    return { v$: useVuelidate() };
  },
  data: function () {
    return {
      uploadUrl: process.env.VUE_APP_BASE_URL + "customer/v1/list/upload",
      storageUrl:
        process.env.VUE_APP_GCP_STORAGE_URL +
        "mpro-static-assets/nos_mpro_template.xlsx",
      tutorialUrl:
        process.env.VUE_APP_GCP_STORAGE_URL +
        "mpro-static-assets/tutorial1.mp4",
      showTutorial: false,
      uploadedFiles: [],
      files: [],
      totalSize: 0,
      totalSizePercent: 0,
      list: {},
      auth: AuthApp,
      showFilters: false,
      showDialog: false,
      showGenericDialog: false,
      loading: false,
      catalog: {},
      searchDate: null,
      searchSender: null,
      searchTitle: null,
      items: [],
      filters: [],
      first: 0,
      size: 10,
      page: 0,
      timers: [],
      maxAllowedLists: 100,
    };
  },
  props: {
    showFiltersArea: Boolean,
  },
  components: {
    ErrorState,
    CustomPaginator,
    EmptyState,
    GenericDialog,
    FileDownloader,
    // VideoPlayer,
    VideoPlayer,
  },
  mounted() {
    let params = this.$route.query;
    if (params.prevpage) {
      this.page = parseInt(params.prevpage, 10);
    }
    if (params.prevsize) {
      this.size = parseInt(params.prevsize, 10);
    }
    if (params.prevfirst) {
      this.first = parseInt(params.prevfirst, 10);
    }

    //clean up just in case
    this.$router.replace({ query: null });

    this.getData();
  },
  computed: {},
  validations() {
    return {
      list: {
        name: {
          required: required,
        },
      },
    };
  },
  methods: {
    async getData() {
      this.error = false;
      this.loading = true;

      this.loader = this.$loading.show();

      var filter = this.handleFilter();

      const [res, err] = await getLists({
        filter: filter,
        page: this.page,
        size: this.size,
      });

      if (err) {
        this.error = true;
        this.$toast.add({
          severity: "error",
          summary: "Erro",
          detail: "Aconteceu um erro, por favor tente mais tarde!",
          life: 3000,
        });
      } else {
        this.items = res.items;
        this.totalRecords = res.totalItems;
        //keep track of pending lists
        this.checkPendingLists();
      }

      this.loader.hide();
      this.loading = false;
    },

    checkPendingLists() {
      for (let idx = 0; idx < this.timers.length; idx++) {
        clearTimeout(this.timers[idx]);
      }

      const pendingItems = this.items.filter(function (el) {
        return (
          el.blob.progress.task1.progress != 100 ||
          el.blob.progress.task2.progress != 100 ||
          el.blob.progress.task3.progress != 100
        );
      });

      if (pendingItems.length) this.emulateRequest(pendingItems);
    },

    emulateRequest(items) {
      //FIX ME: improve this with a progressive time
      this.timers.push(
        setTimeout(() => {
          items.forEach(async (list) => {
            const [res, err] = await getList(list.listId);
            if (!err) {
              const idx = this.items.findIndex((o) => o.listId == res.listId);

              if (
                res.blob.progress.task1.progress != 100 ||
                res.blob.progress.task2.progress != 100 ||
                res.blob.progress.task3.progress != 100
              ) {
                this.emulateRequest([res]);
              }

              this.items[idx] = res;
            } else {
              console.log("ERR", err);
            }
          });
        }, 7000)
      );
    },

    onErrorStateButtonClick() {
      this.getData();
    },
    handleRowClick: function (event) {
      let id = event.data.listId;

      this.$store.dispatch("setContactGroupListSelected", event.data);

      this.$router.push({
        name: "contactdashboardoverview",
        params: { id },
        query: {
          prevpage: this.page,
          prevsize: this.size,
          prevfirst: this.first,
        },
      });
    },
    handlePageChange(e) {
      this.page = e.page;
      this.size = e.rows;
      this.first = e.first;
      this.getData();
    },

    openUploadPopup: function () {
      this.showDialog = true;
    },
    closeUploadPopup: function () {
      this.showDialog = false;
    },
    handleFilter: function () {
      let string = "";
      this.filters.forEach(function (item, idx, array) {
        string += item.property + ":" + item.op + ":" + item.value;
        if (idx !== array.length - 1) {
          string += ",";
        }
      });

      return string;
    },
    convertTimeStampToDate: function (date) {
      return dayjs(date).format("YYYY-MM-DD HH:mm:ss");
    },

    /*FILE UPLOAD HELPERS*/
    myUploader(event) {
      console.log("MY UPLOAD", event.files);
    },

    uploadBeforeSend(event) {
      event.xhr.setRequestHeader("Authorization", `Bearer ${this.auth.token}`);
      event.xhr.setRequestHeader("X-Account", this.auth.decoded.mpro.account);
      event.xhr.setRequestHeader(
        "X-SubAccount",
        this.auth.decoded.mpro.subaccounts[0].subaccount
      );
      event.xhr.setRequestHeader("X-ApiKey", process.env.VUE_APP_APIGEE_CLIENT);

      //add to form data
      event.formData.append("name", this.list.name);
    },

    uploadEvent(callback) {
      this.totalSizePercent = this.totalSize / 10;

      this.v$.$validate();
      if (!this.v$.$errors.length) {
        this.loader = this.$loading.show();
        callback();
      } else {
        console.log("missing mandatory fields");
      }
    },
    // beforeUpload: function (event) {
    //   console.log("beforeUpload", event);
    // },
    chooseCallback: function (event) {
      console.log("chooseCallback", event);
    },
    uploadCallback: function (event) {
      console.log("uploadCallback", event);
    },
    clearCallback: function (event) {
      console.log("clearCallback", event);
    },
    // onClearTemplatingUpload(clear) {
    //   clear();
    //   this.totalSize = 0;
    //   this.totalSizePercent = 0;
    // },
    onTemplatedUpload: function () {
      // event.files = [];
      this.loader.hide();
      this.files = [];
      this.uploadedFiles = [];
      this.totalSize = 0;
      this.totalSizePercent = 0;
      this.$toast.add({
        severity: "success",
        summary: "Sucesso",
        detail: "Lista criada com sucesso",
        life: 3000,
      });
      this.getData();
      this.closeUploadPopup();
    },
    onSelectedFiles: function (event) {
      this.files = event.files;
      this.files.forEach((file) => {
        this.totalSize += parseInt(this.formatSize(file.size));
      });
    },
    onRemoveTemplatingFile(file, onFileRemove, index) {
      onFileRemove(index);
      this.totalSize -= parseInt(this.formatSize(file.size));
      this.totalSizePercent = this.totalSize / 10;
    },
    formatSize(bytes) {
      if (bytes === 0) {
        return "0 B";
      }

      let k = 1000,
        dm = 3,
        sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
        i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
    async removeList() {
      this.loading = true;
      this.loader = this.$loading.show();
      const [, err] = await removeList(this.selectedRow);
      if (err) {
        // this.empty = true;
        this.showGenericDialog = false;

        this.$toast.add({
          severity: "error",
          summary: "Erro",
          detail: "Aconteceu um erro, por favor tente mais tarde!",
          life: 3000,
        });

        this.loader.hide();
      } else {
        this.showGenericDialog = false;

        this.$toast.add({
          severity: "success",
          summary: "Sucesso",
          detail: "Lista apagada com sucesso",
          life: 3000,
        });
        this.loader.hide();
        this.getData();
      }
      this.loading = false;
      // this.loader.hide();
    },
  },
};
</script>

<style lang="scss">
.p-tabview-panels {
  padding-top: 0px !important;
}

.p-dropdown .p-dropdown-label {
  padding: 0.55rem 0.6rem;
}
</style>
