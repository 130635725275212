<template>
  <ErrorState
    v-if="!loading && error"
    @onErrorStateButtonClick="onErrorStateButtonClick()"
    message="Algo não correu bem"
    buttonText="Voltar a tentar"
  ></ErrorState>

  <GenericDialog
    :visible="showGenericDialog"
    title="Eliminar campanha"
    description="Confirma que quer eliminar a campanha?"
    @close="showGenericDialog = false"
    @confirm="removeCampaign()"
  />
  <Card class="card" v-if="!loading && !error">
    <template #content>
      <div>
        <div class="text-right">
          <span
            :class="'mr-2 customer-badge status-' + data.status.toLowerCase()"
            >{{
              $t("views.general.statuses." + data.status.toLowerCase())
            }}</span
          >
        </div>
        <h4>{{ $t("views.catalog.formLabels.general") }}</h4>

        <div class="grid card">
          <div class="p-field col-6 md:col-6 lg:col-3">
            <label for="sender">{{
              $t("views.message.formLabels.sender")
            }}</label>
            <p>{{ data.sender }}</p>
          </div>
          <div class="p-field col-6 md:col-6 lg:col-3">
            <label for="recipients">{{
              $t("views.message.formLabels.recipients")
            }}</label>
            <p
              v-for="item in lists"
              :key="item.list_id"
              style="cursor: pointer"
            >
              <a
                :href="null"
                @click="handleRowClick(item.list_id)"
                class="href-lists"
                >{{ item.name }} <i class="pi pi-external-link ml-2"></i
              ></a>
            </p>
            <p v-if="data.recipients.removedLists">
              <i class="pi pi-exclamation-triangle" />
              {{ data.recipients.removedLists.length }}
              lista(s) apagadas(s)
            </p>
          </div>
          <div class="p-field col-6 md:col-6 lg:col-3">
            <label for="deliveryDate">
              {{ $t("views.message.formLabels.encoding") }}</label
            >
            <p>
              <span class="customer-badge type-campaign">
                {{ data.smartEncode ? "UCS-2" : "GSM-7" }}
              </span>
            </p>
          </div>
        </div>

        <div class="grid cart">
          <div class="p-field col-6 md:col-6 lg:col-6">
            <label for="deliveryDate">
              {{ $t("views.message.formLabels.identifier") }}</label
            >
            <p>
              {{ data.campaignId }}
            </p>
          </div>
        </div>

        <Divider />

        <h4>{{ $t("views.message.formLabels.message") }}</h4>
        <div class="p-field col-6 md:col-6 lg:col-6">
          <p>{{ data.body }}</p>
        </div>

        <Divider />

        <h4>{{ $t("views.message.formLabels.typology") }}</h4>

        <div class="grid card">
          <div class="p-field col-2 md:col-6 lg:col-3">
            <label for="deliveryType">{{
              $t("views.message.formLabels.messageValidity")
            }}</label>
            <p>{{ Math.floor(ttl[data.ttl] / 3600) }}h</p>
          </div>
          <div class="p-field col-2 md:col-6 lg:col-3">
            <label for="deliveryReport">{{
              $t("views.message.formLabels.deliveryReport")
            }}</label>
            <p>
              {{
                deliveryReports.filter((key) => key.code == data.dlr)[0].name
              }}
            </p>
          </div>
          <div class="p-field col-4 md:col-6 lg:col-3">
            <label for="deliveryReport">{{
              $t("views.campaign.formLabels.schedule")
            }}</label>
            <p v-if="data.scheduleStart">
              {{ convertTimeStampToDate(data.scheduleStart) }}
            </p>
          </div>
        </div>

        <div class="grid card" v-if="data.moNumber">
          <div class="p-field col-4 md:col-12 lg:col-6">
            <label for="deliveryType">{{
              $t("views.message.formLabels.longNumber")
            }}</label>
            <p>{{ data.moNumber }}</p>
          </div>
          <div class="p-field col-4 md:col-6 lg:col-3">
            <label for="deliveryReport">{{
              $t("views.campaign.formLabels.scheduleValidity")
            }}</label>
            <p>{{ convertTimeStampToDate(data.moValidity) }}</p>
          </div>
        </div>

        <div v-if="data.status != 'DELETED'">
          <Divider />
          <Button
            @click="showGenericDialog = true"
            icon="pi pi-trash"
            label="Apagar"
            class="p-button-outlined p-button-danger radius-normal"
            :disabled="
              data.status == 'VALIDATING' ||
              data.status == 'VALIDATED' ||
              data.status == 'PROCESSING'
            "
          ></Button>
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import ErrorState from "@/components/utils/ErrorState.vue";
import AuthApp from "../main";
import GenericDialog from "@/components/utils/GenericDialog.vue";

import { getCampaign, removeCampaign } from "@/services/campaign.service";
import { getList } from "@/services/contact.service";
import { convertEpochToDate, convertTimeStampToDate } from "@/utils/date.js";

export default {
  name: "campaignoverview",
  data: function () {
    return {
      auth: AuthApp,
      showFilters: false,
      showGenericDialog: false,
      loading: true,
      data: {},
      lists: [],
      message: {},
      deliveryReports: [
        { name: "Todos", code: "ALL" },
        { name: "Nenhum", code: "NONE" },
        { name: "Só entregues", code: "DELIVERED" },
        { name: "Não entregues", code: "NON_DELIVERED" },
      ],
      ttl: {
        FOUR_HOURS: 14400,
        EIGHT_HOURS: 28800,
        DAY: 86400,
        TWO_DAYS: 172800,
      },
    };
  },
  components: {
    ErrorState,
    GenericDialog,
  },
  computed: {},
  mounted() {
    this.data = this.$store.getters.campaignSelected;
    if (Object.keys(this.data).length) {
      this.loading = false;
    } else {
      this.getData();
    }
    //this does not make sense.. we need to avoid this
    this.getListsMeta();
  },
  methods: {
    async getData() {
      this.error = false;
      this.loading = true;
      this.loader = this.$loading.show();

      let params = this.$route.params;

      const [res, err] = await getCampaign(params.id);

      if (err) {
        this.error = true;
        this.$toast.add({
          severity: "error",
          summary: "Erro",
          detail: "Aconteceu um erro, por favor tente mais tarde!",
          life: 3000,
        });
      } else {
        this.data = res;
        this.onTitleChange(res.title);
      }

      this.loader.hide();
      this.loading = false;
    },
    async removeCampaign() {
      this.loading = true;
      this.loader = this.$loading.show();
      const [, err] = await removeCampaign(this.data.campaignId);
      if (err) {
        // this.empty = true;
        this.showGenericDialog = false;

        this.$toast.add({
          severity: "error",
          summary: "Erro",
          detail: "Aconteceu um erro, por favor tente mais tarde!",
          life: 3000,
        });

        this.loader.hide();
      } else {
        this.showGenericDialog = false;
        this.$router.push({ name: "campaigndashboard" });
        this.$toast.add({
          severity: "success",
          summary: "Sucesso",
          detail: "Campanha apagada com sucesso",
          life: 3000,
        });
      }
      this.loading = false;
      this.loader.hide();
    },
    async getListsMeta() {
      this.data.recipients?.lists?.forEach(async (id) => {
        const [res, err] = await getList(id);
        if (err) {
          this.$toast.add({
            severity: "error",
            summary: "Erro",
            detail: "Aconteceu um erro, por favor tente mais tarde!",
            life: 3000,
          });
        } else {
          this.lists.push(res);
        }
      });
    },
    onTitleChange(title) {
      this.$emit("onTitleChange", title);
    },
    onErrorStateButtonClick: function () {
      this.getData();
    },
    handleRowClick(id) {
      this.$store.dispatch("setContactGroupListSelected", null);
      this.$router.push({ name: "contactdashboardoverview", params: { id } });
    },
    //utils
    convertEpochToDate: convertEpochToDate,
    convertTimeStampToDate: convertTimeStampToDate,
  },
};
</script>

<style lang="scss">
.p-tabview-panels {
  padding-top: 0px !important;
}

.p-dropdown .p-dropdown-label {
  padding: 0.55rem 0.6rem;
}

a.href-lists:link {
  text-decoration: none;
  color: inherit;
}
a.href-lists:visited {
  text-decoration: none;
  color: inherit;
}
a.href-lists:hover {
  text-decoration: none;
  color: inherit;
}
a.href-lists:active {
  text-decoration: none;
  color: inherit;
}
</style>
