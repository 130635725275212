<template>
  <Page
    :title="$t('views.message.dashboard.headerTitle')"
    @onIconClick="this.$router.push({ name: 'home' })"
  >
    <!-- <template v-slot:header>
      <div v-if="activeIndex === 0" class="p-d-flex p-justify-end">
        <Button @click="$router.push({ name: 'newMessage' })" class="large"
          >Enviar mensagem</Button
        >
      </div>
    </template> -->
    <template v-slot:content v-if="!loading">
      <!-- <div class="clients-content"> -->
      <!-- <TabView :activeIndex="activeIndex">
          <TabPanel header="Simples"> -->
      <MessageList v-if="activeIndex === 0"></MessageList>
      <!-- </TabPanel>
        </TabView> -->
      <!-- </div> -->
    </template>
  </Page>
</template>

<script>
import Page from "@/components/Page.vue";
import MessageList from "@/components/MessageList";
// import ErrorState from "@/components/utils/ErrorState.vue";

// import { submitSMS } from "../services/messages.service.js";

import AuthApp from "../main";

export default {
  components: {
    Page,
    MessageList,
    // ErrorState,
  },
  setup() {},
  mounted() {},
  data() {
    return {
      auth: AuthApp,
      loading: false,
      activeIndex: 0,
      tabOptions: [{ value: "SMS Simples", selected: true, visible: true }],
    };
  },
  computed: {},
  validations() {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
