import dayjs from "dayjs";
import Model from "../Model";

export class Contract extends Model {
  startDate = dayjs(new Date()).format("YYYY-MM-DD");

  constructor(data) {
    super(data);
    if (data) {
      this.setValueFromData(
        "endDate",
        dayjs(data.endDate).format("YYYY-MM-DD")
      );
      this.setValueFromData(
        "startDate",
        dayjs(data.startDate).format("YYYY-MM-DD")
      );
    }
  }
}
