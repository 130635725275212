import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import configPrime from "./primeConfig";
import i18n from "./i18nConfig";
import Steps from "./components/Steps.vue";
import Spinner from "./components/Spinner.vue";
import DateMisc from "./dateConfig";

// import { initKeycloak } from "./keycloak";
import VueLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Auth from "./shared/services/auth";

const loading = createApp(Spinner);
const app = createApp(App);

const initVue = () => {
  loading.unmount();
  app.use(router);
  app.use(store);
  app.use(i18n);
  app.use(configPrime);
  app.use(DateMisc);
  app.use(VueLoading);
  app.component("Steps", Steps);
  app.mount("#app");
  return app;
};

const initAuthApp = new Auth(initVue, loading);
export default initAuthApp;

// initVue();
// initKeycloak(initVue);
