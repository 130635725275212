import Axios from "axios";
import keycloak from "../../keycloak";

const config = {
  baseURL: process.env.VUE_APP_BASE_URL,
};

export class HttpError {
  constructor(httpError) {
    if (httpError.response) {
      this.statusCode = httpError.response.status;
      this.messageCode = httpError.response.data.message;
      this.response = httpError.response.data;
    } else {
      this.statusCode = "400";
      this.messageCode = httpError.message;
      this.response = httpError.response.data;
    }
  }
}

export const formatParams = (params) => {
  const httpParams = new URLSearchParams();
  Object.keys(params).forEach((key) => {
    let value = params[key];

    if (value != null) {
      if (Array.isArray(value)) {
        value.forEach((val) => {
          if (val != null) {
            httpParams.append(key, val);
          }
        });
      } else {
        if (value instanceof Date) {
          value = value.toISOString();
        }
        httpParams.set(key, value);
      }
    }
  });

  return httpParams;
};

export class Http {
  http;

  constructor() {
    this.http = Axios.create(config);
    this.http.interceptors.request.use((request) => {
      //FIXME:: subaccounts needs to be replaced with the selected one "not available for now"
      request.headers = {
        Authorization: `Bearer ${keycloak.token}`,
        "X-Account": `${keycloak.tokenParsed.mpro.account}`,
        "X-SubAccount": `${keycloak.tokenParsed.mpro.subaccounts[0].subaccount}`,
        "X-ApiKey": process.env.VUE_APP_APIGEE_CLIENT,
        // "X-ApiKey": "SEr2swIEY9HNmVTEtMqDReIY5Kcb4jPX",
        ...request.headers,
      };
      return request;
    });
    this.http.interceptors.response.use(
      (response) => {
        return response.data;
      },
      async (error) => {
        const httpError = new HttpError(error);
        return await Promise.reject(httpError);
      }
    );
  }

  async get(url, params) {
    let paramsObject = {};
    if (params != null) {
      paramsObject = formatParams(params);
    }
    return await this.http.get(url, {
      params: paramsObject,
    });
  }

  async post(url, data, headers) {
    return await this.http.post(url, data, headers);
  }

  async put(url, data) {
    return await this.http.put(url, data);
  }

  async patch(url, data) {
    return await this.http.patch(url, data);
  }

  async delete(url, data) {
    return await this.http.delete(url, data);
  }
}

export default new Http();
