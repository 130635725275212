import http from "../shared/services/http";

export const getMessages = async (params) => {
  try {
    //not need class for this type yet..

    const result = await http.get(`/communication/v1/message`, params);
    return [result, null];
  } catch (err) {
    return [null, err];
  }
};

export const getMessage = async (id) => {
  try {
    //not need class for this type yet..
    const result = await http.get(`/communication/v1/message/${id}`);
    return [result, null];
  } catch (err) {
    return [null, err];
  }
};

export const getMessageSms = async (id, params) => {
  try {
    const result = await http.get(
      `/communication/v1/message/${id}/sms`,
      params
    );
    return [result, null];
  } catch (err) {
    return [null, err];
  }
};

export const getMessageGenericSms = async (params) => {
  try {
    const result = await http.get(`/communication/v1/message/sms`, params);
    return [result, null];
  } catch (err) {
    return [null, err];
  }
};
