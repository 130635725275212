<template>
  <sidebar-menu :menu="menu" :collapsed="false" width="265px">
    <template v-slot:footer>
      <a
        class="footer-grey text-center footer cursor-pointer"
        @click="$router.push({ name: 'documentation' })"
        >Documentação</a
      >
      <a
        class="cookies footer-grey m-3 text-center footer cursor-pointer"
        @click="handleCookies"
        >Configurar cookies</a
      >
    </template>
  </sidebar-menu>
</template>

<script>
import { SidebarMenu } from "vue-sidebar-menu";
import "vue-sidebar-menu/dist/vue-sidebar-menu.css";
import AuthApp from "../main";

export default {
  data() {
    return {
      auth: AuthApp,
      menu: [],
    };
  },
  mounted() {
    this.menu = [
      {
        header: "Messaging PRO",
        hiddenOnCollapse: true,
      },
      {
        href: "/",
        title: "Dashboard",
        icon: "pi pi-fw pi-home",
      },
      {
        header: "Contactos",
        hiddenOnCollapse: true,
      },
      {
        title: "Contactos",
        icon: "pi pi-fw pi-mobile",
        href: "/contacts",
      },
      {
        header: "Templates",
        hiddenOnCollapse: true,
      },
      {
        title: "Templates",
        icon: "pi pi-fw pi-file-edit",
        href: "/templates",
      },
      {
        header: "Sms",
        hiddenOnCollapse: true,
      },
      {
        title: "SMS",
        disabled: this.auth.account_state == "ENABLED" ? false : true,
        icon: "pi pi-fw pi-forward",
        child: [
          {
            href: "/messages/new",
            title: "Enviar mensagem",
            icon: "pi pi-fw pi-comment",
          },
          {
            href: "/campaign/new",
            title: "Enviar campanha",
            icon: "pi pi-fw pi-comments",
          },
        ],
      },
      {
        title: "Histórico",
        icon: "pi pi-fw pi-history",
        child: [
          {
            href: "/messages/generic/dashboard",
            title: "Geral",
            icon: "pi pi-fw pi-database",
          },
          {
            href: "/messages/dashboard",
            title: "Mensagens",
            icon: "pi pi-fw pi-comment",
          },
          {
            href: "/campaign/dashboard",
            title: "Campanhas",
            icon: "pi pi-fw pi-comments",
          },
          // {
          //   title: "Recebidas",
          //   icon: "pi pi-fw pi-arrow-right-arrow-left",
          //   href: "/sms/statistics",
          // },
        ],
      },
      // {
      //   title: "Hístorico mensagens",
      //   icon: "pi pi-fw pi-history",
      //   href: "/messages/dashboard",
      // },
      {
        title: "Consumos",
        icon: "pi pi-fw pi-chart-line",
        href: "/sms/statistics",
      },
    ];
  },
  components: {
    SidebarMenu,
  },
  computed: {},
  methods: {
    handleCookies(event) {
      event.preventDefault();
      window.Cookiebot.renew();
    },
  },
};
</script>

<style lang="scss">
.v-sidebar-menu,
.v-sidebar-menu .vsm--dropdown,
.v-sidebar-menu .vsm--link_mobile,
.v-sidebar-menu .vsm--mobile-bg {
  position: relative;
  background: #ffffff;
  box-shadow: 0 24px 64px -2px rgba(0, 0, 0, 0.02),
    0 6px 16px -2px rgba(0, 0, 0, 0.06), 0 2px 6px -2px rgba(0, 0, 0, 0.08);
}

.v-sidebar-menu .vsm--icon,
.v-sidebar-menu .vsm--toggle-btn {
  background-color: #bad80a !important;
}
.v-sidebar-menu .vsm--title {
  color: #555 !important;
}
.v-sidebar-menu .vsm--link_hover,
.v-sidebar-menu .vsm--link_open {
  background-color: #e9ecef !important;
}
.v-sidebar-menu .vsm--link_active {
  box-shadow: inset 3px 0 0 0 #bad80a !important;
}

.v-sidebar-menu .vsm--header {
  color: rgba(38, 38, 38, 0.7) !important;
}

.v-sidebar-menu .vsm--toggle-btn {
  height: 50px !important;
}

.v-sidebar-menu .vsm--link_disabled {
  opacity: 0.6 !important;
}

.v-sidebar-menu.vsm_collapsed .footer {
  display: none;
  transition: opacity 1s ease-out;
  opacity: 0;
}

.footer-grey {
  color: var(--grey-400);
  font-style: italic;
}

.cookies {
  margin-left: 10px;
}
</style>
