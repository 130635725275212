<template>
  <Dialog
    position="center"
    :visible="visible"
    :dismissableMask="true"
    :modal="true"
    style="width: 640px"
    :draggable="false"
    @update:visible="toogleModal"
  >
    <template #header>
      <h4>{{ title }}</h4>
    </template>

    <div class="p-fluid p-formgrid grid card">
      <div class="col-12 sm:col-5 md:col-5 lg:col-5 xl:col-5">
        <Dropdown
          v-model="currentMask"
          :options="optionMasks"
          :filter="true"
          optionLabel="name"
        />
      </div>
      <div class="col-12 sm:col-4 md:col-4 lg:col-4 xl:col-4">
        <InputText
          v-if="!readOnly"
          :type="inputType === 'number' ? 'number' : 'text'"
          placeholder="--"
          variant="outlined"
          v-model="currentValue"
          class="inputField"
          autofocus
          :disabled="options ? options.length == maxOptions : false"
        />
      </div>
      <div class="col-12 sm:col-3 md:col-3 lg:col-3 xl:col-3">
        <Button
          v-if="!readOnly"
          label="Adicionar"
          v-on:click="setOptions"
          :disabled="!activeButton"
        />
      </div>
    </div>
    <div class="p-fluid">
      <small class="info-wrapper" v-if="!readOnly"
        ><i class="pi-info-circle" />
        <p>{{ description }}</p></small
      >
    </div>
    <ul>
      <li v-for="item in options" :key="item">
        <Card class="card">
          <template #content>
            <div class="item-wrapper">
              {{ item.name }}
              <i
                class="pi pi-trash"
                v-on:click="removeValue(item)"
                v-if="!readOnly"
              />
            </div>
          </template>
        </Card>
      </li>
    </ul>
    <template #footer>
      <div class="modal-footer" v-if="!readOnly">
        <Button label="Cancelar" class="p-button square" @click="cancel()" />
        <Button
          label="Confirmar"
          autofocus
          class="p-button square"
          @click="save()"
        />
      </div>
    </template>
  </Dialog>
</template>

<script>
import { computed, ref, onUpdated } from "vue";
import { isValidPhoneNumber } from "libphonenumber-js";

export default {
  name: "AddGenericOptionDialog",
  props: {
    visible: Boolean,
    optionValues: Array,
    optionMasks: Array,
    readOnly: Boolean,
    title: String,
    description: String,
    regexValidation: String,
    inputType: String,
    maxOptions: Number,
  },
  setup(props, context) {
    const options = ref([]);
    const currentValue = ref("");
    const currentMask = ref({ name: "Portugal - (+351)", code: "+351" });

    const activeButton = computed(() => {
      // return currentValue.value.match(props.regexValidation) ? true : false;
      return isValidPhoneNumber(currentMask.value.code + currentValue.value);
    });

    onUpdated(() => {
      options.value = props.optionValues;
    });

    const setOptions = () => {
      var current = [];

      if (options.value) current = [...options.value];

      const val = {
        name: currentMask.value.code + currentValue.value,
      };

      options.value = [val, ...current];
      currentValue.value = "";
    };

    const removeValue = (item) => {
      const currentOptions = [...options.value];
      const index = currentOptions.indexOf(item);
      currentOptions.splice(index, 1);
      options.value = currentOptions;
    };

    const toogleModal = (a) => {
      if (!a) {
        context.emit("close");
      }
    };

    const close = () => {
      context.emit("close");
      options.value = [];
      currentValue.value = "";
    };

    const save = () => {
      context.emit("save", options.value);
      close();
    };

    const cancel = () => {
      options.value = [];
      close();
    };

    return {
      currentValue,
      currentMask,
      options,
      setOptions,
      removeValue,
      activeButton,
      toogleModal,
      save,
      cancel,
    };
  },
};
</script>

<style scoped>
.info-wrapper {
  display: flex;
  align-items: center;
  font-weight: 300;
  gap: 10px;
}

.info-wrapper i {
  color: var(--blue);
}

.card {
  margin: 0px 0px 10px 0px;
}

.item-wrapper {
  display: flex;
  justify-content: space-between;
}

ul {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
  max-height: 300px;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
</style>
