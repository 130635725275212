<script>
import AuthApp from "../main";

export default {
  data: function () {
    return {
      auth: AuthApp,
    };
  },
  mounted() {
    this.logout();
  },
  methods: {
    logout() {
      this.auth.logout();
    },
  },
};
</script>
