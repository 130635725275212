<template>
  <ErrorState
    v-if="!loading && error"
    @onErrorStateButtonClick="onErrorStateButtonClick()"
    message="Algo não correu bem"
    buttonText="Voltar a tentar"
  ></ErrorState>
  <Card class="card" v-if="!loading && !error">
    <template #content>
      <div>
        <h4>{{ $t("views.catalog.formLabels.general") }}</h4>

        <div class="grid card">
          <div class="p-field col-6 md:col-6 lg:col-3">
            <label for="name">{{ $t("views.catalog.formLabels.name") }}</label>
            <p>{{ catalog.name }}</p>
          </div>
          <div class="p-field col-6 md:col-6 lg:col-3">
            <label for="totalmonthlypayment">{{
              $t("views.catalog.formLabels.totalMonthlyPayment")
            }}</label>
            <p>€ {{ catalog.regularMonthlyPayment || "--" }}</p>
          </div>
          <div class="p-field col-6 md:col-6 lg:col-3">
            <label for="totalmonthlypayment">{{
              $t("views.catalog.formLabels.totalMonthlyExtraPayment")
            }}</label>
            <p>€ {{ catalog.regularMonthlyExtraPayment || "--" }}</p>
          </div>
        </div>

        <div class="flex flex-wrap">
          <div
            v-for="(item, idx) in catalog.packages"
            :key="idx"
            class="flex align-items-center justify-content-center pl-5 pr-5 mr-2 mb-2 text-center"
            style="background-color: #bad80a"
          >
            <div>
              <h4>
                {{ $t("views.catalog.formLabels.countries." + item.type) }}
              </h4>

              <label for="catalogvolume">{{
                $t("views.catalog.formLabels.smsValue")
              }}</label>
              <p>€{{ item.amount }}</p>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
// import { getCatalog } from "@/services/catalog.service";
import { getAccountCatalog } from "@/services/account.service";
import ErrorState from "@/components/utils/ErrorState.vue";
import AuthApp from "../../main";

export default {
  name: "cataloglist",
  data: function () {
    return {
      auth: AuthApp,
      loading: true,
      catalog: {},
    };
  },
  components: {
    ErrorState,
  },
  props: {
    // msg: String
  },
  computed: {
    getTotalMonthPayment() {
      var count = 0;
      if (!this.catalog.packages) return 0;

      this.catalog.packages.forEach((element) => {
        count += element.amount * element.cost;
      });

      return count;
    },
  },
  methods: {
    onErrorStateButtonClick() {
      this.getData();
    },
    async getData() {
      this.error = false;
      this.loading = true;
      this.loader = this.$loading.show();

      // const [catalogRes, err] = await getAccountCatalog();

      // if (err) {
      //   this.error = true;
      //   this.$toast.add({
      //     severity: "error",
      //     summary: "Erro",
      //     detail: "Aconteceu um erro, por favor tente mais tarde!",
      //     life: 3000,
      //   });

      //   this.loader.hide();
      // } else {
      const [catalogResDetails, err] = await getAccountCatalog();
      // catalogRes.items[0].productId
      // );

      this.loading = false;

      if (err) {
        this.error = true;
        this.$toast.add({
          severity: "error",
          summary: "Erro",
          detail: "Aconteceu um erro, por favor tente mais tarde!",
          life: 3000,
        });
      } else {
        this.catalog = catalogResDetails;
      }

      this.loader.hide();
      // }

      this.loading = false;
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style scoped></style>
