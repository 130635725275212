import http from "../shared/services/http";

export const getTemplate = async (id) => {
  try {
    const result = await http.get(`/customer/v1/template/${id}`);
    return [result, null];
  } catch (err) {
    return [null, err];
  }
};

export const getTemplates = async (params) => {
  try {
    const result = await http.get(`/customer/v1/template`, params);
    return [result, null];
  } catch (err) {
    return [null, err];
  }
};

export const removeTemplate = async (id) => {
  try {
    const result = await http.delete(`/customer/v1/template/${id}`);
    return [result, null];
  } catch (err) {
    return [null, err];
  }
};

export const submitTemplate = async (data) => {
  try {
    const payload = {
      title: data.title,
      body: data.text,
    };

    const result = await http.post(`/customer/v1/template`, payload);
    return [result, null];
  } catch (err) {
    return [null, err];
  }
};

export const updateTemplate = async (data) => {
  try {
    const payload = {
      templateId: data.id,
      title: data.title,
      body: data.text,
      status: "ENABLED",
    };

    const result = await http.put(`/customer/v1/template/${data.id}`, payload);
    return [result, null];
  } catch (err) {
    return [null, err];
  }
};
