<template>
  <Page
    title="Gestão conta"
    leftIcon="pi pi-fw pi-cog"
    :tabOptions="tabOptions"
    @onTabClick="changeSelectedTab"
  >
    <!-- <template v-slot:header>
      <div
        v-if="activeIndex === 2 && appKeys > 0"
        class="p-d-flex p-justify-end"
      >
        <Button @click="addAppKey()" class="large">Criar Chave API</Button>
      </div>
      <div v-if="activeIndex === 1" class="p-d-flex p-justify-end">
        <Button @click="$router.push({ name: 'newUserAccount' })" class="large"
          >Criar utilizador</Button
        >
      </div>
    </template>-->
    <template v-slot:content>
      <TabMenu
        :model="tabOptions"
        v-model:activeIndex="activeIndex"
        @tab-change="changeSelectedTab"
      />
      <!-- <router-view/> -->

      <!-- <div v-if="activeIndex === 0">
        <h1>!!! NOT IMPLEMENTED YET !!!</h1>
      </div> -->
      <CatalogList v-if="activeIndex === 0"></CatalogList>
      <UserList v-if="activeIndex === 1"></UserList>
      <!-- <div v-if="activeIndex === 2"> -->
      <!-- <h1>!!! NOT IMPLEMENTED YET !!!</h1> -->
      <!-- </div> -->
      <AppList
        @updatedAppKeys="updateAppKeys"
        ref="appList"
        v-if="activeIndex === 2"
      ></AppList>
      <LongNumberList v-if="activeIndex === 3"></LongNumberList>
      <!-- <router-view/> -->
      <!-- <div class="clients-content">
        <TabView :activeIndex="activeIndex">

          <TabPanel header="Tarifários">
            <CatalogList v-if="activeIndex === 0"></CatalogList>
          </TabPanel>

          <TabPanel header="Utilizadores">
            <UserList v-if="activeIndex === 1"></UserList>
          </TabPanel>

          <TabPanel header="Chave API">

          </TabPanel>

          <TabPanel header="Blacklist">
            <div v-if="activeIndex === 3">
              <h1>!!! NOT IMPLEMENTED YET !!!</h1>
            </div>
          </TabPanel>
        </TabView>
      </div> -->
    </template>
  </Page>
</template>

<script>
import Page from "@/components/Page.vue";
// import CustomPaginator from "@/components/CustomPaginator.vue";
// import EmptyState from "@/components/utils/EmptyState.vue";
import CatalogList from "@/components/account/CatalogList.vue";
import AppList from "@/components/account/AppList.vue";
import UserList from "@/components/account/UserList.vue";
import LongNumberList from "@/components/account/LongNumberList.vue";

import AuthApp from "../main";

export default {
  data() {
    return {
      loading: false,
      size: 10,
      first: 0,
      totalRecords: 2,
      activeIndex: 0,
      appKeys: 0,
      auth: AuthApp,
      users: [],
      tabOptions: [
        // {
        //   label: "Geral",
        //   // icon: 'pi pi-fw pi-home',
        // },
        {
          label: "Tarifário",
          query: "catalog",
          // icon: 'pi pi-fw pi-home',
        },
        {
          label: "Utilizadores",
          query: "users",
          // icon: 'pi pi-fw pi-calendar',
          // to: '/calendar'
        },
        // {
        //   label: "Projectos",
        //   // icon: 'pi pi-fw pi-pencil',
        //   // to: '/edit'
        // },
        {
          label: "API",
          query: "api",
          // icon: 'pi pi-fw pi-file',
          // to: '/documentation'
        },
        {
          label: "LongNumber",
          query: "longnumbers",
        },
      ],
    };
  },
  // beforeMount() {
  //   if (this.auth.role == "user") {
  //     this.tabOptions = [
  //       { value: "Tarifários", selected: false, visible: false },
  //       { value: "Utilizadores", selected: false, visible: false },
  //       { value: "Chave API", selected: false, visible: false },
  //       { value: "Blacklist", selected: true, visible: true },
  //     ];
  //     this.activeIndex = 3;
  //   } else if (this.auth.role == "admin") {
  //     this.tabOptions = [
  //       { value: "Tarifários", selected: false, visible: false },
  //       { value: "Utilizadores", selected: true, visible: true },
  //       { value: "Chave API", selected: false, visible: true },
  //       { value: "Blacklist", selected: false, visible: true },
  //     ];
  //     this.activeIndex = 1;
  //   } else {
  //     this.tabOptions = [
  //       { value: "Tarifários", selected: false, visible: true },
  //       { value: "Utilizadores", selected: true, visible: true },
  //       { value: "Chave API", selected: false, visible: true },
  //       { value: "Blacklist", selected: false, visible: true },
  //     ];
  //     this.activeIndex = 1;
  //   }
  // },
  components: {
    Page,
    // CustomPaginator,
    // EmptyState,
    CatalogList,
    AppList,
    UserList,
    LongNumberList,
  },
  mounted() {
    let params = this.$route.query;

    this.activeIndex =
      params?.tab === "catalog"
        ? 0
        : params?.tab === "users"
        ? 1
        : params?.tab === "api"
        ? 2
        : params?.tab === "longnumbers"
        ? 3
        : 0;
  },
  methods: {
    addAppKey() {
      this.$refs.appList.addAppKey();
    },
    updateAppKeys(n) {
      this.appKeys = n;
    },
    changeSelectedTab(e) {
      this.tabOptions.forEach((element) => {
        element.selected = false;
      });

      this.tabOptions[e.index].selected = true;
      this.$router.push({
        name: "account",
        query: { tab: this.tabOptions[e.index].query },
      });
      this.activeIndex = e.index;
    },
  },
};
</script>

<style lang="scss">
::v-deep(.tabmenudemo-content) {
  padding: 2rem 1rem;
}
.p-tabmenu-nav .p-highlight {
  border-bottom: 0px;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  border-color: #bad80a !important;
  color: #bad80a !important;
}
.p-tabmenu
  .p-tabmenu-nav
  .p-tabmenuitem
  .p-menuitem-link:not(.p-disabled):focus {
  box-shadow: none !important;
}
</style>
