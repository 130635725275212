<template>
  <EmptyState
    v-if="!loading && appKeys.length === 0 && auth.account_state == 'ENABLED'"
    @onEmptyStateButtonClick="addAppKey()"
    message="Ainda não tem Chaves API"
    buttonText="Criar Chave API"
  ></EmptyState>

  <div class="header-content" v-if="appKeys.length">
    <div
      class="flex mt-2 mb-2 justify-content-between align-items-center"
      v-if="appKeys"
    >
      <div
        class="inline-flex align-items-center results"
        style="font-size: 14px; color: grey"
      >
        <span>{{ appKeys.length }} resultado(s)</span>
      </div>
      <div class="inline-flex flex-auto"></div>
      <div class="inline-flex">
        <Button
          label="Criar Chave API"
          v-if="auth.account_state == 'ENABLED' && appKeys.length < 4"
          class="p-button p-button-secondary"
          @click="addAppKey()"
        />
      </div>
    </div>
  </div>

  <DataTable
    :rowHover="true"
    :value="appKeys"
    :paginator="false"
    :row-hover="true"
    responsiveLayout="scroll"
    v-if="appKeys.length"
  >
    <Column field="consumerKey" :header="$t('views.appKeys.formLabels.appKey')">
      <template #body="{ data, index }">
        <input
          type="hidden"
          :id="'appClientClipBoard_' + `${index}`"
          :value="data.consumerKey"
        />
        {{ data.consumerKey }}
        <img
          src="/resources/images/copy_clipboard.svg"
          @click.stop.prevent="copyClipBoard('appClientClipBoard_' + index)"
          style="cursor: pointer"
        />
      </template>
    </Column>
    <Column
      field="consumerSecret"
      :header="$t('views.appKeys.formLabels.appToken')"
    >
      <template #body="{ data, index }">
        <input
          type="hidden"
          :id="'appTokenClipBoard_' + `${index}`"
          :value="data.consumerSecret"
        />
        {{ data.consumerSecret }}
        <img
          src="/resources/images/copy_clipboard.svg"
          @click.stop.prevent="copyClipBoard('appTokenClipBoard_' + index)"
          style="cursor: pointer"
        />
      </template>
    </Column>
    <Column field="actions">
      <template #header>
        <!-- <i class="pi pi-cog"></i> -->
      </template>
      <template #body="slotProps">
        <Button
          v-if="auth.account_state == 'ENABLED'"
          icon="pi pi-trash"
          class="p-button-rounded p-button-secondary p-button-text"
          @click="removeAppKey(slotProps.data.consumerKey)"
        />
      </template>
    </Column>
  </DataTable>
  <!-- 
  <div v-for="(item, idx) in appKeys.credentials" :key="idx">
    <Card class="card" v-if="!loading && appKeys.credentials.length != 0">
      <template #content>
        <h4>{{ $t("views.appKeys.formLabels.general") }}</h4>

        <div class="fluid formgrid grid card">
          <input
            type="hidden"
            :id="'appClientClipBoard_' + `${idx}`"
            :value="item.consumerKey"
          />
          <input
            type="hidden"
            :id="'appTokenClipBoard_' + `${idx}`"
            :value="item.consumerSecret"
          />
          <div class="field col-12 sm:col-12 md:col-5">
            <label for="name">{{
              $t("views.appKeys.formLabels.appKey")
            }}</label>
            <p>
              {{ item.consumerKey }}
              <img
                src="/resources/images/copy_clipboard.svg"
                @click.stop.prevent="copyClipBoard('appClientClipBoard_' + idx)"
                style="cursor: pointer"
              />
            </p>
          </div>
          <div class="field col-12 sm:col-12 md:col-5">
            <label for="totalmonthlypayment">{{
              $t("views.appKeys.formLabels.appToken")
            }}</label>
            <p>
              {{ item.consumerSecret }}
              <img
                src="/resources/images/copy_clipboard.svg"
                @click.stop.prevent="copyClipBoard('appTokenClipBoard_' + idx)"
                style="cursor: pointer"
              />
            </p>
          </div>
          <div class="field col-12 sm:col-12 md:col-2">
            <label></label>
            <div v-if="auth.account_state == 'ENABLED'">
              <a href="null" @click.prevent="removeAppKey(item.consumerKey)"
                ><i class="pi pi-trash" style="color: red"></i>&nbsp;Apagar</a
              >
            </div>
          </div>
        </div>
      </template>
    </Card>
    <Divider type="horizontal" />
  </div> -->
</template>

<script>
import { getAppKey, deleteAppKey, createAppKey } from "@/services/user.service";
import EmptyState from "@/components/utils/EmptyState.vue";
import AuthApp from "../../main";
// import { getAccountCatalog } from "@/services/account.service";
// import ErrorState from "@/components/utils/ErrorState.vue";

export default {
  name: "applist",
  data: function () {
    return {
      auth: AuthApp,
      loading: true,
      appKeys: [],
    };
  },
  mounted() {
    this.getData();
  },
  components: {
    EmptyState,
    // ErrorState,
  },
  props: {
    // msg: String
  },
  methods: {
    async getData() {
      // this.empty = false;
      this.loading = true;
      this.loader = this.$loading.show();

      const [appClientRes, err] = await getAppKey();

      if (err) {
        // this.empty = true;
        this.$toast.add({
          severity: "error",
          summary: "Erro",
          detail: "Aconteceu um erro, por favor tente mais tarde!",
          life: 3000,
        });

        this.loader.hide();
      } else {
        this.appKeys = appClientRes.credentials;
        this.$emit("updatedAppKeys", this.appKeys.length);
      }
      this.loading = false;
      this.loader.hide();
    },
    async removeAppKey(key) {
      // this.empty = false;
      this.loading = true;
      this.loader = this.$loading.show();

      const [, err] = await deleteAppKey(key);
      if (err) {
        this.$toast.add({
          severity: "error",
          summary: "Erro",
          detail: "Aconteceu um erro, por favor tente mais tarde!",
          life: 3000,
        });

        this.loader.hide();
      } else {
        this.appKeys = this.appKeys.filter((item) => item.consumerKey !== key);
        this.$emit("updatedAppKeys", this.appKeys.length);
      }

      this.loading = false;
      this.loader.hide();
    },
    async addAppKey() {
      this.loading = true;
      this.loader = this.$loading.show();
      const [res, err] = await createAppKey();
      if (err) {
        this.$toast.add({
          severity: "error",
          summary: "Erro",
          detail: "Aconteceu um erro, por favor tente mais tarde!",
          life: 3000,
        });

        this.loader.hide();
      } else {
        this.appKeys.push(res);
        this.$emit("updatedAppKeys", this.appKeys.length);
      }

      this.loading = false;
      this.loader.hide();
    },
    copyClipBoard(context) {
      console.log("IN" + context);
      let clipBoard = document.querySelector("#" + context);
      clipBoard.setAttribute("type", "text");
      clipBoard.select();
      document.execCommand("copy");
      // /* unselect the range */
      clipBoard.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
  },
};
</script>

<style scoped>
.p-field p,
.p-field label {
  margin: 0px;
}

.p-card-content h4 {
  margin: 0px 0px 40px 0px;
}
</style>
