import "primevue/resources/themes/saga-blue/theme.css"; //theme
import "primeicons/primeicons.css"; //icons
import "primevue/resources/primevue.min.css"; //core css
import "../public/resources/theme/theme.css"; //custom theme css
import "primeflex/primeflex.css";

import PrimeVue from "primevue/config";
import Button from "primevue/button";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import InputText from "primevue/inputtext";
import TextArea from "primevue/textarea";
import Card from "primevue/card";
import Divider from "primevue/divider";
import Paginator from "primevue/paginator";
import Calendar from "primevue/calendar";
import ToastService from "primevue/toastservice";
import Toast from "primevue/toast";
import Dialog from "primevue/dialog";
import SelectButton from "primevue/selectbutton";
import Checkbox from "primevue/checkbox";
import Dropdown from "primevue/dropdown";
import Chart from "primevue/chart";
import Menubar from "primevue/menubar";
import DynamicDialog from "primevue/dynamicdialog";
import TabMenu from "primevue/tabmenu";
import DialogService from "primevue/dialogservice";
import FileUpload from "primevue/fileupload";
import Badge from "primevue/badge";
import Message from "primevue/message";
import ProgressBar from "primevue/progressbar";
import InlineMessage from "primevue/inlinemessage";
import RadioButton from "primevue/radiobutton";
import FloatLabel from "primevue/floatlabel";

import pt from "./locales/pt.json";

export default {
  install(app) {
    app.use(PrimeVue, {
      locale: pt.prime,
    });

    app.use(ToastService);
    app.use(DialogService);
    app.component("Button", Button);
    app.component("Column", Column);
    app.component("DataTable", DataTable);
    app.component("TabView", TabView);
    app.component("TabPanel", TabPanel);
    app.component("InputText", InputText);
    app.component("TextArea", TextArea);
    app.component("Card", Card);
    app.component("Divider", Divider);
    app.component("Paginator", Paginator);
    app.component("Paginaor", Card);
    app.component("Calendar", Calendar);
    app.component("Toast", Toast);
    app.component("Dialog", Dialog);
    app.component("SelectButton", SelectButton);
    app.component("Checkbox", Checkbox);
    app.component("Dropdown", Dropdown);
    app.component("Chart", Chart);
    app.component("Menubar", Menubar);
    app.component("DynamicDialog", DynamicDialog);
    app.component("TabMenu", TabMenu);
    app.component("FileUpload", FileUpload);
    app.component("Badge", Badge);
    app.component("Message", Message);
    app.component("ProgressBar", ProgressBar);
    app.component("InlineMessage", InlineMessage);
    app.component("RadioButton", RadioButton);
    app.component("FloatLabel", FloatLabel);
  },
};
