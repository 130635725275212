import http from "../shared/services/http";

export const getList = async (id) => {
  try {
    const result = await http.get(`/customer/v1/list/${id}`);
    return [result, null];
  } catch (err) {
    return [null, err];
  }
};

export const getListContacts = async (id, params) => {
  try {
    //not need class for this type yet..
    const result = await http.get(`/customer/v1/list/${id}/contacts`, params);
    return [result, null];
  } catch (err) {
    return [null, err];
  }
};

export const removeList = async (id) => {
  try {
    const result = await http.delete(`/customer/v1/list/${id}`);
    return [result, null];
  } catch (err) {
    return [null, err];
  }
};

export const getLists = async (params) => {
  try {
    //not need class for this type yet..
    const result = await http.get(`/customer/v1/list`, params);
    return [result, null];
  } catch (err) {
    return [null, err];
  }
};

export const removeListContact = async (id, msisdn) => {
  try {
    const result = await http.delete(
      `/customer/v1/list/${id}/contacts/${msisdn}`
    );
    return [result, null];
  } catch (err) {
    return [null, err];
  }
};

export const addListContact = async (id, params) => {
  try {
    const payload = {
      msisdn: params.msisdn,
      parameters: params.parameters,
    };
    const result = await http.put(`/customer/v1/list/${id}/contacts`, payload);
    return [result, null];
  } catch (err) {
    return [null, err];
  }
};

/* contact endpoints */

export const updateContact = async (msisdn, parameters) => {
  console.log("IN SERVICE 0");

  try {
    const payload = {
      parameters: parameters,
    };

    const result = await http.patch(`/customer/v1/contacts/${msisdn}`, payload);
    return [result, null];
  } catch (err) {
    return [null, err];
  }
};

export const getContact = async (msisdn) => {
  try {
    const result = await http.get(`/customer/v1/contacts/${msisdn}`);
    return [result, null];
  } catch (err) {
    return [null, err];
  }
};
