<template>
  <!-- <EmptyState
    v-if="!loading && users.length === 0"
    @onEmptyStateButtonClick="$router.push({ name: 'newUserAccount' })"
    message="Ainda não tem utilizadores criados"
    buttonText="Criar utilizador"
  ></EmptyState> -->
  <EmptyState
    v-if="!loading && !error && usage?.data?.length == 0"
    message="Sem consumos"
    buttonText=""
    :showButton="false"
  ></EmptyState>
  <div
    class="vl-parent"
    ref="formContainer"
    style="position: relative; min-height: 150px"
  >
    <div v-if="usage.data && usage.data.length">
      <div class="p-field" v-if="!loading">
        <Dropdown
          v-if="!loading && !error"
          :options="filterPositions"
          v-model="selectedFilter"
          optionLabel="name"
          @change="handleFilterSelection"
        />
      </div>
      <Divider align="left" />
      <!-- <div class="p-d-inline-flex p-ai-center results"> -->

      <!-- <div class="p-d-inline-flex p-ai-center results">
      resultado(s)
    </div> -->

      <div class="flex mb-4">
        <!-- <div class="p-field p-col-12">
        <label>SMS Pré-Pago</label>
        <div>
          <div class="p-col">
            <div class="box" style="background-color: #946de8"></div>
            <span>Disponíveis</span>
          </div>
        </div>
      </div>-->
        <!-- <Divider layout="vertical" /> -->
        <div class="p-field p-col-12">
          <label>SMS Pacote</label>
          <div>
            <div class="p-col mr-4">
              <div class="box" style="background-color: #1785c1"></div>
              <span>Consumidos</span>
            </div>
            <div class="p-col">
              <div class="box" style="background-color: #b1dff9"></div>
              <span>Disponíveis</span>
            </div>
          </div>
        </div>
        <!-- <Divider layout="vertical" />
      <div class="p-field p-col-12">
        <label>Extras</label>
        <div>
          <div class="p-col mr-4">
            <div class="box" style="background-color: #eb960a"></div>
            <span>Consumidos</span>
          </div>
          <div class="p-col">
            <div class="box" style="background-color: #fdd28a"></div>
            <span>Disponíveis</span>
          </div>
        </div>
      </div> -->
      </div>

      <ErrorState
        v-if="!loading && error"
        @onErrorStateButtonClick="onErrorStateButtonClick()"
        message="Algo não correu bem"
        buttonText="Voltar a tentar"
      ></ErrorState>

      <Card class="card" v-if="!loading && !error">
        <template #content>
          <div>
            <!-- <h5>Horizontal</h5> -->
            <Chart
              type="bar"
              :data="stackedData"
              :options="stackedOptions"
              style="min-height: 300px"
              v-if="!loading"
            />
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import { getUsage } from "@/services/usage.service";
import EmptyState from "@/components/utils/EmptyState.vue";

// import { getCatalog } from "@/services/catalog.service";
// import { getAccountCatalog } from "@/services/account.service";
import ErrorState from "@/components/utils/ErrorState.vue";

export default {
  name: "cataloglist",
  props: {
    showLoadingFullPage: {
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    return {
      loading: true,
      usage: {},
      error: true,
      selectedFilter: "",
      graphLabels: [],
      filterPositions: [],
      stackedOptions: {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: "y",
        plugins: {
          tooltip: {
            enabled: false,
            position: "nearest",
            external: function (context) {
              const { chart, tooltip } = context;

              //create tooltip
              let tooltipEl = chart.canvas.parentNode.querySelector("div");
              if (!tooltipEl) {
                tooltipEl = document.createElement("div");
                tooltipEl.style.background = "#2a2a33";
                tooltipEl.style.borderRadius = "3px";
                tooltipEl.style.color = "white";
                tooltipEl.style.opacity = 1;
                tooltipEl.style.pointerEvents = "none";
                tooltipEl.style.position = "absolute";
                tooltipEl.style.transform = "translate(+50%, -50%)";
                tooltipEl.style.transition = "all .1s ease";
                const table = document.createElement("table");
                table.style.margin = "0px";
                tooltipEl.appendChild(table);
                chart.canvas.parentNode.appendChild(tooltipEl);
              }

              // Hide if no tooltip
              if (tooltip.opacity === 0) {
                tooltipEl.style.opacity = 0;
                return;
              }

              //set Text
              if (tooltip.body) {
                const titleLines = tooltip.title || [];
                const bodyLines = tooltip.body.map((b) => b.lines);

                // HEADER
                const tableHead = document.createElement("thead");
                const tr = document.createElement("tr");
                tr.style.borderWidth = 0;

                const th = document.createElement("th");
                th.style.borderWidth = 0;
                const text = document.createTextNode(titleLines[0]);
                th.appendChild(text);
                th.style.marginBottom = "20px";
                th.style.fontSize = "24px";

                tr.appendChild(th);
                tableHead.appendChild(tr);

                //BODY
                const tableBody = document.createElement("tbody");

                // const labels = [
                //   "SMS Pré-Pago",
                //   "SMS pacote - ",
                //   "",
                //   "SMS Extra - ",
                // ];

                bodyLines.forEach((body, i) => {
                  const colors = tooltip.labelColors[i];

                  if (i == 0 || i == 1 || i == 3) {
                    if (i > 0) {
                      tableBody.insertAdjacentHTML(
                        "beforeend",
                        '<tr><td><span><hr style="border:1px solid #404149"></span></td><tr>'
                      );
                    }

                    // var headerLabel = 'cenas';
                    //   i > 0
                    //     ? labels[i] +
                    //       (parseInt(tooltip.dataPoints[i + 1].raw) +
                    //         parseInt(tooltip.dataPoints[i].raw))
                    //     : labels[i];

                    const span1 = document.createElement("span");
                    // const text1 = document.createTextNode(headerLabel);
                    const tr = document.createElement("tr");
                    tr.style.fontSize = "14px";
                    tr.style.color = "#BBBCC4";
                    tr.style.backgroundColor = "inherit";
                    tr.style.borderWidth = 0;
                    const td = document.createElement("td");
                    td.appendChild(span1);
                    // td.appendChild(text1);
                    tr.appendChild(td);
                    tableBody.appendChild(tr);
                  }

                  const span = document.createElement("span");
                  span.style.background = colors.backgroundColor;
                  span.style.borderColor = colors.borderColor;
                  span.style.borderWidth = "2px";
                  span.style.marginRight = "10px";
                  span.style.height = "10px";
                  span.style.width = "10px";
                  span.style.display = "inline-block";
                  span.style.fontSize = "14px";

                  const tr = document.createElement("tr");
                  tr.style.backgroundColor = "inherit";
                  tr.style.borderWidth = 0;

                  const td = document.createElement("td");
                  td.style.borderWidth = 0;

                  var text;
                  var label = "€" + tooltip.dataPoints[i].raw + " disponíveis";
                  if (i % 2 == 0) {
                    label = "€" + tooltip.dataPoints[i].raw + " consumidos";
                  }

                  text = document.createTextNode(label);
                  td.appendChild(span);
                  td.appendChild(text);
                  tr.appendChild(td);
                  tableBody.appendChild(tr);
                });

                const tableRoot = tooltipEl.querySelector("table");
                // Remove old children
                while (tableRoot.firstChild) {
                  tableRoot.firstChild.remove();
                }
                // Add new children
                tableRoot.appendChild(tableHead);
                tableRoot.appendChild(tableBody);
              }

              const { offsetTop: positionY } = chart.canvas;

              // Display, position, and set styles for font
              tooltipEl.style.opacity = 1;
              tooltipEl.style.left = tooltip._eventPosition.x - 200 + "px";
              tooltipEl.style.top = positionY + tooltip.caretY + "px";
              tooltipEl.style.font = tooltip.options.bodyFont.string;
              tooltipEl.style.padding =
                tooltip.options.padding +
                "px " +
                tooltip.options.padding +
                "px";
            },
            // callbacks: {
            //   label: function (context) {
            //     let label = context.dataset.label || "";
            //     return label + ' cenas';
            //   },
            //   beforeBody: function(){
            //     return '<b>Olá Mundo</b>';
            //   },
            //   afterBody: function(){
            //     return 'afterbody';
            //   }
            // },
          },
          legend: {
            display: false,
            position: "top",
            align: "start",
            labels: {
              color: "#495057",
            },
          },
        },
        scales: {
          x: {
            position: "top",
            stacked: true,
            ticks: {
              color: "#495057",
            },
            grid: {
              color: "#ebedef",
            },
          },
          y: {
            stacked: true,
            ticks: {
              color: "#495057",
            },
            grid: {
              color: "#ebedef",
            },
          },
        },
        tooltips: {
          mode: "label",
          position: "cursor",
          intersect: true,
        },
      },
      stackedData: {
        meta: [],
        labels: [],
        datasets: [
          // {
          //   type: "bar",
          //   label: "disponíveis",
          //   backgroundColor: "#946DE8",
          //   data: [],
          //   stack: "Stack 0",
          //   barPercentage: 0.8,
          //   categoryPercentage: 0.85,
          // },
          {
            type: "bar",
            label: "consumidos",
            backgroundColor: "#1785C1",
            data: [],
            stack: "Stack 1",
            barPercentage: 0.8,
            categoryPercentage: 0.85,
          },
          {
            type: "bar",
            label: "disponíveis",
            backgroundColor: "#b1dff9",
            data: [],
            stack: "Stack 1",
            barPercentage: 0.8,
            categoryPercentage: 0.85,
          },
          // {
          //   type: "bar",
          //   label: "consumidos",
          //   backgroundColor: "#EB960A",
          //   data: [],
          //   stack: "Stack 2",
          //   barPercentage: 0.8,
          //   categoryPercentage: 0.85,
          // },
          // {
          //   type: "bar",
          //   label: "disponíveis",
          //   backgroundColor: "#fdd28a",
          //   data: [],
          //   stack: "Stack 2",
          //   barPercentage: 0.8,
          //   categoryPercentage: 0.85,
          // },
        ],
      },
    };
  },
  components: {
    ErrorState,
    EmptyState,
  },
  methods: {
    onErrorStateButtonClick() {
      this.getData();
    },
    handleFilterSelection: function (event) {
      this.handleGraph(event.value.value);
    },
    handleGraph: function (idx) {
      this.stackedData.datasets.forEach((el) => {
        el.data = [];
      });
      this.stackedData.labels = [];

      this.usage.data[idx].packages.forEach((el) => {
        // console.log("el", el);
        // //stack 0 for previous credit
        // this.stackedData.datasets[0].data.push(el.rolloverAmount);

        // //stack 1 for normal credit
        if (el.type == "PAYASYOUGO") {
          this.stackedData.datasets[0].data.push(el.consumedAmount);
        } else {
          this.stackedData.datasets[0].data.push(el.consumedAmount);
          this.stackedData.datasets[1].data.push(
            (el.amount - el.consumedAmount).toFixed(4)
          );
        }

        // //stack 2 from extra credit
        // var extra = el.amountLimit - el.amount;
        // var extra = Math.abs(el.amount - el.amountLimit);
        // var extra = el.amountLimit;
        // this.stackedData.datasets[3].data.push(el.exceededAmount);
        // this.stackedData.datasets[4].data.push(
        //   el.amountLimit - el.exceededAmount
        // );

        this.stackedData.labels.push(
          this.$t("views.catalog.formLabels.types." + el.type)
        );
      });
    },
    async getData() {
      this.error = false;
      this.loading = true;
      this.loader = this.$loading.show({
        container: this.showLoadingFullPage ? null : this.$refs.formContainer,
        canCancel: true,
        onCancel: this.onCancel,
      });
      const params = {
        months: 12,
      };

      const [res, err] = await getUsage(params);

      if (err) {
        this.error = true;
        this.$toast.add({
          severity: "error",
          summary: "Erro",
          detail: "Aconteceu um erro, por favor tente mais tarde!",
          life: 3000,
        });

        this.loader.hide();
      } else {
        this.usage = res;

        if (this.usage.data.length) {
          this.filterPositions = this.usage.data.map((el, idx) => {
            var rawDate = new Date(el.startDate);

            return {
              name:
                rawDate.getMonthName("pt") +
                " ( " +
                el.startDate +
                " a " +
                el.endDate +
                " )",
              value: idx,
            };
          });

          this.selectedFilter = this.filterPositions[0];

          //DEFAULT TO ZERO
          this.handleGraph(0);
        }

        // this.usage = {
        //   accountId: "UUID-Default",
        //   data: [
        //     {
        //       packages: [
        //         {
        //           type: "REGULAR",
        //           amount: 1000,
        //           credit: 0,
        //           consumedAmount: 1000,
        //         },
        //         {
        //           type: "PREPAID",
        //           amount: 100,
        //           credit: 0,
        //           consumedAmount: 100,
        //         },
        //         {
        //           type: "REGULAR_EXTRA",
        //           amount: 4000,
        //           credit: 3000,
        //           consumedAmount: 1000,
        //         },
        //         {
        //           type: "PAYASYOUGO",
        //           amount: 4000,
        //           credit: 3000,
        //           consumedAmount: 1000,
        //         },
        //       ],
        //       startDate: "2024-06-03",
        //     },
        //     {
        //       packages: [
        //         {
        //           type: "REGULAR",
        //           amount: 1000,
        //           credit: 0,
        //           consumedAmount: 1000,
        //         },
        //         {
        //           type: "PREPAID",
        //           amount: 1000,
        //           credit: 100,
        //           consumedAmount: 900,
        //         },
        //         {
        //           type: "REGULAR_EXTRA",
        //           amount: 4000,
        //           credit: 4000,
        //           consumedAmount: 0,
        //         },
        //       ],
        //       startDate: "2022-01-01",
        //       endDate: "2022-01-30",
        //     },
        //   ],
        // };

        // console.log("usage >>>", this.usage);

        // this.usage = {
        //   accountId: "*****",
        //   data: [
        //     {
        //       endDate: "2022-06-16",
        //       packages: [
        //         {
        //           amount: 1,
        //           amountLimit: 1000,
        //           consumedAmount: 0,
        //           exceededAmount: 0,
        //           rolloverAmount: 0,
        //           subType: "",
        //           type: "InternationalDefault",
        //         },
        //         {
        //           amount: 5000,
        //           amountLimit: 1000,
        //           consumedAmount: 40,
        //           exceededAmount: 0,
        //           rolloverAmount: 0,
        //           subType: "PT",
        //           type: "National",
        //         },
        //       ],
        //       startDate: "2022-05-17",
        //     },
        //     // {
        //     //   endDate: "2022-07-16",
        //     //   packages: [
        //     //     {
        //     //       amount: 1,
        //     //       amountLimit: 0,
        //     //       consumedAmount: 0,
        //     //       exceededAmount: 0,
        //     //       rolloverAmount: 1000,
        //     //       subType: "",
        //     //       type: "InternationalDefault",
        //     //     },
        //     //     {
        //     //       amount: 5000,
        //     //       amountLimit: 0,
        //     //       consumedAmount: 0,
        //     //       exceededAmount: 0,
        //     //       rolloverAmount: 4960,
        //     //       subType: "PT",
        //     //       type: "National",
        //     //     },
        //     //   ],
        //     //   startDate: "2022-06-17",
        //     // },
        //   ],
        // };
        // console.log(">>>>", this.usage);

        //filter dates
        // this.filterPositions = this.usage.data.map((el, idx) => {
        //   var rawDate = new Date(el.startDate);

        //   return {
        //     name:
        //       rawDate.getMonthName("pt") +
        //       " ( " +
        //       el.startDate +
        //       " a " +
        //       el.endDate +
        //       " )",
        //     value: idx,
        //   };
        // });

        // this.selectedFilter = this.filterPositions[0];

        // //DEFAULT TO ZERO
        // this.handleGraph(0);
      }

      this.loader.hide();
      this.loading = false;
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style scoped>
.p-dropdown {
  border: 0 none;
  background: none;
}

div.p-col {
  display: inline-flex;
  line-height: inherit;
  text-align: center;
  vertical-align: middle;
  line-height: 13px;
}

div.box {
  width: 13px;
  height: 13px;
  margin-right: 10px;
}
</style>
