import Model from "../Model";
import { Contact } from "./Contact";

export class Person extends Model {
  contacts = [];

  constructor(data) {
    super(data);
    if (data) {
      this.contacts = this.setValueFromData(
        "name",
        String(data.name).toString()
      );
      this.setValueFromData("type", String(data.type).toString());
      if (data.contacts) {
        this.contacts = data.contacts.map((item) => new Contact(item));
      }
    }
  }
}
