<template>
  <Button
    icon="pi pi-cloud-download"
    @click="downloadFile()"
    class="p-button p-button-secondary p-button-outlined radius-normal"
    :label="label"
  >
  </Button>
</template>

<script>
import axios from "axios";
import AuthApp from "../../main";

export default {
  name: "FileDownloader",
  data() {
    return {
      auth: AuthApp,
    };
  },
  props: {
    url: String,
    file: String,
    label: String,
    withAuthorization: Boolean,
  },
  methods: {
    downloadFile() {
      // console.log("auth", this.auth);

      let headers = {};
      if (this.withAuthorization) {
        headers = {
          Authorization: `Bearer ${this.auth.token}`,
          "X-Account": `${this.auth.decoded.mpro.account}`,
          "X-SubAccount": `${this.auth.decoded.mpro.subaccounts[0].subaccount}`,
          "X-ApiKey": process.env.VUE_APP_APIGEE_CLIENT,
        };
      }

      // const url = window.URL.createObjectURL(new Blob([response.data]));

      // const link = document.createElement("a");

      this.loader = this.$loading.show();

      axios({
        url: this.url,
        method: "GET",
        responseType: "blob",
        headers: headers,
        // maxRedirects: 1,
      })
        .then((response) => {
          // console.log("response.request.responseURL:",response.request.responseURL);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", this.file);
          document.body.appendChild(link);
          link.click();

          // document.body.appendChild(link);
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
          this.loader.hide();
        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: "Erro",
            detail: "Aconteceu um erro, por favor tente mais tarde!",
            life: 3000,
          });
          this.loader.hide();
        });
    },
  },
};
</script>
