import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/Home.vue";
import Contact from "@/views/Contact.vue";
import ContactDashboardOverview from "@/views/ContactDashboardOverview.vue";
import Template from "@/views/Template.vue";
import Campaign from "@/views/Campaign.vue";
import CampaignDashboard from "@/views/CampaignDashboard.vue";
import CampaignDashboardOverview from "@/views/CampaignDashboardOverview.vue";
import Message from "@/views/Message.vue";
import MessageDashboard from "@/views/MessageDashboard.vue";
import MessageDashboardOverview from "@/views/MessageDashboardOverview.vue";
import MessageGenericDashboard from "@/views/MessageGenericDashboard.vue";
import Account from "@/views/Account.vue";
import UserAccount from "@/views/UserAccount.vue";
import Statistics from "@/views/Statistics.vue";
import Logout from "@/views/Logout.vue";
import Documentation from "@/views/Documentation.vue";
// import CatalogList from "@/components/CatalogList.vue";

// import AuthApp from "@/shared/services/auth";
// import Auth from "../main";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/documentation",
    name: "documentation",
    component: Documentation,
  },
  {
    path: "/contacts",
    name: "contacts",
    component: Contact,
  },
  {
    path: "/templates",
    name: "templates",
    component: Template,
  },
  {
    path: "/contacts/dashboard/overview/:id",
    name: "contactdashboardoverview",
    component: ContactDashboardOverview,
  },
  {
    path: "/campaign/new",
    name: "newCampaign",
    component: Campaign,
  },
  {
    path: "/campaign/dashboard",
    name: "campaigndashboard",
    component: CampaignDashboard,
  },
  {
    path: "/campaign/dashboard/overview/:id",
    name: "campaigndashboardoverview",
    component: CampaignDashboardOverview,
  },
  {
    path: "/messages/new",
    name: "newMessage",
    component: Message,
  },
  {
    path: "/messages/dashboard",
    name: "messagedashboard",
    component: MessageDashboard,
  },
  {
    path: "/messages/dashboard/overview/:id",
    name: "messagedashboardoverview",
    component: MessageDashboardOverview,
  },
  {
    path: "/messages/generic/dashboard",
    name: "messagegenericdashboard",
    component: MessageGenericDashboard,
  },
  {
    path: "/sms/statistics",
    name: "statistics",
    component: Statistics,
  },
  {
    path: "/account",
    name: "account",
    component: Account,
  },
  {
    path: "/account/user/new",
    name: "newUserAccount",
    component: UserAccount,
  },
  {
    path: "/logout",
    name: "logout",
    component: Logout,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// router.beforeEach((to, from, next) => {
//   console.log("AUTH: " + to.name + " " + Auth);
//   if (to.name !== "Login" && !Auth.Auth) {
//     console.log("NOT LOGGED IN");
//     next({ name: "Login" });
//   } else next();
// });

// router.beforeEach((to, from, next) => {
//   console.log("ROUTE_GUARD", {
//     from,
//     to,
//   });
//   //TODO: REMOVE THIS (roles byPass)
//   next();

//   // guard(guardRoles, to, next);
// });

// const guardRoles = {
//   clientes: "manage-account",
//   novoCliente: "manage-account-links",
//   editClient: "manage-account-links",
//   tarifarios: "*",
//   tarifario: "*",
//   novoTarifario: "*",
// };

// const guard = (guardRoles, to, next) => {
//   const roles = new Auth()?.roles;
//   const guard = guardRoles[to.name];
//   if ((guard && roles.includes(guard)) || guard === "*") {
//     next();
//   }
// };

export default router;
