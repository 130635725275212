<template>
  <Page
    :title="$t('views.contacts.headerTitle')"
    @onIconClick="this.$router.push({ name: 'home' })"
  >
    <template v-slot:content v-if="!loading">
      <!-- <div class="clients-content"> -->
      <!-- <TabView :activeIndex="activeIndex">
          <TabPanel header="Simples"> -->
      <ContactGroupList v-if="activeIndex === 0"></ContactGroupList>
      <!-- </TabPanel>
        </TabView> -->
      <!-- </div> -->
    </template>
  </Page>
</template>

<script>
import Page from "@/components/Page.vue";
import ContactGroupList from "@/components/ContactGroupList";
// import ErrorState from "@/components/utils/ErrorState.vue";

// import { submitSMS } from "../services/messages.service.js";

import AuthApp from "../main";

export default {
  components: {
    Page,
    ContactGroupList,
    // ErrorState,
  },
  setup() {},
  mounted() {},
  data() {
    return {
      auth: AuthApp,
      loading: false,
      activeIndex: 0,
      tabOptions: [{ value: "SMS Simples", selected: true, visible: true }],
    };
  },
  computed: {},
  validations() {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
