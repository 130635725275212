<template>
  <Page title="Documentação" leftIcon="pi pi-fw pi-code">
    <template v-slot:content>
      <div id="redoc-container"></div>
    </template>
  </Page>
</template>

<script>
import Page from "@/components/Page.vue";
import * as redoc from "redoc/bundles/redoc.standalone.js";

export default {
  name: "documentation",
  components: {
    Page,
  },
  data() {
    return {
      color: "grey lighten-5",
      flat: true,
      redocOptions: {
        hideDownloadButton: true,
        scrollYOffset: 80,
        theme: {
          logo: { maxWidth: "220px", gutter: "30px" },
        },
      },
    };
  },
  mounted() {
    redoc.init(
      "api.yaml",
      this.redocOptions,
      document.getElementById("redoc-container")
    );
  },
};
</script>

<style lang="scss" scoped>
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

ul li:before {
  width: 0px;
  /* list-style-type: none !important; */
}

::deep() .page-content {
  padding-top: 0px !important;
}
</style>
