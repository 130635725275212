import {
  required as req,
  requiredIf as reqIf,
  helpers,
  maxLength as maxL,
} from "@vuelidate/validators";
import i18n from "../i18nConfig";
import { SegmentedMessage } from "sms-segments-calculator";

const { t } = i18n.global;

export const required = helpers.withMessage(t("validations.required"), req);
export const requiredIf = (param) =>
  helpers.withMessage(t("validations.required"), reqIf(param));

export const macthes = (param) =>
  helpers.withMessage("Formato inválido", (value) => {
    return value.match(param).length;
  });

export const maxLength = (param) =>
  helpers.withMessage(
    `Formtato Inválido! Máximo ${param} caracteres`,
    maxL(param)
  );

export const validateEncodingLenght = () =>
  helpers.withMessage(
    "Tamanho inválido para o formato escolhido",
    (value, vm) => {
      var sms = new SegmentedMessage(value);
      if (
        vm.encodingOption == "smartEncode" &&
        sms.getNonGsmCharacters().length &&
        sms.numberOfCharacters > 335
      ) {
        return false;
      }
      return true;
    }
  );
