<template>
  <Toast position="top-center">
    <template #message="slotProps">
      <div class="flex align-items-center">
        <img
          v-if="slotProps.message.severity === 'success'"
          class="sucess-image"
          src="/resources/icons/toast_success.svg"
          @click="handleNewClient"
        />
        <img
          v-if="slotProps.message.severity === 'error'"
          class="sucess-image"
          src="/resources/icons/toast_error.svg"
          @click="handleNewClient"
        />
        <p>{{ slotProps.message.detail }}</p>
      </div>
    </template>
  </Toast>

  <div class="main-wrapper">
    <TopBarMenu></TopBarMenu>
    <div id="content">
      <LeftSideBar></LeftSideBar>
      <router-view />
    </div>

    <!-- <div class="topbar"> -->
    <!-- </div> -->
    <!-- <div class="sidebar">
      <LeftMenu></LeftMenu>
    </div>
    <div id="content">
      <router-view />
    </div> -->
  </div>
</template>

<script>
// import LeftMenu from "@/components/LeftMenu";
import TopBarMenu from "@/components/TopBarMenu";
import LeftSideBar from "@/components/LeftSideBar";

export default {
  name: "App",
  components: {
    // LeftMenu,
    TopBarMenu,
    LeftSideBar,
  },
};
</script>

<style>
html {
  height: 100%;
  box-sizing: border-box; /* https://css-tricks.com/box-sizing/ */
}

*,
*:before,
*:after {
  box-sizing: inherit; /* https://css-tricks.com/box-sizing/ */
}
body {
  height: 100%;
  margin: 0;
}

#app {
  font-family: Azo Sans;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#content {
  /* flex: 8; */
  width: 100%;
  display: flex;
  margin-top: 55px;
}

.sidebar {
  width: 300px;
  /* flex: 1; */
}

.main-wrapper {
  display: flex;
  min-height: 100vh;
}

.sucess-image {
  height: 30px;
  margin-right: 20px;
}

@media screen and (max-width: 1500px) {
  #content {
    margin-top: 44px;
  }
}

@media screen and (max-width: 1199px) {
  #content {
    margin-top: 34px;
  }
}
</style>
