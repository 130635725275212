import dayjs from "dayjs";
import Model from "../Model";
import { Agent } from "./Agent";
import { BillingAccount } from "./BillingAccount";
import { Contract } from "./Contract";
import { CorporateUser } from "./CorporateUser";
import { Person } from "./Person";
import { Product } from "./Product";

export class AccountPayload {
  constructor(data) {
    if (data) {
      this.agent = data.agent;
      this.billingAccount = data.billingAccount;
      this.contract = {
        endDate: dayjs(data.contract.endDate).format("YYYY-MM-DD"),
        startDate: dayjs(data.contract.startDate).format("YYYY-MM-DD"),
      };
      this.corporateUser = data.corporateUser;
      this.id = data.id;
      this.initials = data.initials;
      this.name = data.name;
      this.person = {
        type: data.person.type,
        name: data.person.name,
        contacts: this.getContacts(data),
      };
      this.product = {
        productId: Number(data.productData.value.productId),
        startDate: dayjs(data.productStartDate).format("YYYY-MM-DD"),
        name: data.productData.name,
      };
    }
  }

  getContacts(data) {
    const contacts = [];
    if (data.person.email) {
      contacts.push({
        type: "EMAIL",
        value: data.person.email,
      });
    }
    if (data.person.phoneNumber) {
      contacts.push({
        type: "MOBILE",
        value: data.person.phoneNumber,
      });
    }

    return contacts;
  }
}

export class Account extends Model {
  agent = new Agent();
  billingAccount = new BillingAccount();
  contract = new Contract();
  corporateUser = new CorporateUser();
  initials = "";
  name = "";
  person = new Person();
  product = new Product();

  constructor(data) {
    super(data);
    if (data) {
      this.setValueFromData("agent", new Agent(data.agent));
      this.setValueFromData(
        "billingAccount",
        new BillingAccount(data.billingAccount)
      );
      this.setValueFromData("contract", new Contract(data.contract));
      this.setValueFromData(
        "corporateUser",
        new CorporateUser(data.corporateUser)
      );
      this.setValueFromData("id", Number(data.id).toFixed());
      this.setValueFromData("initials", String(data.initials).toString());
      this.setValueFromData("name", String(data.name).toString());
      this.setValueFromData("person", new Person(data.person));
      this.setValueFromData("product", new Product(data.product));
    }
  }

  getPostPayload() {
    return new AccountPayload(this);
  }
}
