<template>
  <Dialog
    position="center"
    :visible="visible"
    :dismissableMask="true"
    :modal="true"
    style="width: 640px"
    :draggable="false"
    @update:visible="toogleModal"
  >
    <template #header>
      <h4>{{ title }}</h4>
    </template>

    <div class="p-fluid">
      <small class="info-wrapper"
        ><i class="pi-info-circle" />
        <p>{{ description }}</p></small
      >
    </div>

    <template #footer>
      <div class="modal-footer">
        <Button label="Cancelar" class="p-button square" @click="cancel()" />
        <Button
          label="Confirmar"
          autofocus
          class="p-button square"
          @click="confirm()"
        />
      </div>
    </template>
  </Dialog>
</template>

<script>
import {} from "vue";
export default {
  name: "AddGenericOptionDialog",
  props: {
    visible: Boolean,
    title: String,
    description: String,
  },
  setup(props, context) {
    const toogleModal = (a) => {
      if (!a) {
        context.emit("close");
      }
    };

    const close = () => {
      context.emit("close");
    };

    const cancel = () => {
      close();
    };

    const confirm = () => {
      context.emit("confirm");
    };

    return {
      toogleModal,
      cancel,
      confirm,
    };
  },
};
</script>

<style scoped>
.info-wrapper {
  display: flex;
  align-items: center;
  font-weight: 300;
  gap: 10px;
}

.info-wrapper i {
  color: var(--blue);
}

.card {
  margin: 0px 0px 10px 0px;
}

.item-wrapper {
  display: flex;
  justify-content: space-between;
}

ul {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
  max-height: 300px;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
