import http from "../shared/services/http";

export const getAppKey = async () => {
  try {
    const result = await http.get(`/customer/v1/appclient`);
    return [result, null];
  } catch (err) {
    return [null, err];
  }
};

export const deleteAppKey = async (key) => {
  try {
    const result = await http.delete(`/customer/v1/appclient/${key}`);
    return [result, null];
  } catch (err) {
    return [null, err];
  }
};

export const createAppKey = async () => {
  try {
    const result = await http.post(`/customer/v1/appclient`);
    return [result, null];
  } catch (err) {
    return [null, err];
  }
};

export const getUser = async (params) => {
  try {
    const result = await http.get(`/customer/v1/user`, params);
    return [result, null];
  } catch (err) {
    return [null, err];
  }
};

export const deleteUser = async (id) => {
  try {
    const result = await http.delete(`/customer/v1/user/${id}`);
    return [result, null];
  } catch (err) {
    return [null, err];
  }
};

export const createUser = async (payload) => {
  try {
    const result = await http.post(`/customer/v1/user`, payload);
    return [result, null];
  } catch (err) {
    return [null, err];
  }
};
