<template>
  <Dialog
    position="center"
    :visible="showDialog"
    :dismissableMask="true"
    :modal="true"
    style="width: 640px"
    :draggable="false"
    :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
    :style="{ width: '70vw' }"
  >
    <template #header>
      <p>
        Adicionar um template
        <small>( máximo 1 template por campanha )</small>
      </p>
    </template>
    <div class="page-content">
      <DataTable
        v-model:selection="selectedItem"
        v-model:filters="filterItems"
        :globalFilterFields="['name']"
        :value="items"
        :paginator="false"
        :row-hover="true"
        responsiveLayout="scroll"
        selectionMode="single"
        :metaKeySelection="false"
        @rowSelect="checkStatus"
        @rowUnselect="onRowUnselected"
        :rows="5"
        disabledSelection="#{status != true}"
        lazy
      >
        <template #header>
          <div class="flex justify-content-between">
            <span class="p-input-icon-left">
              <InputText
                v-model="filterItems['global'].value"
                @keyup="filterCallback()"
                :disabled="loading"
                placeholder="Procurar por nome"
              />
            </span>
            <Button
              type="button"
              class="square"
              icon="pi pi-filter-slash"
              label="Limpar"
              @click="clearFilter()"
              :disabled="this.filters.length == 1"
            />
          </div>
        </template>
        <template #empty> Nenhum template encontrado </template>
        <template #loading> Carregar templates.. </template>

        <Column selectionMode="single" headerStyle="width: 3rem"></Column>
        <Column field="title" disabledSelection="true">
          <template #header>
            <i class="pi pi-tag mr-1" style="font-size: 0.8rem"></i>
            {{ $t("views.contacts.table.listName") }}
          </template>
        </Column>
        <Column field="body">
          <template #header>
            <div class="flex-1">
              <i class="pi pi-calendar" style="font-size: 0.8rem"></i>
              {{ $t("views.templates.formLabels.text") }}
            </div>
          </template>
        </Column>
      </DataTable>
      <CustomPaginator
        :size="size"
        :totalRecords="Number(totalRecords)"
        :first="first"
        :rowsPerPageOptions="[5, 10]"
        @pageChange="handlePageChange"
        v-if="items.length"
      />
    </div>
    <template #footer>
      <div class="modal-footer">
        <Button class="square" label="Cancelar" @click="onClose()" />
        <Button
          class="square"
          label="Confirmar"
          @click="onSave()"
          :disabled="!showConfirm"
        />
      </div>
    </template>
  </Dialog>
</template>

<script>
// import ErrorState from "@/components/utils/ErrorState.vue";
import CustomPaginator from "@/components/CustomPaginator.vue";
import AuthApp from "../main";
// import EmptyState from "@/components/utils/EmptyState.vue";
import { getTemplates } from "@/services/template.service.js";
import { FilterMatchMode, FilterOperator } from "primevue/api";

export default {
  emits: ["onClose", "onSave"],
  props: {
    template: {
      type: String,
    },
    showDialog: {
      type: Boolean,
    },
  },
  name: "templatelist",
  components: {
    CustomPaginator,
    // ErrorState
  },
  setup() {
    return {};
  },
  data: function () {
    return {
      auth: AuthApp,
      showConfirm: false,
      selectedItem: null,
      filterItems: null,
      items: [],
      filters: [],
      first: 0,
      size: 5,
      page: 0,
      loading: false,
    };
  },
  mounted() {
    if (this.showDialog) {
      this.initFilters();
      this.selectedItem = this.template;
      this.getData();
    }
  },
  methods: {
    clearFilter() {
      if (this.filters.length) {
        this.initFilters();
        this.clearDynamicFilter();
        this.getData();
      }
    },
    /* left this to aplly more logic in the future */
    initFilters() {
      this.filterItems = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      };
    },
    filterCallback() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.clearDynamicFilter();
        this.filters.push({
          property: "title",
          operator: "LIKE",
          value: this.filterItems.global.value,
        });

        this.getData();
      }, 500);
    },
    clearDynamicFilter() {
      this.filters = this.filters.filter(function (obj) {
        return obj.property !== "title";
      });
    },
    async getData() {
      this.error = false;
      this.loading = true;
      this.loader = this.$loading.show();

      const [res, err] = await getTemplates({
        filter: JSON.stringify(this.filters),
        page: this.page,
        size: this.size,
      });

      if (err) {
        this.error = true;
        this.$toast.add({
          severity: "error",
          summary: "Erro",
          detail: "Aconteceu um erro, por favor tente mais tarde!",
          life: 3000,
        });
      } else {
        this.items = res.items;
        this.totalRecords = res.totalItems;
      }

      this.loader.hide();
      this.loading = false;
    },
    handlePageChange(e) {
      this.page = e.page;
      this.size = e.rows;
      this.first = e.first;
      this.getData();
    },

    /*datatable helpers*/
    checkStatus() {
      if (this.template != this.selectedItem) this.showConfirm = true;
    },
    onRowUnselected() {
      this.showConfirm = this.template != this.selectedItem ? true : false;
      this.selectedItem = null;
    },
    onClose: function () {
      this.$emit("onClose");
    },
    onSave: function () {
      this.$emit("onSave", this.selectedItem);
    },
  },
};
</script>

<style scoped>
:deep() .p-paginator {
  background-color: inherit !important;
}

/* hidden select all */
:deep()
  .p-datatable
  .p-datatable-thead
  > tr
  > th:first-child
  > .p-column-header-content {
  display: none !important;
}

:deep() .p-highlight {
  border-bottom: none !important;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
