<template>
  <div class="p-tabview-nav-content">
    <ul class="p-tabview-nav" role="tablist">
      <li
        role="presentation"
        v-for="(item, index) in options"
        :key="item.value"
        :class="`
          ${item.selected && 'p-highlight'} 
          ${item.disabled && 'p-disabled'} 
          ${!item.visible && 'p-hidden'}
        `"
        @click="onTabClick(index)"
      >
        <a
          role="tab"
          class="p-tabview-nav-link"
          tabindex="0"
          :aria-selected="item.selected"
          ><span class="p-tabview-title">{{ item.value }} </span></a
        >
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "TabNavigator",
  props: {
    options: Object,
  },
  // computed: {
  //   tabs: {
  //     get: function () {
  //       return this.options;
  //     },
  //     set: function (idx) {
  //       this.tabs.forEach((element) => {
  //         element.selected = false;
  //       });
  //       this.tabs[idx].selected = true;
  //       return 1;
  //     },
  //   },
  // },
  emits: ["onTabClick"],
  data: function () {},
  methods: {
    onTabClick(idx) {
      this.$emit("onTabClick", idx);
    },
  },
};
</script>

<style lang="scss">
.p-tabview-nav-link {
  height: 30px;
  font-size: 16px;
  line-height: 24px;
}

.p-highlight {
  border-bottom: 3px solid black;
  font-weight: 900;
}

.p-hidden {
  display: none;
}
</style>
